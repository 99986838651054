import { createContext, useContext, useState } from "react";
import "../style/modal.css"
const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [modalContent, setModalContent] = useState(null);

    return (
        <ModalContext.Provider value={{ modalContent, setModalContent }}>
            {children}
            {modalContent && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        {modalContent}
                    </div>
                </div>
            )}
        </ModalContext.Provider>
    );
}


export const useModal = () => {
    return useContext(ModalContext);
}
