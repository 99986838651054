// hooks/useDeleteObject.js
import { useModal } from '../contextProviders/ModalContext.js';
import { useNotification } from '../contextProviders/NotificationContext.js';
import { deleteDataFromEndpoint as apiDeleteObjekt } from '../functions/api.js';

export function useDeleteObject() {
    const { setModalContent } = useModal();
    const { showNotification } = useNotification();

    const deleteWithConfirmation = async (config, key, data, setData) => {
        console.log("DeleteModal")
        const confirmed = await new Promise((resolve) => {
            setModalContent(
                <>
                    <p>Sind Sie sicher, dass Sie dieses Objekt löschen möchten?</p>
                    <button onClick={() => {
                        resolve(true);
                        setModalContent(null); // Schließe das Modal sofort nach Klick
                    }}>
                        Löschen
                    </button>
                    <button onClick={() => {
                        resolve(false);
                        setModalContent(null); // Schließe das Modal sofort nach Klick
                    }}>
                        Abbrechen
                    </button>
                </>
            );
        });


        if (confirmed) {
            const result = await apiDeleteObjekt(config, key, data);

            console.log("Del Result: ", result)

            if (result.status) {
                showNotification("Objekt gelöscht", "good");

                let newData = data.filter(data => data._id != key)

                setData(newData)

                return result;
            } else {
                showNotification("Objekt konnte nicht gelöscht werden", "error");
                return result;
            }
        }

        setModalContent(null); // Modal schließen
        return null;
    };

    return deleteWithConfirmation;
}
