
import React from 'react';


function Kampagnen() {
    return (
        <div className='mainBox'>
            Demnächst verfügbar
        </div>
    );
}

export default Kampagnen;