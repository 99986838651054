
import React from 'react';
import EditData from '../components/editData/editData';

function SsadCenter() {
    const possibleCategorys = [{
        "name": "activities",
        "displayName": "🌑 Aktivitäten"
    }, {
        "name": "activityGroups",
        "displayName": "🌓 Aktivitätsgruppen",
        "type": "whiteboard"
    }, {
        "name": "workflows",
        "displayName": "🌕 Workflows",
        "type": "whiteboard"
    }]

    return (

        <EditData categorys={possibleCategorys} />

    );
}

export default SsadCenter;