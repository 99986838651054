import React, { useCallback, useState, useEffect } from 'react';
import './flowchart.css'
import './chartelemente.css'
import { getDecodedJwtToken, useGetOrgaData, usePostData, useUpdateData } from '../../functions/api';
import 'reactflow/dist/style.css';
//Extern

import ReactFlow, { Controls, useNodesState, useEdgesState, addEdge, Node, Edge, Handle, NodeProps, Position, MiniMap, getBezierPath } from 'reactflow';
import { calcPathPlaces, calculateGroupPositions, calculateNodePositions, createEdges, createNotes, generateRandomToken } from '../../functions/flowchart_calc';
import { setConfig, handlePlugBtn, handleDeleteBtn, handleUnPlugBtn, resetExtraClass, handleEditItem } from './flowchartHandlers';
import { useNotification } from '../../contextProviders/NotificationContext.js';
import EditData from '../editData/editData';

function secondsToHms(d) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    return ('0' + h).slice(-2) + 'h :' + ('0' + m).slice(-2) + 'min';
}

function FlowChart({ row, category, planungsWorkflowDaten, setPlanungsWorkflowDaten, mode }) {
    const { loadOrganizationData } = useGetOrgaData();
    const { updateData } = useUpdateData();
    const { showNotification } = useNotification();
    const { postDataToEndpoint } = usePostData()
    const [currentConfig, setCurrentConfig] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [flowChartHeader, setFlowChartHeader] = useState({
        'name': "",
        'klasse': "",
        'ags': 0,
        'as': 0,
        'estimateTime': 0
    })

    const [anythingHasBeenChanged, setAnythingHasBeenChanged] = useState(false)
    const [isGroupEditMode, setIsGroupEditMode] = useState(null)


    //FlowChart
    const [flowChartData, setFlowChartData] = useState({
        edges: [],
        groups: []
    });

    const [masterData, setMasterData] = useState({
        connections: [],
        groups: []
    });

    //SelectedStates
    const [selectedNode, setSelectedNode] = useState(null);
    const [selectedEdge, setSelectedEdge] = useState(null);

    //SidebarItems
    const [searchTerm, setSearchTerm] = useState("");
    const [draggableElements, setDraggableElements] = useState([]); // Beispiel-Array

    // Zustand für das ausgewählte Element
    const [selectedItem, setSelectedItem] = useState(null);

    const [tmpActivities, setTmpActivities] = useState([])
    const [tmpActivityGroups, setTmpActivityGroups] = useState([])

    //INIT
    useEffect(() => {
        setCurrentConfig(setConfig(category))


        const fetchData = async () => {
            //console.log("Fetch Category: ", category)
            setIsLoading(true);

            let results = [];

            if (category === "workflows") {
                results = await Promise.all([
                    loadOrganizationData("/activityGroups", { "isTemplate": true }),
                    loadOrganizationData("/activities", { "isTemplate": true })
                ]);
            } else if (category === "activityGroups") {
                results = await Promise.all([
                    loadOrganizationData("/activities", { "isTemplate": true })
                ]);
            }


            //console.log("results: ", results)
            // Hier destrukturieren wir die Ergebnisse basierend auf der Kategorie
            let activityGroups, activity, activities;

            if (category === "workflows") {
                [activityGroups, activities] = results;

                setTmpActivities(activities)
                setTmpActivityGroups(activityGroups)

                //console.log("activities: ", activities)
                //console.log("activityGroups: ", activityGroups)
                //console.log("planungsWorkflowDaten: ", planungsWorkflowDaten)
                if (planungsWorkflowDaten) {
                    setMasterData(planungsWorkflowDaten)
                }
                else if (row) {
                    let json = JSON.parse(row.json_template)

                    setMasterData(json)
                } else {
                    setMasterData({
                        connections: [],
                        groups: []
                    })
                }
                setDraggableElements(activityGroups)


            } else if (category === "activityGroups") {
                [activities] = results;
                if (row) {

                    let json = JSON.parse(row.json_template)
                    //console.log("JSON: ", json)

                    setMasterData({
                        connections: [],
                        groups: [json]
                    })
                } else {
                    setMasterData({
                        connections: [],
                        groups: [{ "id": "ag_" + generateRandomToken(), "activities": [], 'connections': [] }]
                    })
                }

                setTmpActivities(activities)
                setDraggableElements(activities)
            }

            if (row) {
                setFlowChartHeader({
                    'name': row.name,
                    'klasse': category,
                    'ags': 0,
                    'as': 0,
                    'estimateTime': 0
                })
            } else {
                setFlowChartHeader({
                    'name': "",
                    'klasse': "",
                    'ags': 0,
                    'as': 0,
                    'estimateTime': 0
                })
            }


            setIsLoading(false)
        }

        fetchData();
    }, [row, category, planungsWorkflowDaten])

    //MasterDatenVeränderung
    useEffect(() => {

        setAnythingHasBeenChanged(true)
        //console.log("[0/9] - [tmpMasterData] - masterData: ", masterData)
        if (masterData) {
            //transform tmpMasterData to valid FlowChart data
            let tmpMasterData = masterData
            //console.log("[1/9] - [tmpMasterData] - start: ", tmpMasterData)

            //Add befores and afters and calculate Pathplaces for each Group and Activity
            calcPathPlaces(tmpMasterData)
            //console.log("[2/9] - [tmpMasterData] - pathplaces: ", tmpMasterData)


            //Berechne pos. für jede Aufgabe innerhalb einer Gruppe in Relation zu Startpunkt 0;0
            tmpMasterData.groups.forEach(group => {
                calculateNodePositions(group.activities, { width: 250, height: 70, marginX: 50, marginY: 30 }, { x: 0, y: 0 })

                let xValues = group.activities.map(activity => activity.position.x);
                let yValues = group.activities.map(activity => activity.position.y);

                let xMin = Math.min(...xValues);
                let xMax = Math.max(...xValues) + 250;

                let yMin = Math.min(...yValues);
                let yMax = Math.max(...yValues) + 70;

                group.width = xMax - xMin;
                group.height = yMax - yMin;
            });
            //console.log("[3/9] - [tmpMasterData] - pos (0;0): ", tmpMasterData)



            //ToDo 0;0 relation auf Group Pos. anpassen
            //console.log("[4/9] - [tmpMasterData] - (ToDo) - GroupRelationPos: ", tmpMasterData)
            //Missing Corners to Calc Width and Height of each Group
            calculateGroupPositions(tmpMasterData.groups, { x: 0, y: 0 });
            tmpMasterData.groups.forEach(group => {
                group.activities.forEach(activity => {
                    activity.position.x += group.position.x + 15;
                    activity.position.y += group.position.y + 30;
                });
            });



            //console.log("[4/9] - [tmpMasterData] - tmpActivities: ", tmpActivities)

            //übersetzte den Aufgaben in FlowChart Nodes
            let tmpNodeGroups = tmpMasterData.groups.map(group => {
                return createNotes(group.activities, tmpActivities);
            });
            //console.log("[5/9] - [tmpMasterData] - tmpNodeGroups: ", tmpNodeGroups)

            // Korrektur für searchTmpGroup
            const searchTmpGroup = (groupID) => {
                for (let group of tmpActivityGroups) {
                    if (group.id === groupID) {
                        return group;
                    }
                }
                return null; // Gib null zurück, wenn die Gruppe nicht gefunden wurde.
            };

            // Übersetze Gruppen in FlowChart Nodes
            let tmpGroupBoxes = tmpMasterData.groups.map(group => {
                //console.log("Group: ", group);

                // Basiskonfiguration
                let nodeConfig = {
                    id: group.id,
                    type: 'compound',
                    data: {
                        label: group.label,
                        width: group.width,
                        height: group.height,
                        id: group.id,
                        extraClass: group.extraClass
                    },
                    position: { x: group.position.x, y: group.position.y }
                };

                if (category === "workflows") {
                    // Falls tmpGroup gefunden wird, nutze deren label
                    let tmpGroup = searchTmpGroup(group.id);
                    if (tmpGroup) {
                        nodeConfig.data.label = tmpGroup.label;
                    }
                }

                return nodeConfig;
            });
            console.log("[5/9] - [tmpMasterData] - tmpGroupBoxes: ", tmpGroupBoxes)

            let tmpEdgesGroups = createEdges(tmpMasterData.connections);

            let tmpEdgeActivities = tmpMasterData.groups.map(group => {
                return createEdges(group.connections);
            });
            //console.log("[6/9] - [tmpMasterData] - (ToDo) - Edges: ")


            let countActivites = 0;

            tmpMasterData.groups.map(group => {
                countActivites += group.activities.length;
            });

            //console.log("[6/9] - [tmpNodeGroups] - (tmpNodeGroups) - tmpNodeGroups: ", tmpNodeGroups)
            let estimateTime = tmpNodeGroups.reduce((total, group) => {
                return total + group.reduce((groupTotal, node) => {
                    return groupTotal + node.data.time;
                }, 0);
            }, 0);

            setFlowChartHeader({ ...flowChartHeader, 'as': countActivites, 'ags': tmpMasterData.groups.length, 'estimateTime': estimateTime })


            setFlowChartData({
                edges: [...tmpEdgeActivities, ...tmpEdgesGroups],
                groups: [...tmpGroupBoxes, ...tmpNodeGroups]
            })
        }



    }, [masterData])




    const handleButtonClick = (event, btnType, id, klasse) => {
        event.stopPropagation();
        //console.log("Btn: ", btnType, "| ID:", id, "| Klasse:", klasse)
        let error = true;

        //erstmaliger Click
        if (selectedItem == null) {
            switch (btnType) {
                case "plugIcon":
                    error = handlePlugBtn(id, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData);
                    break;
                case "unPlug":
                    error = handleUnPlugBtn(id, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData);
                    break;
                case "trashIcon":
                    error = handleDeleteBtn(id, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData);
                    break;
                case "editItem":
                    error = handleEditItem(id, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData, tmpActivities, tmpActivityGroups, setDraggableElements, setIsGroupEditMode);
                default:
                //console.log(`Keine Aktion definiert für btnType: ${btnType}`);
            }
        }
        //Folgeclick gleiche Klasse
        else if (selectedItem.id == id) {
            let tmpMasterData = JSON.parse(JSON.stringify(masterData));
            resetExtraClass(tmpMasterData);
            setMasterData(tmpMasterData);
            setSelectedItem(null)
            if (btnType == "editItem") {
                if (klasse == "ag") {
                    setDraggableElements(tmpActivityGroups)
                    setIsGroupEditMode(null)
                }

            }

        }
        else if (selectedItem.type == btnType) {
            if (selectedItem.possibleIDs.includes(id)) {
                switch (btnType) {
                    case "plugIcon":
                        error = handlePlugBtn(id, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData);
                        break;
                    case "unPlug":
                        error = handleUnPlugBtn(id, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData);
                        break;
                    default:
                    //console.log(`Keine Aktion definiert für btnType: ${btnType}`);
                }
            } else {
                showNotification("Nicht möglich", "error")
            }

        }
        else {
            showNotification("Nicht möglich", "error")
        }

        if (!error) {
            showNotification("Fehler", "error")
        }
    };

    const handleNodeClick = useCallback((event, node) => { });
    const handleEdgeClick = useCallback((event, clickedEdge) => { });



    const onDragStart = (event, groupId) => {
        event.dataTransfer.setData('application/reactflow', groupId);
        event.dataTransfer.effectAllowed = 'move';
    };
    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };
    const onDrop = (event) => {
        event.preventDefault();
        const elementID = event.dataTransfer.getData('application/reactflow');
        //console.log("ElementDropped: ", elementID, " Category: ", category, " MasterData: ", masterData)


        if (!isGroupEditMode) {
            if (category == "activityGroups") {
                let tmpMasterData = masterData.groups[0]

                let element = JSON.parse(JSON.stringify(draggableElements.find(element => element._id == elementID)))
                //console.log("element: ", element)

                tmpMasterData.activities.push({
                    id: "act_" + generateRandomToken(),
                    tmp_id: element._id,
                    label: element.name,
                    desc: element.description,
                    afters: [],
                    befores: [],
                    position: { x: 0, y: 0 }
                })

                setMasterData({
                    connections: [],
                    groups: [tmpMasterData]
                })
            }
            else if (category == "workflows") {
                let element = JSON.parse(JSON.stringify(draggableElements.find(element => element._id == elementID)))
                //console.log("element: ", element)

                let tmpMasterData = JSON.parse(JSON.stringify(masterData))

                let groupTmp = { ...JSON.parse(element.json_template), "id": "ag_" + generateRandomToken() }

                groupTmp.activities.forEach(activity => {
                    //console.log("activity: ", activity);
                    let originalId = activity.id;  // Speichern Sie die ursprüngliche ID hier
                    activity.id = groupTmp.id + "-" + activity.id;

                    activity.afters = []
                    activity.befores = []
                    activity.position = { x: 0, y: 0 }


                    groupTmp.connections.forEach(connection => {
                        //console.log("connection: ", connection);
                        if (connection.from == originalId) { // Verwenden Sie die ursprüngliche ID hier
                            connection.from = groupTmp.id + "-" + originalId;
                        }
                        if (connection.to == originalId) { // Verwenden Sie die ursprüngliche ID hier
                            connection.to = groupTmp.id + "-" + originalId;
                        }
                    });
                });


                tmpMasterData.groups.push(groupTmp)

                setMasterData(tmpMasterData)
            }
        }


        //GroupEditing
        else if (isGroupEditMode) {
            let tmpMasterData = JSON.parse(JSON.stringify(masterData));
            let tmpMasterGroup = tmpMasterData.groups[isGroupEditMode.groupIndex];


            let element = JSON.parse(JSON.stringify(draggableElements.find(element => element._id == elementID)))
            //console.log("element: ", element)

            tmpMasterGroup.activities.push({
                id: "act_" + generateRandomToken(),
                tmp_id: element._id,
                label: element.name,
                desc: element.description,
                afters: [],
                befores: [],
                position: { x: 0, y: 0 }
            })


            setMasterData(tmpMasterData)

        }

    }


    async function handleSave() {


        if (flowChartHeader.name) {
            //console.log("Save:")
            setAnythingHasBeenChanged(false)

            if (row) {
                if (category == "activityGroups") {
                    let newJSON = JSON.stringify(masterData.groups[0])
                    //console.log("Save:", newJSON)

                    await updateData("tbl_activityGroups", row._id, { "json_template": newJSON, 'name': flowChartHeader.name })
                }
                if (category == "workflows") {
                    let newJSON = JSON.stringify(masterData)
                    //console.log("Save:", newJSON)

                    await updateData("tbl_workflows", row._id, { "json_template": newJSON, 'name': flowChartHeader.name })
                }
            } else {
                let token = await getDecodedJwtToken();
                if (category == "activityGroups") {
                    let newJSON = JSON.stringify(masterData.groups[0])
                    console.log("Gruppe vorm Speichern:", newJSON)

                    let res = await postDataToEndpoint({ "name": "testName", "isTemplate": true, "json_template": newJSON, "organization": [token.decodedToken.orgaId], 'name': flowChartHeader.name }, "/activityGroups")

                    console.log("Gruppe nach speichern: ", res)
                }
                if (category == "workflows") {
                    let originalJSON = JSON.parse(JSON.stringify(masterData))


                    //console.log("originalJSON : ", originalJSON)
                    let newJSON = {};
                    newJSON.groups = originalJSON.groups.map(group => {
                        let newGroup = {
                            id: group.id,
                            label: group.label,
                            connections: group.connections
                        };

                        if (group.activities) {
                            newGroup.activities = group.activities.map(act => {
                                return {
                                    id: act.id,
                                    label: act.label,
                                    tmp_id: act.tmp_id
                                };
                            });
                        }

                        return newGroup;
                    });

                    newJSON.connections = originalJSON.connections;

                    //console.log("Save:", newJSON)
                    const jsonString = JSON.stringify(newJSON);
                    console.log("JSON nach Stringify: ", jsonString);
                    await postDataToEndpoint({ "name": "testName", "isTemplate": true, "json_template": jsonString, "organization": [token.decodedToken.orgaId], 'name': flowChartHeader.name }, "/workflows")

                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
            }
        }
        else {
            showNotification("Namen eingeben", "error")
        }

    }


    function handlePlanungSave() {
        setAnythingHasBeenChanged(false)
        setPlanungsWorkflowDaten(masterData)
    }

    /*
    //Erwartete Struktur NODES - Boxen
        {id: 'id_123',
        type: 'activity',
        data: { label: 'task1', andereAttribute:..., },
        position: {  x: 0,  y: 0}}
    //Erwartete Struktur EDGES - Linien
        {id: 'Ein eindeutiger String oder eine Zahl, um die Kante zu identifizieren',
        source: 'Die ID des Quellknotens',
        target: 'Die ID des Zielknotens',
        type: 'sLine'}
    */

    //console.log("flowChartData: ", flowChartData)
    const displayNodes = flowChartData.groups.flat();
    const displayEdged = flowChartData.edges.flat();

    if (isLoading) {
        return (<div className='flowchartMainBox'>Laden...</div>)
    }




    return (
        <div className='flowchartMainBox'>
            <div className='flowchartMainBox-Header'>
                {!planungsWorkflowDaten && <div className='flowchartMainBox-HeaderInfo'>
                    Name:
                    <input
                        value={flowChartHeader.name}
                        onChange={(e) => {
                            setFlowChartHeader({ ...flowChartHeader, 'name': e.target.value });

                            if (category !== "workflows") {
                                let tmpMasterData = JSON.parse(JSON.stringify(masterData));
                                tmpMasterData.groups[0]['label'] = e.target.value;
                                setMasterData(tmpMasterData);
                            }
                        }}
                    />
                </div>}
                {!planungsWorkflowDaten &&
                    <div className='flowchartMainBox-HeaderInfo'>
                        Klasse:
                        <input value={flowChartHeader.klasse} disabled />
                    </div>}
                <div className='flowchartMainBox-HeaderInfo'>
                    Aktivitätsgruppen:
                    <input value={flowChartHeader.ags} type='number' disabled />
                </div>
                <div className='flowchartMainBox-HeaderInfo'>
                    Aufgaben:
                    <input value={flowChartHeader.as} type='number' disabled />
                </div>
                <div className='flowchartMainBox-HeaderInfo'>
                    Voraussichtliche Zeit:
                    <input value={secondsToHms(flowChartHeader.estimateTime)} type='text' disabled />
                </div>
            </div>
            <div className='flowchartMainBox-Body'>
                {"edit" === "edit" && (
                    <div className="sidebar">
                        <div className='sidebarHeader'>
                            <p>{category}</p>
                            <div className="sidebar-search">
                                <input
                                    type="text"
                                    placeholder="Suche..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='sidebarBody'>
                            {!planungsWorkflowDaten &&
                                <div className='sidebarBodyNewElementBtn'>
                                    {category == 'workflows' ? <p>Gruppen</p> : <p>Aufgaben</p>}
                                </div>}
                            {draggableElements
                                .filter(element => element.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                .map(element => (
                                    <div
                                        key={element._id}
                                        onDragStart={(event) => onDragStart(event, element._id)}
                                        draggable
                                        data-group_id={element.id}
                                        className="draggable-node"
                                    >
                                        {element.name}
                                        {!planungsWorkflowDaten && <div className='draggable-node-edit-btn'>

                                            <i className={"fa-solid fa-pencil"}></i>

                                        </div>}

                                    </div>
                                ))}
                        </div>
                        {!planungsWorkflowDaten && <div className='sidebarFooter'>
                            {anythingHasBeenChanged && <div className='saveBtn' onClick={handleSave}>Speichern</div>}
                            {!anythingHasBeenChanged && <div className='saveBtn' style={{ backgroundColor: 'gray' }} >Speichern</div>}
                        </div>}
                        {planungsWorkflowDaten && <div className='sidebarFooter'>
                            {anythingHasBeenChanged && <div className='saveBtn' onClick={handlePlanungSave}>Das passt</div>}
                            {!anythingHasBeenChanged && <div className='saveBtn' style={{ backgroundColor: 'gray' }} >Das passt</div>}
                        </div>}
                    </div>
                )}

                <ReactFlow
                    nodes={displayNodes}
                    edges={displayEdged}

                    nodeTypes={
                        {
                            activity: (props) => <ActivityNode data={props.data} handleButtonClick={handleButtonClick} planungsWorkflowDaten={planungsWorkflowDaten} />,
                            virtual: VirtualNode,
                            compound: (props) => <TurboGroup data={props.data} handleButtonClick={handleButtonClick} category={category} planungsWorkflowDaten={planungsWorkflowDaten} />
                        }}

                    edgeTypes={{ sLine: sLine }}

                    elementsSelectable={false}
                    onDrop={onDrop}
                    onDragOver={onDragOver}

                    onNodeClick={handleNodeClick}
                    onEdgeClick={handleEdgeClick}

                    minZoom={0.2}
                    maxZoom={4}
                >
                    <MiniMap nodeColor={"#5f27cd"} nodeStrokeWidth={3} zoomable pannable />
                </ReactFlow>
            </div>
        </div>
    );
}

export default FlowChart;


function TurboGroup({ handleButtonClick, data, category, planungsWorkflowDaten }) {
    //console.log("data: ", data)

    let header = null
    let editHTML = null

    if (planungsWorkflowDaten != null) {
        editHTML = (
            <div className="nodeButton" onClick={(e) => handleButtonClick(e, 'editItem', data.id, "ag")}>
                <i className="fa-solid fa-pencil" />
            </div>
        )
    }

    if (category == "workflows") {
        header = (
            <div className='ActivityNodeHeader'>
                <div className='ActivityLabel'>{data.label}</div>
                <div className='ActivityNodeHeader-Settings'>
                    {editHTML}
                    <div className="nodeButton" onClick={(e) => handleButtonClick(e, 'trashIcon', data.id, "ag")}>
                        <i className="fa-solid fa-trash" />
                    </div>
                    <div className="nodeButton" onClick={(e) => handleButtonClick(e, 'plugIcon', data.id, "ag")}>
                        <i className="fa-solid fa-plug" />
                    </div>
                    <div className="nodeButton" onClick={(e) => handleButtonClick(e, 'unPlug', data.id, "ag")}>
                        <i class="fa-solid fa-scissors"></i>
                    </div>
                </div>
            </div>
        )
    } else if (category == "activityGroups") {
        header = (
            <div className='ActivityNodeHeader'>
                <div className='ActivityLabel'>{data.label}</div>
            </div>
        )
    }

    return (

        <div className={`activityGroupNode ${data.extraClass ? data.extraClass : ''}`} style={{ width: data.width + 30, height: data.height + 45 }}>
            {header}
            <Handle className="tHandle" style={{ left: "-2px" }} type="target" position={Position.Left} isConnectable={false} />
            <Handle className="tHandle" style={{ right: "-2px" }} type="source" position={Position.Right} isConnectable={false} />
        </div>

    )
}



function ActivityNode({ handleButtonClick, data, planungsWorkflowDaten }) {
    /*
    data.label
    data.rollen
    data.desc
    data.time
    data.days
    */
    /*
        <div className="nodeButton" onClick={(e) => handleButtonClick(e, 'cogIcon', data.id, "a")}>
            <i className="fa-solid fa-pen"></i>
        </div>
        <div className="nodeButton" onClick={(e) => handleButtonClick(e, 'upload', data.id, "a")}>
            <i class="fa-solid fa-upload"></i>
        </div>
    */
    let editHTML = null

    /*if (planungsWorkflowDaten != null) {
        editHTML = (
            <div className="nodeButton" onClick={(e) => handleButtonClick(e, 'editGroup', data.id, "ag")}>
                <i className="fa-solid fa-pencil" />
            </div>
        )
    }*/

    return (
        <div className={`ActivityNodeBox ${data.extraClass ? data.extraClass : ''}`}>


            <div className='ActivityNodeHeader'>
                <div className='ActivityLabel'>{data.label}</div>
                <div className='ActivityNodeHeader-Settings'>
                    {editHTML}
                    <div className="nodeButton" onClick={(e) => handleButtonClick(e, 'trashIcon', data.id, "a")}>
                        <i className="fa-solid fa-trash" />
                    </div>
                    <div className="nodeButton" onClick={(e) => handleButtonClick(e, 'plugIcon', data.id, "a")}>
                        <i className="fa-solid fa-plug" />
                    </div>
                    <div className="nodeButton" onClick={(e) => handleButtonClick(e, 'unPlug', data.id, "a")}>
                        <i class="fa-solid fa-scissors"></i>
                    </div>
                </div>
            </div>
            <div className='ActivityNodeBody'>
                <div className='ActivityDesc'>
                    <i className="fa-solid fa-file-pen activityIcon"></i>
                    {data.desc}
                </div>
                <div className='ActivityDesc'>
                    <i className="fa-solid fa-people-group activityIcon"></i>
                    {data.rollen.map(role => (
                        <div className="roleTag" key={role.row_id}>
                            {role.display_value.toUpperCase()}
                        </div>
                    ))}
                </div>
                <div className='ActivityDesc'>
                    <i className="fa-solid fa-hourglass-start  activityIcon"></i>
                    {secondsToHms(data.time)}
                </div>
                <div className='ActivityDesc'>
                    <i class="fa-solid fa-calendar-days  activityIcon"></i>{data.days} Tag(e)
                </div>
            </div>

            <p className='NodeClass'>  {data.klasse}</p>

            <Handle className="tHandle" style={{ left: "-2px" }} type="target" position={Position.Left} isConnectable={false} />
            <Handle className="tHandle" style={{ right: "-2px" }} type="source" position={Position.Right} isConnectable={false} />
        </div>
    );
}

function VirtualNode() {

}

function sLine() {

}
