


export async function fetchWhatsAppTemplates(whatsappBusinessId, accessToken) {
    const baseUrl = 'https://graph.facebook.com/v18.0/';
    const endpoint = `${whatsappBusinessId}/message_templates?limit=100`;

    const response = await fetch(baseUrl + endpoint, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });

    console.log("Facbook Request: URL:", baseUrl + endpoint, " |Headers: ", {
        'Authorization': `Bearer ${accessToken}`
    }, " | Method:GET")

    console.log("Facbook Response: ", response)

    if (response.ok) {
        const data = await response.json();
        return data;
    } else {
        return false
    }
}

// Verwendung:
// const result = await fetchWhatsAppTemplates('YOUR_WHATSAPP_BUSINESS_ACCOUNT_ID', 'YOUR_ACCESS_TOKEN');
// console.log(result);
