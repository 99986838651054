import { useNotification } from '../contextProviders/NotificationContext.js';
import { fetchWhatsAppTemplates } from '../functions/fbAPIs';
import './chatbot.css'
import CreatableSelect from 'react-select/creatable';

//Extern
import React, { useEffect, useState, useCallback, useRef } from 'react';
import 'reactflow/dist/style.css';
import ReactFlow, { Controls, useNodesState, useEdgesState, addEdge, Node, Edge, Handle, NodeProps, Position, MiniMap, getBezierPath } from 'reactflow';
import { generateRandomToken } from '../functions/flowchart_calc';
import { calcMessagePositions, calcPathPlaceMessages, connectMsgHandler, deleteMsgHandler, disconnectMsgHandler, resetExtraClass } from '../functions/chatbotHelpers.js';
import { getDecodedJwtToken, useGetData, useGetOrgaData, usePostData } from '../functions/api';
import CustomModal from '../components/creationModals/creationModal.js';




function Chatbot() {
    const { showNotification } = useNotification();
    const { loadOrganizationData } = useGetOrgaData();
    const { getData } = useGetData();
    const { postDataToEndpoint } = usePostData();


    const [isLoading, setIsLoading] = useState(true);
    const [WhatsappVorlagen, setWhatsappVorlagen] = useState([])
    const [facebookSettings, setFacebookSettings] = useState({
        'accessToken': '',
        'whatsappBusiness': ''
    })

    const [chatFlow, setChatFlow] = useState(null)
    const [chatStartFollowUpFlow, setChatStartFollowUpFlow] = useState(null)
    const [chatMitteFollowUpFlow, setChatMitteFollowUpFlow] = useState(null)

    const [customers, setCustomers] = useState([])
    const [customer, setCustomer] = useState([])
    const [customerDatenGeladen, setCustomerDatenGeladen] = useState(false)

    const [projects, setProjects] = useState([])
    const [project, setProject] = useState([])

    const [customVars, setCustomVars] = useState({});

    // Handler, um eine neue Variable hinzuzufügen
    const addCustomVar = (newVarName) => {
        setCustomVars({
            ...customVars,
            [newVarName]: '' // Setzt den Anfangswert der neuen Variable auf einen leeren String
        });
    };

    // Handler, um den Wert einer bestehenden Variable zu aktualisieren
    const handleVarChange = (varName, newValue) => {
        setCustomVars({
            ...customVars,
            [varName]: newValue
        });
    };

    const [modalType, setModalType] = useState({ 'open': false, 'table': '' })

    //INIT
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            let resCustomers = await loadOrganizationData("/customers", {})

            if (resCustomers) {
                let choosableCustomers = resCustomers.map(emp => ({
                    value: emp._id,
                    label: emp.f_name
                }));
                setCustomers(choosableCustomers)
            } else {
                setCustomers([])
            }



            setIsLoading(false)
        }

        fetchData();
    }, [])

    //CustomerChange
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            setCustomerDatenGeladen(false)
            if (customer) {
                let projekte = await getData('/projects', { 'customerID': customer.value })


                let choosableProjekte = projekte.map(emp => ({
                    value: emp._id,
                    label: emp.f_name
                }));




                setProjects(choosableProjekte);
                setProject(null)


                setCustomerDatenGeladen(true)
                setIsLoading(false)
            }



        }

        fetchData();
    }, [customer])


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFacebookSettings(prevSettings => ({
            ...prevSettings,
            [name]: value
        }));
    }

    const handleLoadTemplate = async () => {
        const templates = await fetchWhatsAppTemplates(facebookSettings.whatsappBusiness, facebookSettings.accessToken);


        console.log("templates: ", templates)

        if (templates) {
            setWhatsappVorlagen(templates.data);  // Angenommen, das zurückgegebene Objekt hat eine 'data' Eigenschaft.
            showNotification("Vorlagen geladen", "good")
        } else {
            showNotification("Keine Berechtigung", "error")
        }

    }


    const handleCreateNewProj = async (e) => {
        let ans = await postDataToEndpoint({ 'status': 'aktiv', 'f_name': e.value, customerID: customer.value }, "/projects")
        console.log("ans: ", ans);

        let projects = await getData('/projects', { 'customerID': customer.value });
        projects = projects;
        console.log("projects: ", ans);

        let choosableProjekte = projects.map(emp => ({
            value: emp._id,
            label: emp.f_name
        }));


        setProjects(choosableProjekte);
        setProject(null)
    }


    return (
        <div className='mainBox'>
            <div className='innerBoxPlanner' style={{ borderRadius: '0px' }}>
                <h1>Chatbot - Daten</h1>
                <div className='plannerBox'>
                    <div className='rolleBox'>
                        <CreatableSelect
                            isClearable={true}
                            value={customer}
                            options={customers}
                            className='rollenSelect'
                            placeholder='kunde'
                            onChange={(e) => {
                                if (e) {
                                    if (e.__isNew__) {

                                        setModalType({ 'open': true, 'table': 'Kunde' })

                                    } else {
                                        setCustomer(e);
                                    }
                                } else {
                                    setProject(null)
                                    setCustomer(null)
                                    setCustomerDatenGeladen(false)
                                }

                            }}
                        />
                    </div>
                    <div className='rolleBox'>
                        <CreatableSelect
                            isClearable={true}
                            value={project}
                            options={projects}
                            className='rollenSelect'
                            placeholder='Projekt'
                            isDisabled={!customerDatenGeladen}
                            onChange={(e) => {
                                if (e) {
                                    if (e.__isNew__) {
                                        handleCreateNewProj(e)
                                    } else {
                                        setProject(e)
                                    }
                                } else {
                                    setProject(null)
                                }

                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='ChatbotHeader'>
                <label htmlFor="accessToken"> AccessToken: </label>
                <input
                    type='text'
                    id='accessToken'
                    name='accessToken'
                    placeholder='accessToken'
                    value={facebookSettings.accessToken}
                    onChange={handleInputChange}
                />
                <label htmlFor="whatsappBusiness"> WhatsappBusinessID: </label>
                <input
                    type='text'
                    id='whatsappBusiness'
                    name='whatsappBusiness'
                    placeholder='whatsappBusinessID'
                    value={facebookSettings.whatsappBusiness}
                    onChange={handleInputChange}
                />
                <div onClick={handleLoadTemplate} className='addBtn'>Vorlagen holen</div>
            </div>

            <div className='chatbotSection' >
                <h4>Facebook Telefon ID</h4>
                <div className='ChatbotAngaben'>
                    <div className='ChatbotInformation'>
                        <label htmlFor="telefonID"> TelefonID: </label>
                        <input
                            type='text'
                            id='telefonID'
                            name='telefonID'
                            placeholder='TelefonID'
                        />
                    </div>
                </div>
                <h4>Informationen zur beworbener Stelle</h4>
                <div className='ChatbotAngaben'>
                    <div className='ChatbotInformation'>
                        <label htmlFor="stellenBezeichnung">Variable: Stellenbezeichnung </label>
                        <input
                            type='text'
                            id='stellenBezeichnung'
                            name='stellenBezeichnung'
                            placeholder='stellenBezeichnung'
                        />
                    </div>
                    <div className='ChatbotInformation'>
                        <label htmlFor="firmenName">Variable: Firma </label>
                        <input
                            type='text'
                            id='firmenName'
                            name='firmenName'
                            placeholder='firmenName'
                        />
                    </div>
                </div>
                <div>
                    <h4>Deine Custom Variables</h4>
                    {Object.keys(customVars).map((key) => (
                        <div className='ChatbotInformation' key={key}>
                            <label htmlFor={key}>Variable: {key}</label>
                            <input
                                type='text'
                                id={key}
                                name={key}
                                placeholder={key}
                                value={customVars[key]}
                                onChange={(e) => handleVarChange(key, e.target.value)}
                            />
                        </div>
                    ))}
                    <h5>Neue Variable erstellen</h5>
                    <div className='ChatbotInformation'>
                        <input type="text" id="newVarName" placeholder="Custom Var Name" />
                        <button style={{ "marginTop": "10px" }} onClick={() => {
                            let newVarName = document.getElementById('newVarName').value.trim()

                            if (newVarName) {
                                addCustomVar(newVarName)
                                document.getElementById('newVarName').value = ""
                            }

                        }}>Erstellen</button>
                    </div>

                </div>


                <h4>Leadmanagement System</h4>
                <div className='ChatbotAngaben'>
                    <div className='ChatbotInformation'>
                        <label htmlFor="leadTableKampagneID"> LeadTable Kampagne: </label>
                        <input
                            type='text'
                            id='leadTableKampagneID'
                            name='leadTableKampagneID'
                            placeholder='leadTableKampagneID'
                        />
                    </div>
                    <div className='ChatbotInformation'>
                        <label htmlFor="leadTableApiKey"> LeadTable API Key: </label>
                        <input
                            type='text'
                            id='leadTableApiKey'
                            name='leadTableApiKey'
                            placeholder='leadTableApiKey'
                        />
                    </div>
                    <div className='ChatbotInformation'>
                        <label htmlFor="leadTableEmail"> LeadTable Email: </label>
                        <input
                            type='text'
                            id='leadTableEmail'
                            name='leadTableEmail'
                            placeholder='leadTableEmail'
                        />
                    </div>
                </div>
            </div>

            <div className='chatbotSection' >
                <h4>Ablauf</h4>
                <div className='chatbotFlowChart'>
                    <ChatbotFlowChart possibleItems={WhatsappVorlagen} setChatFlow={setChatFlow} customVarsMap={customVars} />
                </div>
            </div>

            <div className='chatbotSection' >
                <h4>FollowUp - kein Start</h4>
                <div className='chatbotFlowChart'>
                    <ChatbotFlowChart possibleItems={WhatsappVorlagen} setChatFlow={setChatStartFollowUpFlow} nachrichtenLimit={1} customVarsMap={customVars} />
                </div>
            </div>

            <div className='chatbotSection' >
                <h4>FollowUp - Mitten drin aufgehört</h4>
                <div className='chatbotFlowChart'>
                    <ChatbotFlowChart possibleItems={WhatsappVorlagen} setChatFlow={setChatMitteFollowUpFlow} nachrichtenLimit={1} customVarsMap={customVars} />
                </div>
            </div>

            <div className='chatbotSection' >
                <div className='addBtn' style={{ margin: 'auto', marginTop: '40px' }} onClick={() => { handleUpload() }}>
                    Chatbot Starten
                </div>
            </div>
            {/*<CustomModal type={modalType} setType={setModalType} setObject={setCustomer} setObjects={setCustomers} />*/}
        </div>
    );

    async function handleUpload() {

        let bereit = areAllFieldsFilled()



        if (bereit.success) {

            const extractRelevantData = (data) => {
                console.log("Data: ", data)

                return {
                    nachrichten: data.nachrichten.map(nachricht => ({
                        id: nachricht.id,
                        data: {
                            fb_msgID: nachricht.data.fb_msgID,
                            fb_msgName: nachricht.data.fb_msgName,
                            path_place: nachricht.data.path_place,
                            fb_msgComponentsVars: nachricht.data.fb_msgComponentsVars,
                            fb_msgComponents: {
                                BUTTONS: nachricht.data.fb_msgComponents.BUTTONS || {}
                            }
                        }
                    })),
                    connections: data.connections
                };
            };




            let tmpChatFlow = extractRelevantData(chatFlow);
            let tmpChatStartFollowUpFlow = extractRelevantData(chatStartFollowUpFlow);
            let tmpChatMitteFollowUpFlow = extractRelevantData(chatMitteFollowUpFlow);
            const telefonID = document.getElementById("telefonID").value;
            const stellenBezeichnung = document.getElementById("stellenBezeichnung").value;
            const firmenName = document.getElementById("firmenName").value;
            const leadTableKampagneID = document.getElementById("leadTableKampagneID").value;
            const leadTableApiKey = document.getElementById("leadTableApiKey").value;
            const leadTableEmail = document.getElementById("leadTableEmail").value;

            let chatbotPayload = {
                'projekt': project,
                'kunde': customer,
                'chatFlow': JSON.stringify(tmpChatFlow),
                'chatStartFollowUpFlow': JSON.stringify(tmpChatStartFollowUpFlow),
                'chatMitteFollowUpFlow': JSON.stringify(tmpChatMitteFollowUpFlow),
                'telefonID': telefonID,
                'stellenBezeichnung': stellenBezeichnung,
                'firmenName': firmenName,
                'leadTableKampagneID': leadTableKampagneID,
                'leadTableApiKey': leadTableApiKey,
                'leadTableEmail': leadTableEmail,
                'accessToken': facebookSettings.accessToken,
                'whatsappBusiness': facebookSettings.whatsappBusiness,
                'customVars': JSON.stringify(customVars)
            }


            const response = await fetch("https://hook.eu2.make.com/nxs29k4ae1u10i9saipectj2k97xrfal", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(chatbotPayload)
            });

            console.log("workflowAns: ", response)

            showNotification("Chatbot wurde gestartet", "good")

            window.location.reload();

        } else {
            showNotification(bereit.msg, "error")
        }
    }

    function areAllFieldsFilled() {
        // Überprüfen der Werte in Ihrem Status für CreatableSelect
        if (!project || !customer) {
            return { 'success': false, 'msg': 'Wähle das Projekt aus' };
        }

        // Überprüfen der Werte der Input-Felder
        const telefonID = document.getElementById("telefonID").value;
        const stellenBezeichnung = document.getElementById("stellenBezeichnung").value;
        const firmenName = document.getElementById("firmenName").value;
        const leadTableKampagneID = document.getElementById("leadTableKampagneID").value;
        const leadTableApiKey = document.getElementById("leadTableApiKey").value;
        const leadTableEmail = document.getElementById("leadTableEmail").value;


        if (!telefonID || !stellenBezeichnung || !firmenName || !firmenName || !leadTableKampagneID || !leadTableApiKey || !leadTableEmail) {
            return { 'success': false, 'msg': 'Daten nicht vollständig' };
        }

        if (!chatFlow || !chatStartFollowUpFlow || !chatMitteFollowUpFlow) {
            return { 'success': false, 'msg': 'Flow nicht vollständig' }
        }

        return { 'success': true };
    }
}


export default Chatbot;


function ChatbotFlowChart({ possibleItems, setChatFlow, nachrichtenLimit, customVarsMap }) {
    const { showNotification } = useNotification();

    const [anythingHasBeenChanged, setAnythingHasBeenChanged] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null);

    async function handleSave() {
        console.log("Save Masterdata: ", masterData);
        for (let nachricht of masterData.nachrichten) {

            const msgVars = nachricht.data.fb_msgComponentsVars;

            // Überprüfen Sie Variablen auf undefined oder null Werte
            for (let component in msgVars) {
                if (component !== "BUTTONS") {
                    for (let key in msgVars[component]) {
                        if (msgVars[component][key].value === undefined || msgVars[component][key].value === null) {
                            console.error(`Ungültiger Wert in fb_msgComponentsVars für Nachricht ID: ${nachricht.id}, Komponente: ${component}, Schlüssel: ${key}`);
                            showNotification('Hinterlege alle Variablen: ' + nachricht.text, "error");
                            return false;
                        }
                    }
                }
            }

            // Überprüfen Sie die Buttons auf ungültige nextMsg Werte
            const buttonsObject = nachricht.data.fb_msgComponents.BUTTONS;
            for (let buttonText in buttonsObject) {
                let button = buttonsObject[buttonText];
                if (button.followingMsgID === undefined || button.followingMsgID === null) {
                    console.error(`Ungültige followingMsgID für Nachricht ID: ${nachricht.id}, Button Text: ${button.text}`);
                    showNotification('Hinterlege alle Knöpfe: ' + button.text, "error");
                    return false;
                }
            }
        }

        // Hier können Sie den Speichervorgang fortsetzen, wenn alle Überprüfungen bestanden sind
        console.log('Daten sind gültig! Speichern...');

        // Speicherlogik hier...
        setAnythingHasBeenChanged(false)
        for (let nachricht of masterData.nachrichten) {
            nachricht.befores = []
            nachricht.afters = []
        }
        await calcPathPlaceMessages(masterData)


        setChatFlow(masterData)

        return true;
    }

    const handleNodeClick = useCallback((event, node) => { });
    const handleEdgeClick = useCallback((event, clickedEdge) => { });

    const [masterData, setMasterData] = useState({
        nachrichten: [],
        connections: []
    });
    const [flowChartData, setFlowChartData] = useState({
        nodes: [],
        edges: []
    });
    const displayNodes = flowChartData.nodes;
    const displayEdges = flowChartData.edges;




    //MasterDatenVeränderung
    useEffect(() => {
        setAnythingHasBeenChanged(true)
        const updateStructure = async () => {
            let tmpMasterData = JSON.parse(JSON.stringify(masterData));

            for (let nachricht of tmpMasterData.nachrichten) {
                nachricht.type = 'nachricht'
                nachricht.position = { x: 0, y: 0 }
                nachricht.befores = []
                nachricht.afters = []

                nachricht.data = {
                    ...nachricht.data,
                    extraClass: nachricht.extraClass,
                    id: nachricht.id,
                    size: { width: 300, height: 500 },
                    path_place: 0,
                };
            }


            await calcPathPlaceMessages(tmpMasterData)

            for (let nachricht of tmpMasterData.nachrichten) {

                let hatBefore = false
                if (nachricht.befores.length > 0) {
                    hatBefore = true
                }

                nachricht.data = {
                    ...nachricht.data,
                    hasBefores: hatBefore,
                };
            }

            //TODO pos Berechnung für Nachrichten
            await calcMessagePositions(tmpMasterData.nachrichten, { width: 300, height: 500, marginX: 50, marginY: 50 }, { x: 0, y: 0 })



            let edges = []
            tmpMasterData.connections.forEach(conn => {
                edges.push({
                    id: "con_" + generateRandomToken(),
                    target: conn.to,
                    source: conn.from
                })
            });

            await setFlowChartData({
                nodes: tmpMasterData.nachrichten,
                edges: edges
            })
        }
        updateStructure();
    }, [masterData])


    const handleButtonClick = async (event, btnType, id, klasse) => {
        event.stopPropagation();
        console.log("Btn: ", btnType, "| ID:", id, "| Klasse:", klasse)
        let error = true;

        //erstmaliger Click
        if (selectedItem == null) {
            switch (btnType) {
                case "plugIcon":
                    error = connectMsgHandler(id, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData);
                    break;
                case "unPlug":
                    error = disconnectMsgHandler(id, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData);
                    break;
                case "trashIcon":
                    error = deleteMsgHandler(id, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData);
                    break;
                default:
                    console.log(`Keine Aktion definiert für btnType: ${btnType}`);
            }
        }
        //Folgeclick gleiche Klasse
        else if (selectedItem.id == id) {
            let tmpMasterData = JSON.parse(JSON.stringify(masterData));
            resetExtraClass(tmpMasterData);
            setMasterData(tmpMasterData);
            setSelectedItem(null)
        }
        else if (selectedItem.type == btnType) {
            if (selectedItem.possibleIDs.includes(id)) {
                switch (btnType) {
                    case "plugIcon":
                        error = connectMsgHandler(id, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData);
                        break;
                    case "unPlug":
                        error = disconnectMsgHandler(id, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData);
                        break;
                    default:
                        console.log(`Keine Aktion definiert für btnType: ${btnType}`);
                }
            } else {
                showNotification("Nicht möglich", "error")
            }
        }
        else {
            showNotification("Nicht möglich", "error")
        }

        if (!error) {
            showNotification("Fehler", "error")
        }
    }


    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    const onDrop = (event) => {
        event.preventDefault();

        // Eine Variable, die prüft, ob das Nachrichtenlimit erreicht wurde
        let limiterricht = false;

        // Konsolenausgaben zur Kontrolle
        console.log("nachrichtenLimit: ", nachrichtenLimit);
        console.log("masterData.nachrichten.length: ", masterData.nachrichten.length);

        // Prüfung, ob das Nachrichtenlimit erreicht wurde
        if (nachrichtenLimit && masterData.nachrichten.length >= nachrichtenLimit) {
            limiterricht = true;
        }

        // Wenn das Limit erreicht wurde, wird eine Benachrichtigung angezeigt
        if (limiterricht) {
            showNotification("Nachrichten Limit erreicht", "error");
        } else {
            // Holen Sie die ID des Elements aus dem DataTransfer-Objekt des Events
            const elementID = event.dataTransfer.getData('application/reactflow');
            // Erzeugen Sie eine tiefe Kopie von masterData, um Mutationen zu vermeiden
            let tmpMasterData = JSON.parse(JSON.stringify(masterData));
            // Finden Sie das korrespondierende Element in possibleItems anhand der ID
            let tmpFacebookVorlage = possibleItems.find(item => item.id === elementID);

            // Ein Objekt zum Speichern gefundener Variablen
            const variables = {};

            // Durchsuchen Sie alle Komponenten, um Variablen im Text zu finden
            tmpFacebookVorlage.components.forEach(comp => {
                if (comp.text) {
                    const matches = comp.text.match(/{{\d+}}/g);
                    if (matches) {
                        matches.forEach(match => {
                            const varNumber = match.replace(/{{|}}/g, '');
                            if (!variables[comp.type] && comp.type !== "BUTTONS") {
                                variables[comp.type] = {};
                            }
                            if (comp.type !== "BUTTONS") {
                                variables[comp.type][varNumber] = {
                                    name: match,
                                    value: null
                                };
                            }
                        });
                    }
                }
            });

            // Ein temporäres Objekt zum Speichern von Komponenten
            let tmpFacebookComp = {};

            tmpFacebookVorlage.components = tmpFacebookVorlage.components.map(comp => {
                if (!tmpFacebookComp[comp.type]) {
                    if (comp.type === "BUTTONS") {
                        tmpFacebookComp[comp.type] = {};
                        comp.buttons.forEach(button => {
                            tmpFacebookComp[comp.type][button.text] = {
                                type: button.type,
                                text: button.text,
                                followingMsgID: null
                            };
                        });
                    } else {
                        tmpFacebookComp[comp.type] = comp;
                    }
                } else {
                    if (comp.type !== "BUTTONS") {
                        tmpFacebookComp[comp.type] = {
                            ...comp,
                            followingMsgID: null
                        };
                    }
                }
                return comp;
            });

            // Erstellen Sie ein neues Nachrichtenobjekt
            let newMsg = {
                id: generateRandomToken(),
                data: {
                    fb_msgID: elementID,
                    fb_msgName: tmpFacebookVorlage.name,
                    fb_msgComponents: tmpFacebookComp,
                    fb_msgComponentsVars: variables
                }
            };

            // Fügen Sie die neue Nachricht zu masterData hinzu und setzen Sie den State
            tmpMasterData.nachrichten.push(newMsg);
            setMasterData(tmpMasterData);
        }
    };


    return (
        <div className='flowchartMainBox-Body' style={{ border: '1px bold purple' }}>
            <SideBar draggableElements={possibleItems} anythingHasBeenChanged={anythingHasBeenChanged} handleSave={handleSave} />

            <ReactFlow
                nodes={displayNodes}
                edges={displayEdges}
                nodeTypes={
                    {
                        nachricht: (props) => <NachrichtNode data={props.data} handleButtonClick={handleButtonClick} masterData={masterData} setMasterData={setMasterData} customVarsMap={customVarsMap} />,
                        filter: (props) => <FilterNode data={props.data} />
                    }}
                elementsSelectable={false}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onNodeClick={handleNodeClick}
                onEdgeClick={handleEdgeClick}
                minZoom={0.2}
                maxZoom={4}
            />
        </div>
    )

}


function SideBar({ draggableElements, anythingHasBeenChanged, handleSave }) {
    const [searchTerm, setSearchTerm] = useState("");

    const onDragStart = (event, groupId) => {
        event.dataTransfer.setData('application/reactflow', groupId);
        event.dataTransfer.effectAllowed = 'move';
    };

    console.log("draggableElements: ", draggableElements)

    return (
        <div className="sidebar">
            <div className='sidebarHeader'>
                <p>Facebook Nachrichten</p>
                <div className="sidebar-search">
                    <input
                        type="text"
                        placeholder="Suche..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>
            <div className='sidebarBody'>
                {draggableElements
                    .filter(element => element.name.toLowerCase().includes(searchTerm.toLowerCase()))
                    .map(element => (
                        <div
                            key={element.id}
                            onDragStart={(event) => onDragStart(event, element.id)}
                            draggable
                            data-group_id={element.id}
                            className="draggable-node"
                        >
                            {element.name}
                        </div>
                    ))}
            </div>
            <div className='sidebarFooter'>
                {anythingHasBeenChanged && <div className='saveBtn' onClick={handleSave}>Speichern</div>}
                {!anythingHasBeenChanged && <div className='saveBtn' style={{ backgroundColor: 'gray' }} >Speichern</div>}
            </div>
        </div>
    )
}



function NachrichtNode({ data, handleButtonClick, masterData, setMasterData, customVarsMap }) {



    console.log("masterData: ", masterData)
    console.log("data: ", data)

    //funktionen um die Auswahl zu handeln
    function handleVarChange(value, componentType, variableObj, nachrichtId) {
        //Mache eine Tiefe kopie von MasterDate und kopiere die Inhalte und speichere die als Variable
        const updatedMasterData = JSON.parse(JSON.stringify(masterData));
        let nachricht = updatedMasterData.nachrichten.find(n => n.id === nachrichtId);

        if (nachricht && nachricht.data.fb_msgComponentsVars[componentType]) {
            let variableKey = variableObj.name.replace(/{{|}}/g, '');
            if (nachricht.data.fb_msgComponentsVars[componentType][variableKey]) {
                nachricht.data.fb_msgComponentsVars[componentType][variableKey].value = value;
            }
        }

        // 3. Setze das aktualisierte masterData
        setMasterData(updatedMasterData);
    }


    function handleButtonChange(selectedNachrichtId, button, nachrichtId) {
        const updatedMasterData = JSON.parse(JSON.stringify(masterData));
        // Verwenden Sie `nachrichtId` statt `data.id`
        let nachricht = updatedMasterData.nachrichten.find(n => n.id === nachrichtId);

        if (nachricht && nachricht.data.fb_msgComponents.BUTTONS && nachricht.data.fb_msgComponents.BUTTONS[button.text]) {
            nachricht.data.fb_msgComponents.BUTTONS[button.text].followingMsgID = selectedNachrichtId;
        }

        setMasterData(updatedMasterData);
    }


    // Funktion, um Dropdown für Variablen zu rendern
    const renderButtonDropdown = (button, componentType, nachrichtId, idx, afters) => {
        let selectedValue = button.followingMsgID || "";

        return (
            <select
                key={idx}
                value={selectedValue}
                className={button.followingMsgID ? '' : 'unsetVariable'}
                onChange={(e) => handleButtonChange(e.target.value, button, nachrichtId)}
            >
                <option value="" disabled>Wähle eine Nachricht</option>
                <option value="ChatBeenden">Chat-beenden</option>
                <option value="Fortfahren">Fortfahren</option>
                {
                    afters.map(nachricht => (
                        <option key={nachricht.id} value={nachricht.id}>{nachricht.data.fb_msgName}</option>
                    ))
                }
            </select>
        );
    };



    const renderVariableDropdown = (variable, componentType, nachrichtId, idx) => {
        let variableKey = variable.replace(/{{|}}/g, '');
        let actualVariableObject = data.fb_msgComponentsVars[componentType][variableKey];

        return (
            <select
                key={idx}
                value={actualVariableObject.value}
                className={`whatsappMsgSelect ${!actualVariableObject.value ? 'unsetVariable' : ''}`}
                onChange={(e) => handleVarChange(e.target.value, componentType, actualVariableObject, nachrichtId)}
            >
                <option value="" disabled selected>Wähle eine Variable</option>
                {
                    Object.keys(customVarsMap).map((key) => (
                        <option key={key} value={key}>{key}</option>
                    ))
                }
                {availableVariables.map(varName => (
                    <option key={varName} value={varName}>{varName}</option>
                ))}
            </select>
        );
    };

    // Komponentendaten extrahieren
    const headerComponent = data.fb_msgComponents.HEADER; // Direkter Zugriff über Schlüssel
    const bodyComponent = data.fb_msgComponents.BODY; // Direkter Zugriff über Schlüssel
    const buttonsComponent = data.fb_msgComponents.BUTTONS ? data.fb_msgComponents.BUTTONS : []; // Hier ändern wir es zu einem Array.

    const afters = masterData.connections
        .filter(conn => conn.from === data.id)
        .map(conn => masterData.nachrichten.find(n => n.id === conn.to));



    // Verfügbare Variablen für Dropdown
    const availableVariables = ['Firma', 'Stellenbezeichnung', 'Bewerbername'];

    // Variablenmuster in Header und Body finden
    const variableMatchesHeader = headerComponent?.text.match(/{{\d+}}/g) || [];
    const variableMatchesBody = bodyComponent?.text.match(/{{\d+}}/g) || [];


    console.log("buttonsComponent: ", buttonsComponent)



    // Komponenten rendern
    return (
        <div className={`nachrichtNode ${data.extraClass ? data.extraClass : ''}`}>
            <div className="nodeSettings">
                {/* Knotensteuerungselemente */}
                <div className='nameBox'>
                    {data.fb_msgName}
                </div>
                <div className='btnBox'>
                    <div className="nodeButton" onClick={(e) => handleButtonClick(e, 'trashIcon', data.id, "msg")}>
                        <i className="fa-solid fa-trash" />
                    </div>
                    <div className="nodeButton" onClick={(e) => handleButtonClick(e, 'plugIcon', data.id, "msg")}>
                        <i className="fa-solid fa-plug" />
                    </div>
                    <div className="nodeButton" onClick={(e) => handleButtonClick(e, 'unPlug', data.id, "msg")}>
                        <i class="fa-solid fa-scissors"></i>
                    </div>
                </div>

            </div>

            {/* Header rendern */}
            {headerComponent && (
                <div className="nachrichtHeader">
                    {headerComponent.text.split(/({{\d+}})/).map((part, idx) =>
                        variableMatchesHeader.includes(part) ? renderVariableDropdown(part, 'HEADER', data.id, idx)
                            : part
                    )}
                </div>
            )}

            {/* Body rendern */}
            {bodyComponent && (
                <div className="nachrichtBody">
                    {bodyComponent.text.split(/({{\d+}})/).map((part, idx) =>
                        variableMatchesBody.includes(part) ? renderVariableDropdown(part, 'BODY', data.id, idx) : part
                    )}
                </div>
            )}

            {/* Buttons rendern */}
            {buttonsComponent && Object.keys(buttonsComponent).length > 0 && (
                <div className="nachrichtFooter">
                    {Object.keys(buttonsComponent).map((key, idx) => (
                        <div key={idx} className="nachrichtButton">
                            <i className="fa-solid fa-reply"></i>{buttonsComponent[key].text}
                            {renderButtonDropdown(buttonsComponent[key], 'BUTTON', data.id, idx, afters)}
                        </div>
                    ))}
                </div>
            )}



            {/* Verbindungspunkte rendern */}
            <Handle className="tHandle" style={{ left: "50%" }} type="target" position={Position.Top} isConnectable={false} />
            <Handle className="tHandle" style={{ right: "-50%" }} type="source" position={Position.Bottom} isConnectable={false} />
        </div>
    );
}



function FilterNode({ data }) {

}

