
import React from 'react';
import EditData from '../components/editData/editData';

function Employees() {
    const possibleCategorys = [{
        "name": "roles",
        "displayName": "🧑 Rollen"
    }, {
        "name": "employees",
        "displayName": "🧑 Mitarbeiter"
    }]

    return (

        <EditData categorys={possibleCategorys} />

    );
}

export default Employees;