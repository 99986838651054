import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from '@fullcalendar/core/locales/de';
import Select from 'react-select';
import './worktime.css';
import { useGetOrgaData } from '../../functions/api';


function WorkTimeCalendar() {
    const { loadOrganizationData } = useGetOrgaData();

    const [isLoading, setIsLoading] = useState(true);

    const [arbeitszeit, setArbeitszeit] = useState([]);
    const [mitarbeiter, setMitarbeiter] = useState([]);
    const [projekte, setProjekte] = useState([]);

    const [filteredArbeitszeiten, setFilteredArbeitszeiten] = useState([]);
    const [choosedMitarbeiter, setChoosedMitarbeiter] = useState([]);
    const [choosedProjekte, setChoosedProjekte] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            const [arbeitszeit, mitarbeiter, projekte, kunden] = await Promise.all([
                loadOrganizationData("/timeTrackings", {}),
                loadOrganizationData("/employees", {}),
                loadOrganizationData("/projects", {}),
                loadOrganizationData("/customers", {})
            ]);

            console.log("kunden: ", kunden)
            console.log("arbeitszeit: ", arbeitszeit);
            console.log("mitarbeiter: ", mitarbeiter);
            console.log("projekte: ", projekte);

            if (arbeitszeit && mitarbeiter && projekte) {



                setMitarbeiter(mitarbeiter.map(item => ({
                    value: item._id,
                    label: item.f_name
                })));
                setProjekte(projekte.map(item => {
                    const customer = kunden.find(kunde => kunde._id === item.customerRowID[0]);
                    const label = customer ? `${customer.f_name} - ${item.f_name}` : item.f_name;

                    return {
                        value: item._id,
                        label: label
                    };
                }));


                setArbeitszeit(formatEvents(arbeitszeit, projekte));
                setFilteredArbeitszeiten(formatEvents(arbeitszeit, projekte));
            }
            setIsLoading(false)
        }

        fetchData()
    }, []);


    if (isLoading) {
        <p>Loading...</p>
    }

    useEffect(() => {
        filterEvents();
    }, [choosedMitarbeiter, choosedProjekte]);

    const handleEmployeeChange = (selectedOptions) => {
        const selectedEmployees = selectedOptions.map(option => option.value);
        setChoosedMitarbeiter(selectedEmployees);
    }

    const handleProjectChange = (selectedOptions) => {
        const selectedProjects = selectedOptions.map(option => option.value);
        setChoosedProjekte(selectedProjects);
    }

    const filterEvents = () => {
        let filteredEvents = arbeitszeit;
        console.log("filteredEvents: ", filteredEvents);

        if (choosedMitarbeiter.length > 0) {
            filteredEvents = filteredEvents.filter(event => choosedMitarbeiter.includes(event.employee));
        }

        if (choosedProjekte.length > 0) {
            filteredEvents = filteredEvents.filter(event => choosedProjekte.includes(event.project));
        }

        setFilteredArbeitszeiten(filteredEvents);
    };

    const handleEventClick = (info) => {
        alert(`Event: ${info.event.title}`);
    }

    const formatEvents = (timeTrackings, projects) => {
        return timeTrackings.map(tracking => {
            const startDate = new Date(tracking.gemeldetAm);
            const endDate = new Date(tracking.gemeldetAm);
            endDate.setSeconds(endDate.getSeconds() + tracking.duration);

            const project = projects.find(proj => tracking.customerRowID[0] == proj.customer[0].row_id)
            return {
                title: project.customer[0].display_value + " - " + project.f_name + " - " + tracking.activity[0].display_value,
                start: startDate.toISOString(),
                end: endDate.toISOString(),
                employee: tracking.employees[0].row_id,
                project: tracking.projectRowID[0]  // Hier nehme ich an, dass projectRowID das Projekt repräsentiert. Passen Sie dies entsprechend an.
            };
        });
    };

    console.log("arbeitszeit: ", arbeitszeit);
    console.log("mitarbeiter: ", mitarbeiter);
    console.log("projekte: ", projekte);


    return (
        <div className='timeTracking'>
            <div className='timeTrackingHeader'>
                <div className='timeTrackingHeaderSelectCell'>
                    Mitarbeiter:
                    <Select
                        isMulti
                        name="employees"
                        options={mitarbeiter}
                        className="timeTrackingHeaderSelectCell-select"
                        classNamePrefix="select"
                        onChange={handleEmployeeChange}
                        maxMenuHeight={85}
                    />
                </div>

                <div className='timeTrackingHeaderSelectCell'>
                    Projekte:
                    <Select
                        isMulti
                        name="projects"
                        options={projekte}
                        className="timeTrackingHeaderSelectCell-select"
                        classNamePrefix="select"
                        onChange={handleProjectChange}
                        maxMenuHeight={85}
                    />
                </div>
            </div>

            <div className='timeTrackingBody'>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    allDaySlot={false}
                    initialView="timeGridWeek"
                    events={filteredArbeitszeiten}
                    eventClick={handleEventClick}
                    slotLabelFormat={[
                        { hour: '2-digit', minute: '2-digit', hour12: false }  // z.B. 09:00
                    ]}
                    dayHeaderFormat={{ weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true }}  // z.B. Mo 7.10.
                    locale={deLocale}
                    height="auto"
                    slotMinTime="06:00:00"
                    slotMaxTime="24:00:00"
                />
            </div>
        </div>
    )

}


export default WorkTimeCalendar