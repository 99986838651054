
import React from 'react';
import EditData from '../components/editData/editData';

function AutProjManager() {
    const possibleCategorys = [{
        "name": "cuSpaces",
        "displayName": "🧑 Arbeitsbereiche"
    }]

    return (

        <EditData categorys={possibleCategorys} />

    );
}

export default AutProjManager;