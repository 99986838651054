
import React from 'react';


function Troubleshoot() {

    return (

        <p>Trouobleshooting</p>

    );
}

export default Troubleshoot;