
import React from 'react';
import WorkTimeCalendar from '../components/worktime/worktime';



function WorkTimeCal() {
    return (
        <div className='mainBox'>
            <WorkTimeCalendar />
        </div>
    );
}

export default WorkTimeCal;