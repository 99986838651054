export function formatDataToDatebaseUpload(rawData, config) {
    let formattedData = { ...rawData }; // Eine Kopie von rawData erstellen

    for (let key in rawData) {
        for (let spalte of config.angezeigteSpalten) {
            if (spalte.name === key) {
                switch (spalte.type) {
                    case "singleSelect":
                        if (typeof rawData[key] === 'object' && rawData[key].value != null) {
                            formattedData[key] = rawData[key].value;
                        }
                        break;
                    case "singleLink":
                        formattedData[key] = [rawData[key].value];
                        break;
                    case "multiLink":
                        if (Array.isArray(rawData[key])) {
                            const isValidStructure = rawData[key].every(item =>
                                typeof item === 'object' && item.label && item.value
                            );
                            if (isValidStructure) {
                                formattedData[key] = rawData[key].map(item => item.value);
                            }
                        }
                        break;
                    default:
                        formattedData[key] = rawData[key];
                }
            }
        }
    }

    return formattedData;
}


export function toExtendedFormat(simpleArray, originalData) {
    // Gehe durch das einfache Array und suche nach jedem Wert im originalData
    return simpleArray.map(value => {
        // Finde das ursprüngliche Objekt, das diesen Wert enthält
        const originalItem = originalData.find(item => item.value === value);
        if (originalItem) {
            return originalItem;
        } else {
            // Wenn der Wert nicht gefunden wird, geben Sie ein Standardobjekt zurück
            return { label: value, value: value };
        }
    });
}