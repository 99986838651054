
import React, { useState, useEffect } from 'react';
import "./editData.css"
import settingsCategorys from "../../tableConfig.json"
import { useGetOrgaData, useGetData, usePostData, getDecodedJwtToken, useUpdateData } from '../../functions/api';
import { useDeleteObject } from '../../hooks/deleteElement.js';
import { RenderDataCell } from './spaltenTypen';
import FlowChart from '../flowchart/flowchart';
import { useNotification } from '../../contextProviders/NotificationContext';
import { formatDataToDatebaseUpload, toExtendedFormat } from './editDataHelpers';


function EditData({ categorys, customerID, orgaID, mode = "intern" }) {
    //Funktions
    const deleteObject = useDeleteObject();
    const { loadOrganizationData } = useGetOrgaData();
    const { getData } = useGetData()
    const { showNotification } = useNotification();
    const { updateData } = useUpdateData();
    const { postDataToEndpoint } = usePostData();

    //Config
    const [category, setCategory] = useState(categorys[0].name);
    const [activeConfig, setActiveConfig] = useState(settingsCategorys[category]);
    const [isLoading, setIsLoading] = useState(true);
    const [customerObj, setCustomerObj] = useState({});

    //Data
    const [data, setData] = useState(null)
    const [displayData, setDisplayData] = useState(null)
    const [zustatzDaten, setZusatzDaten] = useState({});
    const [editingId, setEditingId] = useState(null);
    const [editedValues, setEditedValues] = useState({});
    const [isAdding, setIsAdding] = useState(false);
    const [newRowDict, setNewRowDict] = useState({});


    const resetNewRowDict = () => {
        //Definiere notwendife Daten für eine Neue Zeile
        const tmpNewRowDict = {};
        settingsCategorys[category].angezeigteSpalten.forEach(spalte => {
            tmpNewRowDict[spalte.name] = null;
            if (spalte.type == "multiLink" || spalte.type == "singleLink") {
                tmpNewRowDict[spalte.name] = [];
            }
        });
        setNewRowDict(tmpNewRowDict)
        setIsAdding(false);
    }

    const resetStates = () => {
        setActiveConfig(settingsCategorys[category]);
        setIsLoading(true);
        setCustomerObj({});
        setData(null);
        setDisplayData(null);
        setZusatzDaten({});
        setEditingId(null);
        setEditedValues({});
        setIsAdding(false);
        setNewRowDict({});
    };

    useEffect(() => {
        //ToDo setze alle States zu dem Ausgangspunkt
        resetStates();

        resetNewRowDict();

        //Setze den richtigen Config
        setActiveConfig(settingsCategorys[category])
    }, [category]);

    //INIT
    useEffect(() => {
        if (categorys.map(cat => cat.name).includes(category)) {

            setIsLoading(true);

            const fetchData = async () => {
                // Datenarrays leeren.
                setData([]);
                setDisplayData([]);

                console.log("INIT: ", category)

                let fetchMainData = null
                let fetchLinkFieldsData = null
                // Ermittle die LinkFields für die aktuelle Kategorie.
                const linkFields = settingsCategorys[category].angezeigteSpalten.filter(spalte =>
                    spalte.type === "singleLink" || spalte.type === "multiLink"
                );


                // Hauptdatenanfrage für die aktuelle Kategorie initialisieren, aber noch nicht ausführen.
                if (mode == "intern") {
                    if (["activities", "activityGroups", "workflows"].includes(category)) {
                        fetchMainData = loadOrganizationData(settingsCategorys[category]['endpoint'], { "isTemplate": true });
                    } else {
                        fetchMainData = loadOrganizationData(settingsCategorys[category]['endpoint'], {});
                    }

                    // Initialisiere die Datenanfragen für alle LinkFields, aber führe sie noch nicht aus.
                    fetchLinkFieldsData = linkFields.map(linkFeld => {
                        if (linkFeld.linkTo.tbl === "tbl_cuStatus") {
                            return getData(linkFeld.linkTo.endpoint, {});
                        } else {
                            return loadOrganizationData(linkFeld.linkTo.endpoint, {});
                        }
                    });
                } else if (mode == "extern") {
                    fetchMainData = getData(activeConfig.endpoint, { "customerID": customerID })

                    // Initialisiere die Datenanfragen für alle LinkFields, aber führe sie noch nicht aus.
                    fetchLinkFieldsData = linkFields.map(linkFeld => {
                        if (linkFeld.linkTo.tbl === "tbl_cuStatus") {
                            return getData(linkFeld.linkTo.endpoint, {});
                        } else {
                            return getData(linkFeld.linkTo.endpoint, { 'organizationID': orgaID });
                        }
                    });
                }

                // Führe alle Anfragen gleichzeitig aus und warte, bis alle abgeschlossen sind.
                const [mainData, ...linkFieldsData] = await Promise.all([fetchMainData, ...fetchLinkFieldsData]);



                // Verarbeite die empfangenen Daten für jeden LinkField und speichere sie im Zustand.
                linkFields.forEach((linkFeld, index) => {
                    let dataResults = linkFieldsData[index];
                    setZusatzDaten(prev => ({ ...prev, [linkFeld.linkTo.tbl]: dataResults }));
                });

                if (customerID) {
                    let customerObj = await getData("/customers", { "rowID": customerID })

                    if (customerObj) {
                        console.log("customerObj: ", customerObj)
                        setCustomerObj(customerObj)
                    }
                }

                /*
                    Struktur der LinkFelder?
                    {
                        tbl_cuStatus: Array(8),
                        tbl_roles: Array(2)
                    }
                    Every dict in a Array is just the Dataset from SeaTable
                */
                // Speichere die Hauptdaten im Zustand.
                setData(mainData);
                setDisplayData(mainData);
                /*
                    Struktur der MainData
                    (11) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
                    Every dict in Array is just the Dataset from SeaTable
                */

                // Datenabfrage abgeschlossen: Setze den Ladezustand auf "false".
                setIsLoading(false);
            }
            fetchData();
        }
    }, [activeConfig]);

    //Filter
    const handleFilter = (value) => {
        if (!data) return;
        let filteredData = [];

        filteredData = data.filter(dataset =>
            dataset[activeConfig['filterField']].toLowerCase().includes(value.toLowerCase())
        );

        setDisplayData(filteredData);
    };

    //Änderung der MainData
    useEffect(() => {
        setDisplayData(data)
    }, [data]);










    //Handle Edit & Save
    const handleEditButton = async (id) => {

        console.log("ID: ", id);
        if (!editingId || editingId !== id) {
            setEditingId(id);
        } else if (editingId === id) {
            const formattedData = formatDataToDatebaseUpload(editedValues, activeConfig);

            let res = null;
            //if (activeConfig.tbl !== "tbl_activities") {
            //    res = await updateData(activeConfig.tbl, id, formattedData);
            //} else {
            res = await updateData(activeConfig.endpoint, id, formattedData);
            //}


            if (res) {
                //ToDo bei Verlinkgsfeldern wieder korrigieren
                console.log("res: ", res)
                let tmpData = [...data];

                for (let dataSet of tmpData) {
                    if (dataSet._id === id) {
                        for (let key in editedValues) {
                            // Überprüfen Sie, ob der bearbeitete Wert ein einfaches Array ist
                            if (Array.isArray(editedValues[key]) && editedValues[key].length > 0 && typeof editedValues[key][0] === "string") {
                                // Wenn ja, konvertieren Sie es zurück in das erweiterte Format
                                dataSet[key] = toExtendedFormat(editedValues[key], editedValues[key]); // Verwenden Sie originalValues[key] hier
                            } else {
                                dataSet[key] = editedValues[key];
                            }
                        }
                    }
                }


                setEditedValues({})
                setData(tmpData);
                setEditingId(null);
            }
        }
    }




    //[DONE] - HandleSaveNewRow
    const handleSave = async (id, name, value) => {
        // Extract the 'name' values from activeConfig's 'angezeigteSpalten' array
        const displayedColumnsNames = activeConfig.angezeigteSpalten.map(column => column.name);

        const allColumnsHaveValues = displayedColumnsNames.every(columnName => {
            return newRowDict.hasOwnProperty(columnName) && newRowDict[columnName] !== null && newRowDict[columnName] !== "";
        });

        if (allColumnsHaveValues) {
            showNotification("Vollständig", "good")
            let token = await getDecodedJwtToken()

            console.log("newRowDict: ", newRowDict)
            const formattedData = formatDataToDatebaseUpload(newRowDict, activeConfig);
            let res = null
            if (mode == "intern") {
                if (["activities", "activityGroups", "workflows"].includes(category)) {
                    res = await postDataToEndpoint({ ...formattedData, 'organization': [token.decodedToken.orgaId], "isTemplate": true }, activeConfig.endpoint)
                }
                else {
                    res = await postDataToEndpoint({ ...formattedData, 'organization': [token.decodedToken.orgaId] }, activeConfig.endpoint)
                }

            } else if (mode == "extern") {
                res = await postDataToEndpoint({ ...formattedData, 'customer': [customerID] }, activeConfig.endpoint)
            }
            if (res.status) {
                let tmpData = [...data]
                tmpData.push(res.data)
                setData(tmpData)
                resetNewRowDict();
            }

        } else {
            showNotification("Daten nicht vollständig", "error")
        }
    }

    //[DONE] - HandleDelete
    const handleDeleteButton = async (id) => {
        if (editingId && editingId == id) {
            setEditingId(null)
            setEditedValues({})
        } else {
            await deleteObject(activeConfig.tbl, id, data, setData);
        }
    }

    //[DONE] - HandleOnChangeValue
    const handleOnChange = async (id, name, value, type) => {
        if (!editingId) {
            console.log("onChange new Value: ", id, name, value)
            console.log("editingId: ", editingId)

            setNewRowDict(prevData => ({
                ...prevData,
                [name]: value
            }));

        } else {
            if (editingId == id) {
                console.log("onChange Editing Value: ", id, name, value)
                setEditedValues(prevData => ({
                    ...prevData,
                    [name]: value
                }));
            }
        }
    }




    if (isLoading) {
        return (<p>laden...</p>)
    }


    let html = null;


    //WhiteBoardKlassen
    if (category == "activityGroups" || category == "workflows") {
        html =
            <div className="EditDataContainerBody">
                {displayData.map(data => (
                    <WhiteBoardRow row={data} category={category} data={data} />
                ))}
                {!isAdding && <div className='addBtn' onClick={() => setIsAdding(true)}>{activeConfig['displayName']} erstellen</div>}
                {isAdding && <WhiteBoardRow category={category} data={data} setIsAdding={setIsAdding} />}
            </div>;
    }


    //NormaleKlassen
    else {
        html = (
            <div className="EditDataContainerBody">
                <div className='zeile zeileHeader'>
                    <div className="breite-0"></div>
                    <div className="breite-0"></div>
                    {activeConfig?.angezeigteSpalten?.map((spalte, index) =>
                        <div key={index} className={"EditDataZelle breite-" + spalte.breite}>{spalte.displayName}</div>
                    )}
                </div>
                {/* Jede Zeile im Dataset */}
                {displayData.map(data => (
                    <div key={data._id} className='zeile'>
                        <div className="breite-0" style={{ color: "red" }} onClick={() => handleDeleteButton(data._id)}>
                            <i className={editingId === data._id ? "fa-solid fa-times" : "fa-solid fa-trash"}></i>
                        </div>
                        <div className="breite-0" onClick={() => handleEditButton(data._id)}>
                            <i className={editingId === data._id ? "fa-solid fa-save" : "fa-solid fa-pencil"}></i>
                        </div>
                        {activeConfig?.angezeigteSpalten?.map(spalte => RenderDataCell(data, spalte, handleOnChange, false, editedValues, editingId, zustatzDaten, false))}
                    </div>
                ))}
                {
                    activeConfig['addingEnabled'] ?
                        isAdding ? (
                            <div key={data._id} className='zeile'>
                                <div className="breite-0" style={{ color: "red" }} onClick={() => { resetNewRowDict() }}>
                                    <i className={"fa-solid fa-times"}></i>
                                </div>
                                <div className="breite-0" onClick={() => handleSave()}>
                                    <i className={"fa-solid fa-save"}></i>
                                </div>
                                {activeConfig?.angezeigteSpalten?.map(spalte => RenderDataCell(newRowDict, spalte, handleOnChange, true, null, editingId, zustatzDaten, false))}
                            </div>
                        ) :
                            <div className='addBtn' onClick={() => setIsAdding(true)}>Neue Zeile</div>
                        : null
                }
            </div>
        )
    }

    return (

        <div className='mainBox'>
            {console.log("EditingValues: ", editedValues)}
            {/* HEADER */}
            <div className="EditDataContainerHeader">
                <div className="EditDataContainerHeader-views-contrainer">
                    {
                        categorys.map((categoryObj, idx) => (
                            <div
                                key={idx}
                                className={categoryObj.name === category ? "view-box view-box-selected" : "view-box"}
                                onClick={() => {
                                    setCategory(categoryObj.name);
                                    resetStates();
                                }}
                            >
                                {categoryObj.displayName} {customerID && "-"} {customerObj?.f_name}
                            </div>
                        ))

                    }
                </div>
                <div className="EditDataContainerHeader-filter-container">
                    <div className="header-info">
                        <label htmlFor="itemName">Name:</label>
                        <input
                            id="itemName"
                            onInput={e => handleFilter(e.target.value)}
                            className="filter-header-search-input"
                            placeholder="&#128269;Suche.."
                        />
                    </div>

                </div>
            </div>
            {/* Body */}
            {html}
        </div>
    );
}

export default EditData;


function WhiteBoardRow({ row, category, data, setIsAdding }) {


    const [isOpen, setIsOpen] = useState(false);

    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
        if (setIsAdding) {
            setIsAdding(false)
        }
    };

    useEffect(() => {
        if (!row) {
            setIsOpen(true)
        }
    }, []);

    let bodyHtml = null

    if (row) {
        if (isOpen) {
            bodyHtml = (
                <div className='FlowChartBox'>
                    <FlowChart row={row} category={category} />
                </div>

            )
        } else {
            bodyHtml = null
        }

        return (
            <>
                <div key={row._id} className='zeileWhiteBoardEdit'>
                    <div key={row._id} className='zeileWhiteBoardEdit-Header'>
                        <div className="breite-0" onClick={handleToggleOpen}>
                            {!isOpen && <i className="fa-solid fa-eye"></i>}
                            {isOpen && <i className="fa-solid fa-x" style={{ color: "red" }}></i>}
                        </div>
                        <div className={"EditDataZelle breite-4"}>
                            {row.name}
                        </div>
                    </div>
                    {/* BODY */}
                    {bodyHtml}
                </div>

            </>
        )
    } else {
        if (isOpen) {
            bodyHtml = (
                <div className='FlowChartBox'>
                    <FlowChart category={category} />
                </div>

            )
        } else {
            bodyHtml = null
        }

        return (
            <>
                <div className='zeileWhiteBoardEdit'>
                    <div className='zeileWhiteBoardEdit-Header'>
                        <div className="breite-0" onClick={handleToggleOpen}>
                            {!isOpen && <i className="fa-solid fa-eye"></i>}
                            {isOpen && <i className="fa-solid fa-x" style={{ color: "red" }}></i>}
                        </div>
                    </div>
                    {/* BODY */}
                    {bodyHtml}
                </div>

            </>
        )
    }

}