
import React from 'react';
import EditData from '../components/editData/editData';

function Settings() {
    const possibleCategorys = [{
        "name": "organizations",
        "displayName": "⚙️ Einstellungen"
    }]

    return (
        <EditData categorys={possibleCategorys} />
    );
}

export default Settings;


