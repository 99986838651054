import Modal from 'react-modal'; // Ein Beispiel mit 'react-modal'. Du kannst eine beliebige Modal-Library verwenden.
import { useNotification } from '../../contextProviders/NotificationContext.js';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { getDecodedJwtToken, useGetData, useGetOrgaData, usePostData } from '../../functions/api';
import settingsCategorys from "../../tableConfig.json"
import { formatDataToDatebaseUpload } from '../editData/editDataHelpers.js';
import { RenderDataCell } from '../editData/spaltenTypen.js';



function CustomModal({ modalConfig, setModalConfig }) {

    let classSetting = settingsCategorys[modalConfig.category];
    console.log("classSetting: ", classSetting)    
    const { postDataToEndpoint } = usePostData();
    const { showNotification } = useNotification();
    const [isLoading, setIsLoading] = useState(true)
    const [formData, setFormData] = useState({});


    const resetNewRowDict = () => {
        //Definiere notwendife Daten für eine Neue Zeile
        const tmpNewRowDict = {};
        classSetting.angezeigteSpalten.forEach(spalte => {
            tmpNewRowDict[spalte.name] = null;
            if (spalte.type == "multiLink" || spalte.type == "singleLink") {
                tmpNewRowDict[spalte.name] = [];
            }
        });
        setFormData(tmpNewRowDict)
    }

    //INIT
    useEffect(() => {
        if (modalConfig.open) {
            resetNewRowDict()
            const fetchData = async () => {
                setIsLoading(false)
            }
            setIsLoading(true)
            fetchData();
        } else {
            setIsLoading(false)
        }
    }, [])


    function handleCloseModal() {
        setFormData({});
        setModalConfig({ 'open': false, 'category': '', 'setObjects': null, 'parentID': '', 'setObject': null });
    }

    const handleFromInputChange = async (id, name, value, type) => {
        console.log("name: ", name, " Value: ", value)
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };




    const uploadObject = async (e) => {

        const displayedColumnsNames = classSetting.angezeigteSpalten.map(column => column.name);
        const allColumnsHaveValues = displayedColumnsNames.every(columnName => {
            return formData.hasOwnProperty(columnName) && formData[columnName] !== null && formData[columnName] !== "";
        });

        if (!allColumnsHaveValues) {
            showNotification("Daten nicht vollständig", "error")
        } else {
            const formattedData = formatDataToDatebaseUpload(formData, classSetting);
            console.log("[classSetting.parentID]: ", classSetting.parent)
            const postRes = await postDataToEndpoint({ ...formattedData, [classSetting.parent]: [modalConfig.parentID] }, classSetting.endpoint);
            console.log("postRes: ", postRes)

            if (postRes.status) {
                let tmpArryOfObjects = JSON.parse(JSON.stringify(modalConfig.objects))
                modalConfig.setObjects([...tmpArryOfObjects, postRes.data]);
                handleCloseModal()
            }
        }
    }


    if (isLoading) {
        return (<p>Loading...</p>)
    }

    if (!modalConfig.open) {
        return null
    }

    return (
        <>
            <Modal isOpen={modalConfig.open} className={"mainModal"} >
                <div className='modalStyle'>
                    <div className='modalHeader'>
                        <div className='modalName'>
                            {modalConfig.category}
                        </div>
                        <div className='closeModal' onClick={handleCloseModal}>
                            X
                        </div>
                    </div>
                    <div className='modalBody'>
                        {classSetting?.angezeigteSpalten?.map(spalte => RenderDataCell(formData, spalte, handleFromInputChange, true, null, null, null, true))}
                    </div>
                    <div className='addBtn' onClick={uploadObject}>
                        Speichern
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CustomModal


{/*
                        {type.table == "Kunde" &&
                            <form onSubmit={handleCreateNewCustomer}>
                                <input
                                    type="text"
                                    name="f_name"
                                    placeholder="Name"
                                    value={formData.f_name}
                                    onChange={handleFromInputChange}
                                    className="modern-input"
                                    required
                                />
                                <select
                                    name="type"
                                    value={formData.type}
                                    onChange={handleFromInputChange}
                                    className="modern-input"
                                    required
                                >
                                    <option value="company">Firmenkunde</option>
                                    <option value="privat">Privat</option>

                                </select>
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Adresse"
                                    value={formData.address}
                                    onChange={handleFromInputChange}
                                    className="modern-input"
                                    required
                                />
                                <input
                                    type="text"
                                    name="plz"
                                    placeholder="PLZ"
                                    value={formData.plz}
                                    onChange={handleFromInputChange}
                                    className="modern-input"
                                    required
                                />
                                <input
                                    type="text"
                                    name="city"
                                    placeholder="Stadt"
                                    value={formData.city}
                                    onChange={handleFromInputChange}
                                    className="modern-input"
                                    required
                                />
                                {formData.type == 'company' && (
                                    <input
                                        type="text"
                                        name="ustID"
                                        placeholder="UST-ID"
                                        value={formData.ustID}
                                        onChange={handleFromInputChange}
                                        className="modern-input"
                                        required
                                    />
                                )}
                                <button type="submit">Kunden anlegen</button>
                            </form>}

                        {type.table == "Person" &&
                            <form onSubmit={handleCreateNewPerson}>
                                <input
                                    type="text"
                                    name="firstname"
                                    placeholder="Vorname"
                                    value={formData.firstname}
                                    onChange={handleFromInputChange}
                                    className="modern-input"
                                    required
                                />
                                <input
                                    type="text"
                                    name="lastname"
                                    placeholder="Nachname"
                                    value={formData.lastname}
                                    onChange={handleFromInputChange}
                                    className="modern-input"
                                    required
                                />
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleFromInputChange}
                                    className="modern-input"
                                    required
                                />
                                <input
                                    type="text"
                                    name="phonenumber"
                                    placeholder="Telefonnummer"
                                    value={formData.phonenumber}
                                    onChange={handleFromInputChange}
                                    className="modern-input"
                                    required
                                />
                                <select
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleFromInputChange}
                                    className="modern-input"
                                    required
                                >
                                    <option value="herr">Männlich</option>
                                    <option value="frau">Weiblich</option>

                                </select>
                                <select
                                    name="speech"
                                    value={formData.speech}
                                    onChange={handleFromInputChange}
                                    className="modern-input"
                                    required
                                >
                                    <option value="du">Du</option>
                                    <option value="sie">Sie</option>

                                </select>
                                <button type="submit">Person anlegen</button>
                            </form>
                        }*/}