import { decodeToken } from 'react-jwt';
import { useNotification } from '../contextProviders/NotificationContext.js';

//OLD ADRESS -> const apiUrl = 'https://backend-3qc.ey.r.appspot.com';
const apiUrl = 'https://qc3backend.ey.r.appspot.com';



export function usePostData() {
    const { showNotification } = useNotification();

    async function postDataToEndpoint(payload, endpoint) {
        // Token aus dem localStorage abrufen
        const token = localStorage.getItem('jwtToken');

        console.log("payload: ", payload, " endpoint: ", endpoint)

        for (let key in payload) {
            if (typeof payload[key] === 'string') {
                payload[key] = payload[key].trim();
            }
        }

        try {

            let response = ''

            if (!endpoint.includes("https"))
            {
                response = await fetch(apiUrl + endpoint, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Token im Authorization-Header hinzufügen
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload)
                });
            }else{
                response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Token im Authorization-Header hinzufügen
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload)
                });
            }

            // Antwortkörper lesen
            let data = await response.json();
            console.log("[JSON-1] - response data: ", data, " endpoint: ", endpoint, " type: ", typeof data);

            // Prüfen, ob der Typ von 'data' 'string' ist, falls 'response.json()' nicht erwartet ein String zurückgibt
            if (typeof data == 'string') {
                try {
                    data = await JSON.parse(data); // Versucht den String in ein Objekt umzuwandeln
                    console.log("[JSON-2] - parsed data: ", data, " endpoint: ", endpoint, " type: ", typeof data);
                } catch (error) {
                    console.error('Fehler beim Parsen des JSON-Strings:', error);
                }
            } else {
                console.log("[JSON-4] - response data is already an object: ", data, " endpoint: ", endpoint, " type: ", typeof data);
            }

            if (!response.ok || data.status === false) {  // Prüfen Sie den Status der Antwort UND den Status in den Daten
                showNotification("[Verbindungsfehler]", "error")
                console.error("[ERROR] - [loadOrganizationData] - data:", data);
                return false;
            } else {
                showNotification("gespeichert", "good")
                console.log("[INFO] - [loadOrganizationData] - data:", data);
                return data;
            }

        } catch (error) {
            console.error("Error posting data:", error);
            throw error;
        }
    }

    return { postDataToEndpoint };
}


export function useUpdateData() {
    const { showNotification } = useNotification();


    async function updateData(tableOrEndpoint, rowID, newDataDict) {
        // Token aus dem localStorage abrufen
        const token = await localStorage.getItem('jwtToken');
        console.log("tableOrEndpoint:", tableOrEndpoint, " rowID:", rowID, " Update dict:", newDataDict)

        try {
            let endpoint;

            // Determine the correct endpoint based on the value of tableOrEndpoint
            if (tableOrEndpoint.substring(0, 4) === "tbl_") {
                endpoint = apiUrl + "/update";
            } else if (tableOrEndpoint.substring(0, 1) === "/") {
                endpoint = apiUrl + tableOrEndpoint;
            } else {
                throw new Error("Invalid tableOrEndpoint value");
            }

            for (let key in newDataDict) {
                if (typeof newDataDict[key] === 'string') {
                    newDataDict[key] = newDataDict[key].trim();
                }
            }

            const payload = {
                rowID: rowID,
                newData: newDataDict
            };

            // If the tableOrEndpoint begins with tbl_, add the "tbl" key to the payload
            if (tableOrEndpoint.substring(0, 4) === "tbl_") {
                payload.tbl = tableOrEndpoint;
            }


            console.log("Payload: ", payload)
            const response = await fetch(endpoint, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`, // Add the token to the Authorization header
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();
            console.log("response data: ", data, " endpoint: ", endpoint)

            if (!response.ok || data.status === false) {
                showNotification("[Verbindungsfehler]", "error");
                console.error("[ERROR] - [updateData] - data:", data);
                return false;
            } else {
                showNotification("gespeichert", "good");
                console.log("[INFO] - [updateData] - data:", data);
                return data;
            }

        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    }

    return { updateData };
}


export function useGetData() {
    const { showNotification } = useNotification();

    async function getData(endpointName, params = {}) {

        console.log("getDataFromEndpoint: ", " endpointName: ", endpointName, " params: ", params)

        const queryString = Object.keys(params)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
            .join('&');

        let endpoint = ''

        if (!endpointName.includes("https")) {
            endpoint = `${apiUrl}${endpointName}?${queryString}`;
        } else {
            endpoint = `${endpointName}?${queryString}`;
        }
        

        // Token aus dem localStorage abrufen
        const token = localStorage.getItem('jwtToken');

        try {
            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Token im Authorization-Header hinzufügen
                    'Content-Type': 'application/json'
                }
            });

            let r = await response.json()

            if (!r.status) {
                console.error("Res: ", r)
                showNotification("[Verbindungsfehler]", "error")
                return false
            } else {
                return r.data;
            }
        } catch (error) {
            showNotification("[Verbindungsfehler]", "error")
            console.error("[ERROR] - [loadOrganizationData] - error:", error);
            return [];
        }
    }

    return { getData };
}



export function useGetOrgaData() {
    const { getData } = useGetData(); // <-- Verwenden Sie den Hook an der obersten Ebene.

    async function loadOrganizationData(endpoint, zusatzParameter) {
        try {
            const decoded = await getDecodedJwtToken();
            let parameter = null;

            if (endpoint != "/organizations") {
                parameter = { "organizationID": decoded.decodedToken.orgaId, ...zusatzParameter }
            } else {
                parameter = { "rowID": decoded.decodedToken.orgaId, ...zusatzParameter }
            }
            const dataTmp = await getData(endpoint, parameter); // <-- Direkte Verwendung von getData.

            if (!dataTmp) {
                return [];
            } else {
                if (endpoint != "/organizations") {
                    return dataTmp;
                } else {
                    return [dataTmp]
                }

            }
        } catch (error) {
            console.error("[ERROR] - [loadOrganizationData] - error:", error);
            return [];
        }
    }

    return { loadOrganizationData }
}



export async function getDecodedJwtToken() {
    const decodedToken = decodeToken(localStorage.getItem("jwtToken"));
    return { decodedToken };
}



export async function deleteDataFromEndpoint(tableName, rowID) {
    //const { showNotification } = useNotification();
    //const { updateToken } = useUpdateToken();
    console.log("deleteDataFromEndpoint: ", " tableName: ", tableName, " rowID: ", rowID)

    const endpoint = `${apiUrl}/delete`;

    // Token aus dem localStorage abrufen
    const token = localStorage.getItem('jwtToken');

    try {
        const response = await fetch(endpoint, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`, // Token im Authorization-Header hinzufügen
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "rowID": rowID, "tableName": tableName })
        });

        const data = await response.json();
        if (!response.ok || data.status === false) {  // Prüfen Sie den Status der Antwort UND den Status in den Daten
            console.error("[ERROR] - [deleteDataFromEndpoint] - data:", data);
            return false;
        } else {
            console.log("[INFO] - [deleteDataFromEndpoint] - data:", data);
            return data;
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
}