export function setConfig(category) {
    return {
        flowChart: {
            level: 1,
            category: category,
            mode: "edit",
            startPos: {
                x: 0,
                y: 0
            }
        },
        nodeConfig: {
            NODE_WIDTH: 200,
            NODE_HEIGHT: 100,
            NODE_MARGIN: 80
        },
        tmpNode: {
            id: "VorlageID",
            data: { label: "Vorlage" },
            position: { x: 10, y: 10 },
            draggable: false,
            sourcePosition: 'right',
            targetPosition: 'left',
            type: "turbo"
        },
        groupConfig: {
            padding: 15,
            headerHeight: 55
        }
    }
}

//
//Plug
//
export function handlePlugBtn(clickedID, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData) {
    let tmpMasterData = JSON.parse(JSON.stringify(masterData));
    let { indexGroup, indexActivityInGroup } = findIdexOfActivity(masterData, clickedID, klasse);

    //überprüfe IDs
    if (klasse === "a" && (indexGroup === -1 || indexActivityInGroup === -1)) {
        return false;
    } else if (klasse === "ag" && indexGroup === -1) {
        return false;
    }

    if (!selectedItem) {
        if (klasse === "a") {
            let possibleConnections = findPossibleConnections(masterData.groups[indexGroup], indexActivityInGroup, klasse)

            tmpMasterData.groups[indexGroup].activities.forEach(activity => {
                if (possibleConnections.includes(activity.id)) {
                    activity['extraClass'] = 'targetConnection';
                }

                if (activity.id == clickedID) {
                    activity['extraClass'] = 'sourceConnection';
                }
            });
            setSelectedItem({ "id": clickedID, "type": btnType, "possibleIDs": possibleConnections })
        }
        else if (klasse === "ag") {
            let possibleConnections = findPossibleConnections(masterData, indexGroup, klasse)

            tmpMasterData.groups.forEach(grp => {
                if (possibleConnections.includes(grp.id)) {
                    grp['extraClass'] = 'targetConnection';
                }

                if (grp.id == clickedID) {
                    grp['extraClass'] = 'sourceConnection';
                }
            });
            setSelectedItem({ "id": clickedID, "type": btnType, "possibleIDs": possibleConnections })
        }
    } else {
        if (klasse === "a") {
            tmpMasterData.groups[indexGroup].connections.push({ "from": selectedItem.id, "to": clickedID })
        }
        else if (klasse === "ag") {
            tmpMasterData.connections.push({ "from": selectedItem.id, "to": clickedID })
        }

        resetExtraClass(tmpMasterData)
        setSelectedItem(null)
    }

    setMasterData(tmpMasterData)
    return true
}


//
//Unplug
//
export function handleUnPlugBtn(clickedID, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData) {
    let tmpMasterData = JSON.parse(JSON.stringify(masterData));
    let { indexGroup, indexActivityInGroup } = findIdexOfActivity(masterData, clickedID, klasse);

    //überprüfe IDs
    if (klasse === "a" && (indexGroup === -1 || indexActivityInGroup === -1)) {
        return false;
    } else if (klasse === "ag" && indexGroup === -1) {
        return false;
    }

    //erstmaliger Click
    if (!selectedItem) {
        if (klasse === "a") {
            let afters = tmpMasterData.groups[indexGroup].activities[indexActivityInGroup].afters;
            afters.forEach(after => {
                const activity = tmpMasterData.groups[indexGroup].activities.find(act => act.id === after);
                if (activity) activity['extraClass'] = 'targetUnplugConnection';
            });

            tmpMasterData.groups[indexGroup].activities[indexActivityInGroup]['extraClass'] = 'sourceUnplugConnection';
            setSelectedItem({ "id": clickedID, "type": btnType, "possibleIDs": afters })
        }
        if (klasse === "ag") {
            let afters = tmpMasterData.groups[indexGroup].afters;

            afters.forEach(after => {
                const activity = tmpMasterData.groups.find(group => group.id === after);
                if (activity) activity['extraClass'] = 'targetUnplugConnection';
            });

            tmpMasterData.groups[indexGroup]['extraClass'] = 'sourceUnplugConnection';
            setSelectedItem({ "id": clickedID, "type": btnType, "possibleIDs": afters })
        }
    } else {
        if (klasse === "a") {
            //unplug After A
            const group = tmpMasterData.groups[indexGroup];

            // Filter connections based on criteria
            group.connections?.filterInPlace(conn => !(conn.from === selectedItem.id && conn.to === clickedID));

            // Remove clickedID from the 'befores' of the activity
            const { befores } = group.activities[indexActivityInGroup];
            befores.splice(befores.indexOf(clickedID), 1);

            // Remove selectedItem.id from the 'afters' of the selected activity
            const { afters } = group.activities.find(act => act.id === selectedItem.id) || {};
            afters?.splice(afters.indexOf(selectedItem.id), 1);
        }
        if (klasse === "ag") {
            tmpMasterData.connections?.filterInPlace(conn => !(conn.from === selectedItem.id && conn.to === clickedID));

            // Remove clickedID from the 'befores' of the group
            const { befores } = tmpMasterData.groups[indexGroup];
            befores.splice(befores.indexOf(clickedID), 1);

            // Remove selectedItem.id from the 'afters' of the selected activity
            const { afters } = tmpMasterData.groups.find(grp => grp.id === selectedItem.id) || {};
            afters?.splice(afters.indexOf(selectedItem.id), 1);
        }
        setSelectedItem(null)
        resetExtraClass(tmpMasterData);
    }
    setMasterData(tmpMasterData);
    return true
}


/*
HandleBtn Done A && AG
*/
export function handleDeleteBtn(clickedID, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData) {
    let tmpMasterData = JSON.parse(JSON.stringify(masterData));
    let { indexGroup, indexActivityInGroup } = findIdexOfActivity(masterData, clickedID, klasse);


    //console.log("handleDeleteBtn: ", tmpMasterData, indexGroup, indexActivityInGroup)
    //überprüfe IDs
    if (klasse === "a" && (indexGroup === -1 || indexActivityInGroup === -1)) {
        return false;
    } else if (klasse === "ag" && indexGroup === -1) {
        return false;
    }

    if (klasse === "ag") {
        // Filtere Gruppen, um die Gruppe mit der spezifischen ID zu entfernen
        tmpMasterData.groups = tmpMasterData.groups.filter(group => group.id !== clickedID);

        // Filtere Verbindungen, um diejenigen zu entfernen, die entweder 'from' oder 'to' mit der ID haben
        tmpMasterData.connections = tmpMasterData.connections.filter(conn => conn.from !== clickedID && conn.to !== clickedID);

        //console.log("deleted AG: ", tmpMasterData)
    }
    if (klasse === "a") {
        // Entferne die Aktivität aus der Gruppe
        tmpMasterData.groups[indexGroup].activities.splice(indexActivityInGroup, 1);

        // Wenn die Gruppe eine connections Eigenschaft hat, filtere diese Verbindungen
        if (tmpMasterData.groups[indexGroup].connections) {
            tmpMasterData.groups[indexGroup].connections = tmpMasterData.groups[indexGroup].connections.filter(conn => conn.from !== clickedID && conn.to !== clickedID);
        }
    }

    setMasterData(tmpMasterData);
    setSelectedItem(null)
    return true
}





export function handleEditItem(clickedID, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData, tmpActivities, tmpActivityGroups, setDraggableElements, setIsGroupEditMode) {
    let tmpMasterData = JSON.parse(JSON.stringify(masterData));
    let { indexGroup, indexActivityInGroup } = findIdexOfActivity(masterData, clickedID, klasse);

    //console.log("handleDeleteBtn: ", tmpMasterData, indexGroup, indexActivityInGroup)
    //überprüfe IDs
    if (klasse === "a" && (indexGroup === -1 || indexActivityInGroup === -1)) {
        return false;
    } else if (klasse === "ag" && indexGroup === -1) {
        return false;
    }

    setSelectedItem({ "id": clickedID, "type": btnType, "possibleIDs": [] })

    if (klasse == "ag") {
        tmpMasterData.groups[indexGroup]['extraClass'] = 'editGroup';
        setDraggableElements(tmpActivities)
        setIsGroupEditMode({ 'id': clickedID, 'groupIndex': indexGroup })
    }

    setMasterData(tmpMasterData);
    return true
}




Array.prototype.filterInPlace = function (predicate) {
    let j = 0;
    for (let i = 0; i < this.length; i++) {
        if (predicate(this[i])) {
            if (i !== j) this[j] = this[i];
            j++;
        }
    }
    this.length = j;
    return this;
};


export function resetExtraClass(tmpMasterData) {
    tmpMasterData.groups.forEach(group => {
        group['extraClass'] = '';
        group.activities.forEach(activity => {
            activity['extraClass'] = '';
        });
    });
}

function findIdexOfActivity(masterData, id, klasse) {
    //console.log("Search in Klasse: ", klasse)
    let indexGroup = -1;
    let indexActivityInGroup = -1;

    if (klasse == "a") {
        for (let i = 0; i < masterData.groups.length; i++) {
            const group = masterData.groups[i];
            const activityIndex = group.activities.findIndex(activity => id === activity.id);

            if (activityIndex !== -1) {
                indexGroup = i;
                indexActivityInGroup = activityIndex;
                break;
            }
        }

        return { indexGroup, indexActivityInGroup }
    } else if (klasse == "ag") {
        for (let i = 0; i < masterData.groups.length; i++) {
            const group = masterData.groups[i];
            if (group.id == id) {
                indexGroup = i;
                break;
            }
        }

        return { indexGroup, indexActivityInGroup }
    }
}

export function findPossibleConnections(parent, taskOrGroupIndex, klasse) {
    // Die Liste der möglichen Items, die hinzugefügt werden können, ohne einen Loop zu erzeugen.
    let possibleItems = [];
    let clickedID = null
    let childKey = ""

    if (klasse == "a") {
        childKey = "activities";
    } else if (klasse == "ag") {
        childKey = "groups";
    } else if (klasse == "msg") {
        childKey = "nachrichten";
    }

    clickedID = parent[childKey][taskOrGroupIndex].id

    // Prüfe jede Aktivität innerhalb des Elterns ob diese in Fragekommt
    for (let taskOrGroup of parent[childKey]) {

        // Wenn die Aktivität noch nicht im Pfad ist.
        if (!pathIncludesActivity(parent.connections, clickedID, taskOrGroup.id)) {

            // Füge eine temporäre Verbindung hinzu, um zu überprüfen, ob sie einen Zyklus erzeugt.
            let tmpConnections = JSON.parse(JSON.stringify(parent.connections))

            //parent.connections.push({ from: clickedID, to: taskOrGroup.id });
            console.log("temporäre verbindung davor: ", tmpConnections)
            console.log("füge temporäre verbindung von ", clickedID, " to ", taskOrGroup.id)
            tmpConnections.push({ from: clickedID, to: taskOrGroup.id })
            console.log("temporäre verbindung danach: ", tmpConnections)
            // Wenn das Hinzufügen der Verbindung keinen Zyklus erzeugt.
            if (!createsCycle(tmpConnections, clickedID)) {
                // Füge die Aktivität der Liste der möglichen Items hinzu.
                possibleItems.push(taskOrGroup.id);
            }

            // Entferne die temporäre Verbindung.
            //parent.connections.pop();
        }
    }

    // Gibt die Liste der möglichen Items zurück.
    return possibleItems;
}

export function pathIncludesActivity(connections, from, to) {
    // Überprüft, ob der Pfad bereits eine Verbindung von 'from' nach 'to' enthält.
    return connections.some(connection => connection.from === from && connection.to === to);
}

/*
export function createsCycle(connections, start) {
    // Überprüft, ob das Hinzufügen einer Verbindung einen Zyklus erzeugt.

    console.log("createCycle Start: connections:", connections, " start:", start)


    // Erstelle eine Liste der besuchten Knoten.
    let visited = new Set();

    // Beginne mit dem Startknoten.
    let stack = [start];

    // Während der Stack nicht leer ist.
    while (stack.length > 0) {
        let node = stack.pop();

        // Wenn der Knoten bereits besucht wurde, erzeugt es einen Zyklus.
        if (visited.has(node)) {
            console.log("Node created Cycle: ", node)
            return true;
        }

        // Markiere den Knoten als besucht.
        visited.add(node);

        // Füge alle Nachbarknoten zum Stack hinzu.
        for (let connection of connections.filter(c => c.from === node)) {
            stack.push(connection.to);
        }
    }

    // Kein Zyklus gefunden.
    return false;
}
*/
export function createsCycle(connections, start) {
    console.log("createCycle Start: connections:", connections, " start:", start);

    // 'visited' speichert alle Knoten, die bereits besucht wurden.
    let visited = new Set();
    // 'pathStack' speichert den aktuellen Pfad der Tiefensuche.
    let pathStack = [];

    // Eine Hilfsfunktion für die Tiefensuche (DFS).
    function dfs(node) {
        // Überprüfe, ob der aktuelle Knoten bereits besucht wurde.
        if (visited.has(node)) {
            // Wenn der Knoten bereits besucht wurde und sich im aktuellen Pfad befindet, liegt ein Zyklus vor.
            if (pathStack.includes(node)) {
                console.log("Node created Cycle: ", node);
                return true;
            }
            // Wenn der Knoten schon besucht wurde, aber nicht im aktuellen Pfad ist, fahre fort ohne Zyklus.
            return false;
        }

        // Markiere den Knoten als besucht und füge ihn zum aktuellen Pfad hinzu.
        visited.add(node);
        pathStack.push(node);

        // Durchlaufe alle Nachbarknoten des aktuellen Knotens.
        for (let connection of connections.filter(c => c.from === node)) {
            // Rekursiver Aufruf der DFS-Funktion für jeden Nachbarknoten.
            if (dfs(connection.to)) {
                // Wenn ein Zyklus gefunden wird, beende die Suche und gebe 'true' zurück.
                return true;
            }
        }

        // Nachdem alle Nachbarknoten besucht wurden, entferne den aktuellen Knoten aus dem Pfad.
        pathStack.pop();
        // Kein Zyklus gefunden, gebe 'false' zurück.
        return false;
    }

    // Starte die Tiefensuche beim Startknoten.
    return dfs(start);
}