//LIbs
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useParams,
  Navigate
} from "react-router-dom";
import { useEffect, useState } from "react";


//Components
import ShareItems from "./components/shareItems/shareItems.js"
import Menu from "./components/menu/menu.js";

import WorkflowPlanner from "./sites/site_WorkflowPlanner.js";
import SsadCenter from "./sites/site_ssadCenter.js";
import AutProjManager from "./sites/site_autProjektManager.js";
import Overview from "./sites/site_overview.js";
import Kampagnen from "./sites/site_kampagnen.js";
import Chatbot from "./sites/site_chatbot.js";
import CompanyKPI from "./sites/site_companyKPI.js";

//Styles
import './App.css';
import './style/style.css';
import WorkTimeCal from "./sites/site_worktime.js";
import Employees from "./sites/site_employees.js";
import Settings from "./sites/site_settings.js";
import LogIn from "./sites/site_login.js";
import { NotificationProvider } from "./contextProviders/NotificationContext.js";
import { ModalProvider } from "./contextProviders/ModalContext.js";
import OnbAutomations from "./sites/site_onbAutomations.js";
import Troubleshoot from "./sites/site_troubleshoot.js";
import AfterSaleForm from "./components/afterSaleForm/afterSaleForm.js";
import WorkflowsProgress from "./sites/site_workflowsProgress.js";


function SharedRoutes() {
  const { type, orgaID, customerID } = useParams();


  if (type && orgaID && customerID) {
    // Sie können hier die spezifische Komponente basierend auf weiteren Pfadinformationen oder anderen Bedingungen laden.
    return (
      <div className="App-Share">
        <ShareItems orgaID={orgaID} customerID={customerID} type={type} />
      </div>
    );
  }
  return <h1>Falscher Link</h1>;
}

function ProtectedRoute({ children, hasAccess, redirectTo = '/settings' }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasAccess) {
      navigate(redirectTo);
    }
  }, [hasAccess, navigate, redirectTo]);

  if (!hasAccess) {
    return null;
  }

  return children;
}

function RedirectToSettings() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/settings");
  }, [navigate]);

  return null;  // Dies gibt nur null zurück, da Sie tatsächlich nichts rendern wollen; Sie wollen nur umleiten.
}



function ProtectedRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('jwtToken');

  const hasAccessTo = (module) => {
    const orgaData = JSON.parse(localStorage.getItem('cookieOrgaData'));
    switch (module) {
      case "agentur_cockpit_modul":
        return orgaData?.agentur_cockpit_modul;
      case "chatbot_modul":
        return orgaData?.chatbot_modul;
      default:
        return false;
    }
  }


  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate, location.pathname]);

  if (token) {
    return (
      <div className="App">
        <div className="App-Menu">
          <Menu />
        </div>
        <div className="App-Main">
          <Routes>

            <Route path="/workflow" element={
              <ProtectedRoute hasAccess={hasAccessTo("agentur_cockpit_modul")}>
                <WorkflowPlanner />
              </ProtectedRoute>
            } />

            <Route path="/ssad-center" element={
              <ProtectedRoute hasAccess={hasAccessTo("agentur_cockpit_modul")}>
                <SsadCenter />
              </ProtectedRoute>
            } />
            <Route path="/onbAutomations" element={
              <ProtectedRoute hasAccess={hasAccessTo("agentur_cockpit_modul")}>
                <OnbAutomations />
              </ProtectedRoute>
            } />

            <Route path="/proj_manager" element={
              <ProtectedRoute hasAccess={hasAccessTo("agentur_cockpit_modul")}>
                <AutProjManager />
              </ProtectedRoute>
            } />

            <Route path="/overview" element={
              <ProtectedRoute hasAccess={hasAccessTo("agentur_cockpit_modul")}>
                <Overview />
              </ProtectedRoute>
            } />
            <Route path="/workflowsProgress" element={
              <ProtectedRoute hasAccess={hasAccessTo("agentur_cockpit_modul")}>
                <WorkflowsProgress />
              </ProtectedRoute>
            } />
            <Route path="/kampagnen" element={
              <ProtectedRoute hasAccess={hasAccessTo("agentur_cockpit_modul")}>
                <Kampagnen />
              </ProtectedRoute>
            } />

            <Route path="/kpi" element={
              <ProtectedRoute hasAccess={hasAccessTo("agentur_cockpit_modul")}>
                <CompanyKPI />
              </ProtectedRoute>
            } />
            <Route path="/timetracking" element={
              <ProtectedRoute hasAccess={hasAccessTo("agentur_cockpit_modul")}>
                <WorkTimeCal />
              </ProtectedRoute>
            } />

            <Route path="/employees" element={
              <ProtectedRoute hasAccess={hasAccessTo("agentur_cockpit_modul")}>
                <Employees />
              </ProtectedRoute>
            } />


            <Route path="/chatbot" element={
              <ProtectedRoute hasAccess={hasAccessTo("chatbot_modul")}>
                <Chatbot />
              </ProtectedRoute>
            } />


            <Route path="/settings" element={
              <Settings />
            } />

            <Route path="*" element={<RedirectToSettings />} />

          </Routes>
        </div>
      </div>
    );
  }

  return null;
}


function App() {
  return (
    <NotificationProvider>
      <ModalProvider>
        <Router>
          <Routes>
            <Route path="/s/troubleshooting/:taskID" element={<Troubleshoot />} />
            <Route path="/s/:type/:orgaID/:customerID" element={<SharedRoutes />} />
            <Route path="/s/afterSale/:organizationID" element={<AfterSaleForm />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="*" element={<ProtectedRoutes />} />
          </Routes>
        </Router>
      </ModalProvider>
    </NotificationProvider>
  );
}


export default App;

