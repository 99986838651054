
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

function CompanyKPI() {
    return (
        /*
        <div className='mainBox'>
            <MyChartComponent
                token="xgLGnHGwjGfmkpmGYUynRTivxpxyw7dXPKX-N0Y7cp9q7Awa"
            />
        </div>
        */
        <h1>...</h1>
    );
}
/*
function MyChartComponent({ token }) {
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({
        chart: {
            type: 'line',
            zoom: {
                enabled: true, // Ermöglicht das Zoomen
                type: 'x', // Zoom-Typ
            },
            toolbar: {
                autoSelected: 'zoom' // Standardmäßig auf Zoom-Tool eingestellt
            }
        },
        colors: ['#008FFB', '#00E396'],
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                formatter: function (value, timestamp) {
                    // Hier können Sie das Format basierend auf dem Zoom-Level anpassen
                    // Zum Beispiel: dd/mm/yyyy für normale Ansicht und dd/mm für gezoomte Ansicht
                    // Diese Logik können Sie je nach Ihren Bedürfnissen anpassen
                    return new Date(timestamp).toLocaleDateString('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    });
                }
            }
        },
        yaxis: {
            title: {
                text: 'Total Amount'
            },
            labels: {
                formatter: function (value) {
                    return value.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    });
                }
            }
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy' // Format der Tooltip-Daten
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
    });

    const findDateRange = (data) => {
        let minDate = new Date(data[0].updatedDate);
        let maxDate = new Date(data[0].updatedDate);

        data.forEach(item => {
            const currentDate = new Date(item.updatedDate);
            if (currentDate < minDate) minDate = currentDate;
            if (currentDate > maxDate) maxDate = currentDate;
        });

        return { minDate, maxDate };
    };

    const fillDateRangeByMonth = (minDate, maxDate) => {
        const dateMap = {};
        for (let date = new Date(minDate); date <= maxDate; date.setMonth(date.getMonth() + 1)) {
            const monthKey = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
            dateMap[monthKey] = 0;
        }
        return dateMap;
    };

    const preciseAddition = (a, b) => {
        const precision = 100; // oder eine andere geeignete Genauigkeitsstufe
        return ((Math.round(a * precision) + Math.round(b * precision)) / precision);
    };

    const groupDataByMonth = (data) => {
        const { minDate, maxDate } = findDateRange(data);
        const groupedData = fillDateRangeByMonth(minDate, maxDate);

        data.forEach(item => {
            const date = new Date(item.createdDate);
            const monthKey = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
            groupedData[monthKey] = preciseAddition(groupedData[monthKey] || 0, parseFloat(item.totalAmount));
        });

        return Object.entries(groupedData).map(([key, value]) => ({ x: parseInt(key), y: value }));
    };


    // Funktion zum Laden von Daten von der API
    const loadData = async (status, item) => {
        try {
            const response = await fetch(`https://qc3backend.ey.r.appspot.com/get-voucher-data?token=${token}&item=${item}&status=${status}`);

            if (!response.ok) {
                const errorData = await response.json(); // Versuchen, den Fehlerkörper zu lesen
                console.error("Error Response:", errorData);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log("data: ", data)
            // Angenommen, 'data' ist eine Array von Objekten


            // Verwenden der Hilfsfunktion, um Daten zu gruppieren
            //const processedData = groupDataByDay(data);

            return data

        } catch (error) {
            console.error('Fehler beim Laden der Daten:', error);
        }
    };

    useEffect(() => {
        loadData("accepted", "quotation").then(processedData => {
            const seriesData = [{ name: "Accepted Quotations", data: groupDataByMonth(processedData) }];
            console.log("Accepted Quotations: ", seriesData[0].data);

            loadData("paid", "invoice").then(processedData => {
                seriesData.push({ name: "Paid Invoices", data: groupDataByMonth(processedData) });
                console.log("Paid Invoices: ", seriesData[1].data);

                setSeries(seriesData);
            });
        });

    }, [token]);



    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="line" height={350} />
        </div>
    );
};

*/
export default CompanyKPI;