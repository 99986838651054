import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./menu.css";

function Menu() {
    const navigate = useNavigate();
    const location = useLocation();

    const isActive = (path) => location.pathname === path;
    const handleLogout = () => {
        // JWT-Token aus dem localStorage entfernen
        localStorage.removeItem('jwtToken');

        navigate('/login');
    }


    const orgaData = JSON.parse(localStorage.getItem('cookieOrgaData'));
    const userData = JSON.parse(localStorage.getItem('cookieUserData'));


    if (!orgaData || !userData) {        // JWT-Token aus dem localStorage entfernen
        localStorage.removeItem('jwtToken');

        navigate('/login');
    }

    console.log("MENU: ORGA: ", orgaData)
    console.log("MENU: userData: ", userData)


    const hasAccessTo = (module) => {
        switch (module) {
            case "agentur_cockpit_modul":
                return orgaData?.agentur_cockpit_modul;
            case "chatbot_modul":
                return orgaData?.chatbot_modul;
            default:
                return false;
        }
    }

    const isUserRole = (role) => {
        return userData?.role3qc === role;
    }

    return (
        <div className="menu-container">
            <div className="menu-logo menu-punkt-aktiv">{orgaData.name}</div>
            <p className="userMenu">
                {userData.firstname + " " + userData.lastname}
            </p>
            {hasAccessTo("agentur_cockpit_modul") &&
                <>
                    <Link to="/workflow" className={`menu-punkt ${isActive('/workflow') ? 'menu-punkt-aktiv' : ''}`}>
                        <div className="menu-punkt-emoji"><i className="fa-sharp fa-solid fa-timeline" /></div>
                        <div className="menu-punkt-text">Neuer Workflow</div>
                    </Link>
                    {isUserRole("admin") || isUserRole("ceo") &&

                        <Link to="/ssad-center" className={`menu-punkt ${isActive('/ssad-center') ? 'menu-punkt-aktiv' : ''}`}>
                            <div className="menu-punkt-emoji"><i className="fa-solid fa-list-check"></i></div>
                            <div className="menu-punkt-text">System-Konfigurator</div>
                        </Link>
                    }
                    {isUserRole("admin") || isUserRole("ceo") &&
                        <Link to="/onbAutomations" className={`menu-punkt ${isActive('/onbAutomations') ? 'menu-punkt-aktiv' : ''}`}>
                            <div className="menu-punkt-emoji"><i class="fa-solid fa-robot"></i></div>
                            <div className="menu-punkt-text">Automations</div>
                        </Link>
                    }
                    {isUserRole("admin") || isUserRole("ceo") &&
                        <Link to="/proj_manager" className={`menu-punkt ${isActive('/proj_manager') ? 'menu-punkt-aktiv' : ''}`}>
                            <div className="menu-punkt-emoji"><i className="fa-solid fa-person-chalkboard" /></div>
                            <div className="menu-punkt-text">Bereiche</div>
                        </Link>
                    }
                    {isUserRole("admin") || isUserRole("ceo") &&
                        <Link to="/overview" className={`menu-punkt ${isActive('/overview') ? 'menu-punkt-aktiv' : ''}`}>
                            <div className="menu-punkt-emoji"><i className="fa-solid fa-users-rectangle" /></div>
                            <div className="menu-punkt-text">Overview</div>
                        </Link>
                    }
                    {isUserRole("admin") || isUserRole("ceo") &&
                        <Link to="/workflowsProgress" className={`menu-punkt ${isActive('/workflowsProgress') ? 'menu-punkt-aktiv' : ''}`}>
                            <div className="menu-punkt-emoji"><i class="fa-solid fa-battery-quarter"></i></div>
                            <div className="menu-punkt-text">Fortschritt</div>
                        </Link>
                    }
                    {isUserRole("admin") || isUserRole("ceo") &&
                        <Link to="/kampagnen" className={`menu-punkt ${isActive('/kampagnen') ? 'menu-punkt-aktiv' : ''}`}>
                            <div className="menu-punkt-emoji"><i className="fa-solid fa-chart-line"></i></div>
                            <div className="menu-punkt-text">Monitoring</div>
                        </Link>
                    }
                    {isUserRole("ceo") &&
                        <Link to="/kpi" className={`menu-punkt ${isActive('/kpi') ? 'menu-punkt-aktiv' : ''}`}>
                            <div className="menu-punkt-emoji"><i className="fa-solid fa-chart-simple"></i></div>
                            <div className="menu-punkt-text">Firmen KPIs</div>
                        </Link>
                    }
                    {isUserRole("admin") || isUserRole("ceo") &&
                        <Link to="/timetracking" className={`menu-punkt ${isActive('/timetracking') ? 'menu-punkt-aktiv' : ''}`}>
                            <div className="menu-punkt-emoji"><i className="fa-regular fa-calendar"></i></div>
                            <div className="menu-punkt-text">Mitarbeiterzeiten</div>
                        </Link>
                    }
                    {isUserRole("admin") || isUserRole("ceo") &&
                        <Link to="/employees" className={`menu-punkt ${isActive('/employees') ? 'menu-punkt-aktiv' : ''}`}>
                            <div className="menu-punkt-emoji"><i className="fa-solid fa-person"></i></div>
                            <div className="menu-punkt-text">Rollen & Mitarbeiter</div>
                        </Link>
                    }
                </>
            }
            {hasAccessTo("chatbot_modul") &&
                <Link to="/chatbot" className={`menu-punkt ${isActive('/chatbot') ? 'menu-punkt-aktiv' : ''}`}>
                    <div className="menu-punkt-emoji"><i className="fa-brands fa-android"></i></div>
                    <div className="menu-punkt-text">Chatbot</div>
                </Link>
            }
            {isUserRole("ceo") &&
                <Link to="/settings" className={`menu-punkt ${isActive('/settings') ? 'menu-punkt-aktiv' : ''}`}>
                    <div className="menu-punkt-emoji"><i className="fa-solid fa-gear"></i></div>
                    <div className="menu-punkt-text">Einstellungen</div>
                </Link>
            }




            <div onClick={handleLogout} className={`menu-punkt ${isActive('/logout') ? 'menu-punkt-aktiv' : ''}`}>
                <div className="menu-punkt-emoji"><i className="fa-solid fa-sign-out-alt"></i></div>
                <div className="menu-punkt-text">Logout</div>
            </div>

        </div>
    );
}

export default Menu;