
import React, { useEffect, useState, useRef } from 'react';
import FlowChart from '../components/flowchart/flowchart';
import { getDecodedJwtToken, useGetData, useGetOrgaData, usePostData } from '../functions/api';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './workflowPlanner.css';
import Modal from 'react-modal'; // Ein Beispiel mit 'react-modal'. Du kannst eine beliebige Modal-Library verwenden.
import { useNotification } from '../contextProviders/NotificationContext.js';
import CustomModal from '../components/creationModals/creationModal';


function WorkflowPlanner() {
    //Functions
    const { showNotification } = useNotification();
    const { loadOrganizationData } = useGetOrgaData();
    const { getData } = useGetData();

    //Config
    const [isLoading, setIsLoading] = useState(true)
    const [modalConfig, setModalConfig] = useState({ 'open': false, 'category': '', 'setObjects': null, 'parentID': '', 'setObject': null })
    const [neededRoles, setNeededRoles] = useState([])
    const [customerDataGeladen, setCustomerDataGeladen] = useState(false)
    const currentDate = new Date().toISOString().split('T')[0];
    const [orgaID, setOrgaID] = useState(null)

    const [isCustomerSelectOpen, setIsCustomerSelectOpen] = useState(false);
    const [isPersonSelectOpen, setIsPersonSelectOpen] = useState(false);
    const [isProjectSelectOpen, setIsProjectSelectOpen] = useState(false);

    //orgaDataCookie
    const orgaData = JSON.parse(localStorage.getItem('cookieOrgaData'));


    // Überprüfen der Werte der Input-Felder
    const [workflowName, setWorkflowName] = useState("");
    const [workflowStartDate, setWorkflowStartDate] = useState(currentDate);

    //Auswählbare Items
    const [activitiesVorlagen, setActivitiesVorlagen] = useState([])
    const [workflowsVorlagen, setWorkflowsVorlagen] = useState([])
    const [rolesVorlagen, setRolesVorlagen] = useState([])
    const [employeesVorlagen, setEmployeesVorlagen] = useState([])
    const [customersVorlagen, setCustomersVorlagen] = useState([])
    const [projekteVorlagen, setProjekteVorlagen] = useState([])
    const [AnsprechpersonenVoralgen, setAnsprechpersonenVoralgen] = useState([])
    const [autoOnboardingsVorlagen, setAutoOnboardingsVorlagen] = useState([])
    const [arbeitsbereicheVorlagen, setArbeitsbereicheVorlagen] = useState(null)
    const [kundenListenVorlagen, setKundenListenVorlagen] = useState([])

    //ChoosableItems
    const [choosedCustomer, setChoosedCustomer] = useState(null)
    const [choosedProject, setChoosedProject] = useState(null)
    const [choosedContactPerson, setChoosedContactPerson] = useState(null)

    const [choosedOnboarding, setChoosedOnboarding] = useState(null)
    const [choosedArbeitsbereich, setChoosedArbeitsbereich] = useState(null)
    const [choosedFlowChartData, setChoosedFlowChartData] = useState({
        connections: [],
        groups: []
    })
    const [choosedKundenListe, setChoosedKundenListe] = useState(null)

    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [newElementsList, setNewElementsList] = useState([]);


    const handleOptionChange = (event) => {
        setIsCreatingNew(event.target.value === 'Ja');
    };

    const handleAddElement = () => {
        setNewElementsList([...newElementsList, '']); // Add a new blank item
    };

    const handleElementNameChange = (index, value) => {
        const updatedList = [...newElementsList];
        updatedList[index] = value;
        setNewElementsList(updatedList);
    };

    const handleDeleteElement = (index) => {
        const updatedList = newElementsList.filter((_, i) => i !== index);
        setNewElementsList(updatedList);
    };





    //Init
    useEffect(() => {
        const fetchData = async () => {
            let results = null


            results = await Promise.all([
                loadOrganizationData("/activities", { "isTemplate": true }),
                loadOrganizationData("/workflows", { "isTemplate": true }),
                loadOrganizationData("/roles", {}),
                loadOrganizationData("/employees", {}),
                loadOrganizationData("/customers", {}),
                loadOrganizationData("/autoOnboardings", {}),
                loadOrganizationData("/cuSpaces", {}) //ToDo auswechseln
            ]);
            console.log("results", results)

            let [activities, workflows, roles, employees, customers, autoOnboardings, cuSpaces] = results;



            setArbeitsbereicheVorlagen(cuSpaces)
            setWorkflowsVorlagen(workflows)
            setActivitiesVorlagen(activities)
            setRolesVorlagen(roles)
            const transformedEmployees = employees.map(emp => ({
                value: emp._id,
                label: emp.f_name
            }));

            setEmployeesVorlagen(transformedEmployees);
            setCustomersVorlagen(customers)
            setAutoOnboardingsVorlagen(autoOnboardings)


            let decodedToken = await getDecodedJwtToken()
            setOrgaID(decodedToken.decodedToken.orgaId)
            setIsLoading(false)
        }
        fetchData()
    }, []);



    //calcNeededRoles
    useEffect(() => {
        setNeededRoles(calc_nessasry_roles(choosedFlowChartData, activitiesVorlagen, rolesVorlagen))
    }, [choosedFlowChartData]);

    const handleRoleChoose = (e, role, index) => {
        let tmpNeededRoles = JSON.parse(JSON.stringify(neededRoles));
        tmpNeededRoles[index].choose = e
        setNeededRoles(tmpNeededRoles)
    }

    useEffect(() => {
        //trigger sobald Arbeitsbereich verändert wurde
        let folderId = null
        // choosableArbeitsbereich = ClickUp Folder
        setKundenListenVorlagen([])

        if (choosedArbeitsbereich) {

            const fetchData = async () => {
                try {
                    const query = new URLSearchParams({ archived: 'false' }).toString();


                    console.log("folderID: ", folderId)
                    console.log("Authorization: ", orgaData.cuApiToken)
                    console.log("Query: ", `https://api.clickup.com/api/v2/folder/${folderId}/list?${query}`)

                    const response = await fetch(
                        `https://api.clickup.com/api/v2/folder/${folderId}/list?${query}`,
                        {
                            method: 'GET',
                            headers: {
                                Authorization: orgaData.cuApiToken
                            }
                        }
                    );

                    if (!response.ok) {
                        const errorBody = await response.text(); // oder response.json() falls die Antwort JSON ist
                        throw new Error(`HTTP-Fehler: Status ${response.status}, Body: ${errorBody}`);
                    }

                    const data = await response.json();
                    console.log("choosable data: ", data);
                    const avaibleListsDropDownArray = data.lists.map(list => {
                        return {
                            value: list.id,
                            label: list.name
                        };
                    });
                    console.log("avaibleListsDropDownArray: ", avaibleListsDropDownArray);
                    setKundenListenVorlagen(avaibleListsDropDownArray)
                } catch (error) {
                    console.error("Fehler beim Abrufen der Daten: ", error);
                }
            };


            console.log("orgaData: ", orgaData);
            if (orgaData.mode == "kunden_structure") {

                const findDict = arbeitsbereicheVorlagen.find(dict => dict._id === choosedArbeitsbereich.value);

                if (findDict) {
                    const cuSpaceID = findDict.cuSpaceID;
                    // Hier können Sie cuSpaceID verwenden, z.B.:
                    folderId = cuSpaceID;
                    // ... weiterer Code, der folderId verwendet ...
                    fetchData();
                } else {
                    console.log('Kein passendes Dictionary gefunden');
                }


                console.log("Arbeitsbereich gewählt: ", choosedArbeitsbereich);


                fetchData();
            }
        }
    }, [choosedArbeitsbereich]);

    useEffect(() => {
        async function loadCustomerData() {
            setCustomerDataGeladen(false)
            const fetchCustomerData = async (choosenCustomer) => {
                let results = await Promise.all([
                    getData('/projects', { 'customerID': choosenCustomer.value }),
                    getData('/contactPersons', { 'customerID': choosenCustomer.value })
                ]);
                let [projekte, personen] = results
                setProjekteVorlagen(projekte);
                setAnsprechpersonenVoralgen(personen);

                setCustomerDataGeladen(true)
            }
            await fetchCustomerData(choosedCustomer);
        }

        if (choosedCustomer && choosedCustomer.value) {
            loadCustomerData()
        } else {
            setCustomerDataGeladen(false)
            setChoosedContactPerson({})
            setChoosedProject({})
        }

    }, [choosedCustomer]);




    if (isLoading) {
        return (<div className='mainBox'>Loading...</div>);
    }





    let choosableProjects = []

    let choosablePersons = []
    if (customerDataGeladen) {

        console.log("projekteVorlagen", projekteVorlagen)
        choosableProjects = projekteVorlagen.map(proj => ({
            value: proj._id,
            label: proj.f_name
        }));

        console.log("AnsprechpersonenVoralgen", AnsprechpersonenVoralgen)
        choosablePersons = AnsprechpersonenVoralgen.map(per => ({
            value: per._id,
            label: per.f_name
        }));
    }

    console.log("customersVorlagen: ", customersVorlagen)
    const choosableCustomers = customersVorlagen.map(cstm => ({
        value: cstm._id,
        label: cstm.f_name
    }));

    const choosableWorkflows = workflowsVorlagen.map(workflow => ({
        value: workflow._id,
        label: workflow.name
    }));

    const choosableArbeitsbereiche = arbeitsbereicheVorlagen.map(cuSpace => ({
        value: cuSpace._id,
        label: cuSpace.name
    }));


    const combimedCustomers = isCustomerSelectOpen
        ? [{ value: "create", label: "neuen Kunden erstellen...", __isNew__: true }, ...choosableCustomers]
        : choosableCustomers;

    const combimedProjects = isProjectSelectOpen
        ? [{ value: "create", label: "neues Projekt erstellen...", __isNew__: true }, ...choosableProjects]
        : choosableProjects;

    const combimedPersons = isPersonSelectOpen
        ? [{ value: "create", label: "neue Person erstellen...", __isNew__: true }, ...choosablePersons]
        : choosablePersons;




    return (
        <div className='mainBox'>
            <div className='workFlowConfiguratorSection'>
                <div className='innerBoxPlanner'>
                    <h1>Workflow Daten</h1>
                    <div className='plannerBox'>
                        <div className='rolleBox'>
                            <span><b>Kunde: </b> für welchen Kunden soll der Workflow erstellt werden?</span>
                            <CreatableSelect
                                onMenuOpen={() => setIsCustomerSelectOpen(true)}
                                onMenuClose={() => setIsCustomerSelectOpen(false)}
                                isClearable={true}
                                value={choosedCustomer}
                                options={combimedCustomers}
                                className='rollenSelect'
                                onChange={(e) => {
                                    if (e) {
                                        if (e.__isNew__) {
                                            setModalConfig({
                                                'open': true,
                                                'category': 'customers',
                                                'objects': customersVorlagen,
                                                'setObjects': setCustomersVorlagen,
                                                'parentID': orgaID,
                                                'setObject': setChoosedCustomer
                                            });
                                            setCustomerDataGeladen(false)
                                        } else {
                                            setChoosedCustomer(e);
                                            setChoosedContactPerson({})
                                            setChoosedProject({})
                                        }
                                    } else {
                                        setChoosedCustomer({})
                                    }

                                }}
                            />
                        </div>
                        <div className='rolleBox'>
                            <span><b>Projekt: </b> für welches Projekt? </span>
                            <CreatableSelect
                                onMenuOpen={() => setIsProjectSelectOpen(true)}
                                onMenuClose={() => setIsProjectSelectOpen(false)}
                                isClearable={true}
                                value={choosedProject}
                                options={combimedProjects}
                                className='rollenSelect'
                                isDisabled={!customerDataGeladen}
                                onChange={(e) => {
                                    if (e) {
                                        if (e.__isNew__) {
                                            setModalConfig({
                                                'open': true,
                                                'category': 'projects',
                                                'objects': projekteVorlagen,
                                                'setObjects': setProjekteVorlagen,
                                                'parentID': choosedCustomer.value,
                                                'setObject': setChoosedProject
                                            });
                                        } else {
                                            setChoosedProject({ 'value': e.value, 'label': e.label })
                                        }
                                    } else {
                                        setChoosedProject({})
                                    }
                                }}
                            />
                        </div>
                        <div className='rolleBox'>
                            <span><b>Ansprechpartner:</b> Falls eine Willkommensemail oder ähnliches ausgewählt werden, wird es diese Person erhalten</span>
                            <CreatableSelect
                                onMenuOpen={() => setIsPersonSelectOpen(true)}
                                onMenuClose={() => setIsPersonSelectOpen(false)}
                                isClearable={true}
                                value={choosedContactPerson}
                                options={combimedPersons}
                                className='rollenSelect'
                                isDisabled={!customerDataGeladen}
                                onChange={(e) => {
                                    if (e) {
                                        if (e.__isNew__) {
                                            setModalConfig({
                                                'open': true,
                                                'category': 'contactpersons',
                                                'objects': AnsprechpersonenVoralgen,
                                                'setObjects': setAnsprechpersonenVoralgen,
                                                'parentID': choosedCustomer.value,
                                                'setObject': setChoosedContactPerson
                                            });
                                        } else {
                                            setChoosedContactPerson({ 'value': e.value, 'label': e.label })
                                        }
                                    } else {
                                        setChoosedContactPerson({})
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='plannerBox'>
                        <div className='rolleBox'>
                            <div><b>Name:</b> (Beispiel: 2 Recruiting Stellen // IT-Support, IT-Administrator)</div>
                            <input type='text' value={workflowName} id="workdlowName" onChange={(e) => { setWorkflowName(e.target.value) }} placeholder='Dieser Name landet in ClickUp als neuer Workflow-Ordner. Hier sollte der Kunde und das Main-Ziel des Workflows ersichtlich sein' />
                        </div>
                        <div className='rolleBox'>
                            <div><b>Startdatum:</b> Ab dem Datum beginnt die Deadline-Planung</div>
                            <input type="date" id="workflowStartDate" value={workflowStartDate} onChange={(e) => { setWorkflowStartDate(e.target.value) }} />
                        </div>
                        <div className='rolleBox'>
                            <div><b>Deadline:</b> Orientierungspunkt wird auf der Timeline gekennzeichnet</div>
                            <input type='date' id="workdlowDeadLine" min={currentDate} />
                        </div>

                    </div>
                </div>

            </div>



            <div className='workFlowConfiguratorSection' >
                <div className='innerBoxPlanner'>
                    <h1>Ablauf</h1>
                    <Select
                        options={choosableWorkflows}
                        className='rollenSelect'
                        isClearable={true}
                        placeholder='Vorlage'
                        onChange={(e) => {
                            if (e) {

                                let workflow = workflowsVorlagen.filter(wf => wf._id == e.value)
                                if (workflow.length != 0) {
                                    setChoosedFlowChartData(JSON.parse(workflow[0].json_template))
                                }

                            } else {
                                setChoosedFlowChartData(null)
                            }
                        }}
                    />

                    <div style={{ height: "750px", width: '100%', backgroundColor: 'white', marginTop: '20px' }}>
                        <FlowChart category={"workflows"} planungsWorkflowDaten={choosedFlowChartData} setPlanungsWorkflowDaten={setChoosedFlowChartData} mode={"planung"} />
                    </div>
                </div>
            </div>

            <div className='workFlowConfiguratorSection'>
                <div className='innerBoxPlanner'>
                    <h1>Rollen:</h1>
                    <p>Unbesetzte Rollen werden unter qualifizierten Mitarbeitern aufgeteilt, fest zugewiesene Rollen gehen direkt an bestimmte Personen.</p>
                    <p style={{ color: "red" }}>Wichtig! Das Verfahren erfordert das aktivierte "Rollen" Update.</p>
                    <div className='RollenBoxContainer'>
                        {neededRoles.map((role, index) =>
                            <div className='rolleBox' data-roleid={role.id}>
                                <div>{role.label}</div>
                                <Select
                                    value={role.choose}
                                    options={employeesVorlagen}
                                    isMulti={true}
                                    className='rollenSelect'
                                    onChange={(e) => {
                                        handleRoleChoose(e, role, index)
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='workFlowConfiguratorSection'>
                <div className='innerBoxPlanner'>
                    <h1>Automation:</h1>
                    <div className='AutomationContainer'>
                        {
                            (() => {
                                const autoOnboardingsFormatted = autoOnboardingsVorlagen.map(aonb => ({
                                    value: aonb._id,
                                    label: aonb.name
                                }));

                                return (
                                    <Select
                                        options={autoOnboardingsFormatted}
                                        onChange={(e) => {
                                            setChoosedOnboarding(e);
                                        }}
                                    />
                                );
                            })()
                        }
                    </div>
                    {choosedOnboarding &&
                        <div className='automationDesc'>
                            {autoOnboardingsVorlagen.find(aonb => aonb._id == choosedOnboarding.value).description}
                        </div>}
                </div>
            </div>

            <div className='workFlowConfiguratorSection'>
                <div className='innerBoxPlanner'>
                    <h1>ClickUp Ordner:</h1>
                    <div className='AutomationContainer'>
                        {
                            (() => {
                                return (
                                    <Select
                                        options={choosableArbeitsbereiche}
                                        onChange={(e) => {
                                            setChoosedArbeitsbereich(e);
                                        }}
                                    />
                                );
                            })()
                        }
                    </div>
                </div>
            </div>

            {kundenListenVorlagen.length > 0 && (
                <div className='workFlowConfiguratorSection'>
                    <div className='innerBoxPlanner'>
                        <h1>ClickUp Liste:</h1>
                        <div className='AutomationContainer'>
                            <Select
                                options={kundenListenVorlagen}
                                onChange={(e) => setChoosedKundenListe(e)}
                            />
                        </div>
                    </div>
                </div>
            )}


            {
                /*
                <div className='workFlowConfiguratorSection'>
                    <div className='innerBoxPlanner'>
                        <h1>Werden in diesem Workflow neue Kampagnen / Profile etc. erstellt?</h1>
                        <p>Diese Elemente werden dem gewähltem Projekt für das Ongoing Managament untergeordnet und tauchen in deiner Ongoing-Managament-View auf</p>
                        <div className='newElementsMainBox'>
                            <div className='radioBox'>
                                <label>
                                    <input
                                        type="radio"
                                        value="Ja"
                                        checked={isCreatingNew === true}
                                        onChange={handleOptionChange}
                                    />
                                    Ja
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="Nein"
                                        checked={isCreatingNew === false}
                                        onChange={handleOptionChange}
                                    />
                                    Nein
                                </label>
                            </div>
    
                            <div className='newElementBox'>
                                {isCreatingNew && (
                                    <div>
                                        {newElementsList.map((element, index) => (
                                            <div key={index} className="itemInteractionArea">
                                                <input
                                                    type="text"
                                                    value={element}
                                                    onChange={(e) => handleElementNameChange(index, e.target.value)}
                                                    placeholder="Elementname"
                                                />
                                                <button
                                                    className="deleteButton"
                                                    onClick={() => handleDeleteElement(index)}
                                                >
                                                    Löschen
                                                </button>
                                            </div>
                                        ))}
                                        <button onClick={handleAddElement}>Neues Element</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    
    
                    </div>
                </div>
                */
            }

            <div className='workFlowConfiguratorSection'>
                <div className='workflowStartBtn' onClick={() => { startWorkflow() }}>Workflow Starten</div>
            </div>
            <CustomModal modalConfig={modalConfig} setModalConfig={setModalConfig} />
        </div>
    );

    // Verwenden Sie diese Funktion, bevor Sie den Workflow starten
    async function startWorkflow() {

        let bereit = areAllFieldsFilled()
        if (bereit.success) {

            const workflowDeadLine = document.getElementById("workdlowDeadLine").value;

            let cuSpace = arbeitsbereicheVorlagen.find(aonb => aonb._id == choosedArbeitsbereich.value).cuSpaceID

            const extractRelevantData = (data) => {
                const processedData = { ...data }; // Eine Kopie des ursprünglichen Datenobjekts erstellen.

                processedData.groups = processedData.groups.map(group => {
                    // Extrahiere nur "id", "label" und "connections" von jeder Gruppe.
                    const { id, label, connections, activities } = group;
                    return { id, label, connections, activities };
                });

                processedData.groups.forEach(group => {
                    group.activities = group.activities.map(activity => {
                        // Extrahiere nur "id", "label" und "tmp_id" von jeder Aktivität.
                        const { id, label, tmp_id } = activity;
                        return { id, label, tmp_id };
                    });
                });

                return processedData;
            }



            let workflowPayload = {
                projectID: choosedProject,
                person: choosedContactPerson,
                workflowOnboarding: choosedOnboarding,
                workspaceID: cuSpace,
                name: workflowName.trim(),
                startDate: workflowStartDate,
                json_planed: JSON.stringify(extractRelevantData(choosedFlowChartData)),
                json_roles: JSON.stringify(neededRoles),
                json_newItems: newElementsList
            }

            if (workflowDeadLine) {
                workflowPayload['deadline'] = workflowDeadLine
            }

            if (orgaData.mode == "kunden_structure") {

                workflowPayload['kundenListe'] = choosedKundenListe;

            }


            const response = await fetch("https://hook.eu2.make.com/qwyar0c11d3v5l55v9124pjnknqmcyor", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(workflowPayload)
            });

            console.log("workflowAns: ", response)

            showNotification("Workflow wird hochgeladen", "good")

            window.location.reload();

        } else {
            showNotification(bereit.msg, "error")
        }
    }

    function areAllFieldsFilled() {
        // Überprüfen der Werte in Ihrem Status für CreatableSelect
        const isEmpty = (value) =>
            value === null ||
            value === undefined ||
            value === "" ||
            (typeof value === "object" && Object.keys(value).length === 0) ||
            (typeof value === "string" && value.trim().length === 0);

        if (isEmpty(choosedCustomer) || isEmpty(choosedProject) || isEmpty(choosedContactPerson)) {
            return { 'success': false, 'msg': 'WorkflowDaten nicht vollständig' };
        }


        if (!workflowName || !workflowName.trim() || !workflowStartDate || !workflowStartDate.trim()) {
            return { 'success': false, 'msg': 'WorkflowDaten nicht vollständig' };
        }

        if (choosedFlowChartData.groups.length == 0) {
            return { 'success': false, 'msg': "Hinterlege einen Ablauf" };
        }


        if (orgaData.mode == "legecy") {
            for (let neededRole of neededRoles) {
                console.log("Überprüfe: ", neededRole);
                if (neededRole.choose.length === 0) {
                    return { 'success': false, 'msg': "Du hast keinen Zugriff auf das Rollen Update, bitte hinterlege alle Rollen mit Verantwortlichen Personen" };
                }
            }

        }



        if (!choosedOnboarding) {
            return { 'success': false, 'msg': "Wähle die Automations aus" };
        }
        if (!choosedArbeitsbereich) {
            return { 'success': false, 'msg': "Wähle einen Arbeitsbereich aus" };
        }

        for (let element of newElementsList) {
            if (!element.trim()) {
                return { 'success': false, 'msg': 'Mindestens ein Element ist leer' };
            }
        }

        // Wenn Sie bis hierher gekommen sind, waren alle Überprüfungen erfolgreich
        return { 'success': true };
    }

}

export default WorkflowPlanner;








function calc_nessasry_roles(workflowData, activitiesVorlagen, alleRollenImUnternehmen) {
    let nessasry_roles = []

    let rollenEinzigartig = []

    if (!workflowData) {
        return []
    }

    workflowData.groups.forEach(group => {
        group.activities.forEach(act => {
            let TmpObj = activitiesVorlagen.filter(tmpActivity => tmpActivity._id == act.tmp_id);

            if (TmpObj.length != 0) {
                if (TmpObj[0].roles) {
                    TmpObj[0].roles.forEach(role => {
                        //console.log("Tmp role: ", role, " checking in: ", rollenEinzigartig)
                        if (!rollenEinzigartig.includes(role.row_id)) {
                            console.log("Push")
                            rollenEinzigartig.push(role.row_id)
                        }
                    });
                } else {
                    console.error("keine Rollen im Template: ", TmpObj[0])
                }

            } else {
                console.error("Kein TmpObj gefunden: ", act.tmp_id)
            }

        });
    });

    console.log("alleRollenImUnternehmen: ", alleRollenImUnternehmen)

    rollenEinzigartig.forEach(notwendigeRolle => {
        alleRollenImUnternehmen.forEach(vorhandeneRolle => {
            //console.log("vorhandeneRolle: ", vorhandeneRolle)
            if (vorhandeneRolle._id == notwendigeRolle) {
                nessasry_roles.push({ 'label': vorhandeneRolle.f_name, 'value': vorhandeneRolle._id, 'choose': [] })
            }
        });
    });

    return nessasry_roles;
}