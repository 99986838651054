import React, { useEffect, useState } from 'react';
import { getDecodedJwtToken, useGetData, useGetOrgaData, usePostData } from '../functions/api';
import { useNotification } from '../contextProviders/NotificationContext.js';
import './site_workflowprogress.css';

function WorkflowsProgress() {
    const { getData } = useGetData()
    const { loadOrganizationData } = useGetOrgaData();

    const [isLoading, setIsLoading] = useState(true);
    const [workflows, setWorkflows] = useState([]);
    const [aufgabenGruppen, setAufgabenGruppen] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)

            const [tmpWorkflows, tmpGruppen] = await Promise.all([
                loadOrganizationData("/workflows", {}),
                loadOrganizationData("/activityGroups", { "target": "progress" })
            ]);

            console.log("tmpWorkflows: ", tmpWorkflows)
            console.log("tmpGruppen: ", tmpGruppen)


            const workflows = tmpWorkflows.filter(wf => wf.f_status === "active" || wf.f_status === "planned");
            setWorkflows(workflows);

            setAufgabenGruppen(tmpGruppen);

            setIsLoading(false);
        }

        fetchData()
    }, []);

    const calcColorStatus = (status) => {
        switch (status) {
            case "done":
                return "green";
            case "active":
                return "orange";
            case "planned":
                return "#e0e0e0"; // Default/Planned status
            default:
                return "#e0e0e0";
        }
    }


    if (isLoading) {
        return (
            <p>Laden...</p>
        )
    }

    const calculateStatusPercentage = (workflowId, status) => {
        const totalGroups = aufgabenGruppen.filter(group => group.workflow[0].row_id === workflowId).length;
        const statusGroups = aufgabenGruppen.filter(group => group.workflow[0].row_id === workflowId && group.f_status === status).length;

        return (statusGroups / totalGroups) * 100; // Hier wird nicht abgerundet.
    }



    return (
        <div className='mainBox mainBoxProgress'>
            <h1>Aktive Arbeit</h1>
            <div className="workflow-progress-container">
                {workflows.length > 0 ? (

                    workflows.map((workflow) => (
                        <div key={workflow._id} className="workflow-card">
                            <div className='workflow-header'>
                                <h2>{workflow.name}</h2>
                                <div className='progress-barBox'>
                                    Fortschritt:
                                    <div className="progress-bar">
                                        <div className={`progress done`} style={{ width: `${calculateStatusPercentage(workflow._id, 'done')}%` }}>
                                            {calculateStatusPercentage(workflow._id, 'done') > 0 ? Math.round(calculateStatusPercentage(workflow._id, 'done')) + " %" : ''} {/* Hier wird abgerundet. */}
                                        </div>
                                        <div className={`progress active`} style={{ width: `${calculateStatusPercentage(workflow._id, 'active')}%` }}>
                                            {calculateStatusPercentage(workflow._id, 'active') > 0 ? Math.round(calculateStatusPercentage(workflow._id, 'active')) + " %" : ''} {/* Hier wird abgerundet. */}
                                        </div>
                                        <div className={`progress planned`} style={{ width: `${calculateStatusPercentage(workflow._id, 'planned')}%` }}>
                                            {calculateStatusPercentage(workflow._id, 'planned') > 0 ? Math.round(calculateStatusPercentage(workflow._id, 'planned')) + " %" : ''} {/* Hier wird abgerundet. */}
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="groups-container">
                                {aufgabenGruppen.filter(group => group.workflow[0].row_id === workflow._id).map(group => (
                                    <div key={group._id} className={`group-item ${group.f_status.toLowerCase()}-status`}>
                                        <div className='statusBox' style={{ backgroundColor: calcColorStatus(group.f_status.toLowerCase()) }} />
                                        <span>{group.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))

                ) : (<p>Keine Aktiven Workflows</p>)}
            </div>
        </div>

    );
}

export default WorkflowsProgress;
