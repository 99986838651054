
import React from 'react';
import './shareItems.css'
import EditData from '../editData/editData';

import { useParams, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';


function ShareItems({ orgaID, customerID, type }) {

    console.log("type: ", type)

    const possibleCategories = [{
        "name": "passwords",
        "displayName": "🧑 Passwörter"
    },
    {
        "name": "linktree",
        "displayName": "🧑 Verlinkungen"
    },
    {
        "name": "contactpersons",
        "displayName": "🧑 Personen"
    }, {
        "name": "touchpoints",
        "displayName": "🧑 Touchpoints"
    }]

    let categorys = null;
    if (type == "kundenkarte") {
        categorys = possibleCategories
    } else {
        let index = possibleCategories.findIndex(c => c.name == type)
        categorys = [possibleCategories[index]]
    }

    if (categorys != null) {
        return (
            <div className='mainBox'>
                <EditData
                    categorys={categorys}
                    orgaID={orgaID}
                    customerID={customerID}
                    mode='extern'
                />
            </div>
        );
    } else {
        return (
            <div className='mainBox'>
                <p>Falscher Link...</p>
            </div>
        );
    }

}

export default ShareItems;