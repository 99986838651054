import { useEffect, useState } from 'react';
import Select from 'react-select';


function RenderDataCell(data, spalte, onChange, newLine, editedValues, editingId, zustatzDaten, ShowName) {

    let relevantProps = null;

    if (editingId == data._id && !newLine) {
        relevantProps = {
            value: (editedValues[spalte.name] !== undefined && editedValues[spalte.name] !== null) ? editedValues[spalte.name] : data[spalte.name],
            id: data._id,
            name: spalte.name,
            onChange: onChange,
            type: spalte.type
        };
    } else {
        relevantProps = {
            value: data[spalte.name],
            id: data._id,
            name: spalte.name,
            onChange: onChange,
            type: spalte.type
        };
    }



    let daten = null
    let inputHTML = null;
    let enableEdit = true

    if (editingId === data._id || newLine) {
        enableEdit = false
    }


    switch (spalte.type) {
        case 'text':
            inputHTML = <TextInput {...relevantProps} disabled={enableEdit} />;
            break;
        case 'checkbox':

            console.log("Checkbox Input: ", relevantProps)

            inputHTML = <CheckboxInput {...relevantProps} disabled={enableEdit} />;
            break;
        case 'number':
            inputHTML = <NumberInput {...relevantProps} disabled={enableEdit} />;
            break;
        case 'singleSelect':
            //console.log("relevantProps.value: ", relevantProps.value)
            //console.log("spalte: ", spalte)

            // Check if the value is not an object
            if (typeof relevantProps.value !== "object") {
                // Find the corresponding label-value pair from the chooses array
                let correspondingChoice = spalte.chooses.find(choose => choose.value === relevantProps.value);

                if (correspondingChoice) {
                    // If found, assign it
                    relevantProps.value = {
                        value: correspondingChoice.value,
                        label: correspondingChoice.label
                    };
                } else {
                    // If not found, use the value as both label and value
                    relevantProps.value = {
                        'value': relevantProps.value,
                        'label': relevantProps.value
                    };
                }
            }


            inputHTML = <SelectInput {...relevantProps} options={spalte['chooses']} isMulti={false} disabled={enableEdit} />;
            break;


        case 'singleLink':
        case 'multiLink':


            const transformedValues = transformValue(relevantProps.value);

            //console.log("transformedValues: ", transformedValues)

            daten = zustatzDaten[spalte.linkTo.tbl]?.map(choose => ({
                label: choose.f_name || choose.display_value,
                value: choose._id
            }));

            inputHTML = <SelectInput {...relevantProps} options={daten} isMulti={spalte.type === 'multiLink'} value={transformedValues} disabled={enableEdit} />;
            break;

        case 'duration':
            inputHTML = <DurationInput {...relevantProps} disabled={enableEdit} />;
            break;
        case 'longText':
            inputHTML = <LongTextInput {...relevantProps} disabled={enableEdit} />
            break;
        case 'date':
            inputHTML = <DateInput {...relevantProps} disabled={enableEdit} />
            break;
        default:
            inputHTML = data[spalte.name];
            break;
    }
    if (!ShowName) {
        return <div className={"EditDataZelle breite-" + spalte.breite}> {inputHTML} </div>
    } else {
        return <div>
            <div className={"EditDataZelle breite-" + spalte.breite}> {spalte.displayName} </div>
            <div className={"EditDataZelle breite-" + spalte.breite}> {inputHTML} </div>
        </div>

    }




    function transformValue(valueArray) {
        if (Array.isArray(valueArray)) {
            return valueArray.map(item => {
                if (item?.display_value && item?.row_id) {
                    return {
                        label: item.display_value,
                        value: item.row_id
                    };
                } else if (item?.label && item?.value) {
                    return item;
                } else {
                    // Sie sollten hier eine Rückfall-Logik oder einen Fehlerwurf hinzufügen
                    return null; // oder einen geeigneten Standardwert
                }
            });
        } else {
            return valueArray
        }

    }
}

function msToTime(duration) {
    let minutes = Math.floor(duration / 360);
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    return `${hours}:${minutes.toString().padStart(2, '0')}`;
}


function DurationInput({ value, onChange, id, name, disabled, type }) {

    // Convert the initial value (in seconds) to hours and minutes
    const initialHours = Math.floor(value / 3600);
    const initialMinutes = (value % 3600) / 60;

    const [hours, setHours] = useState(initialHours);
    const [minutes, setMinutes] = useState(initialMinutes);

    const handleHoursChange = (e) => {
        setHours(e.target.value);
    };

    const handleMinutesChange = (e) => {
        setMinutes(e.target.value);
    };

    useEffect(() => {
        // Convert the hours and minutes back to seconds
        const totalSeconds = (hours * 3600) + (minutes * 60);
        // Call the onChange function with the new value in seconds
        onChange(id, name, totalSeconds, type);
    }, [hours, minutes]);

    return (
        <div className='durationBox'>
            <div className='durationBoxBox'>
                <input
                    type="number"
                    placeholder="Stunden"
                    value={hours}
                    onChange={handleHoursChange}
                    disabled={disabled}
                />h
            </div>
            <div className='durationBoxBox'>
                <input
                    type="number"
                    placeholder="Minuten"
                    value={minutes}
                    onChange={handleMinutesChange}
                    disabled={disabled}
                />m
            </div>
        </div>
    );
}

function TextInput({ value, onChange, id, name, disabled, type }) {
    // Eine einfache Funktion, um zu prüfen, ob der Wert ein gültiger Link ist
    const isValidHttpUrl = (string) => {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    };

    // Wenn das Feld deaktiviert ist und der Wert ein Link ist, zeige einen anklickbaren Link an
    if (disabled && isValidHttpUrl(value)) {
        return <a href={value} target="_blank" rel="noopener noreferrer" style={{ display: 'block', margin: '10px 0' }}>{value}</a>;
    }

    // Ansonsten zeige das normale Textfeld
    return (
        <input
            type="text"
            style={{ height: '40px' }}
            value={value}
            onChange={(e) => onChange(id, name, e.target.value, type)}
            disabled={disabled}
        />
    );
}

function CheckboxInput({ value, onChange, id, name, disabled, type }) {

    return (
        <input
            type="checkbox"
            style={{ height: '40px' }}
            checked={value}
            onChange={(e) => onChange(id, name, e.target.checked, type)}
            disabled={disabled}
        />
    );
}

function NumberInput({ value, onChange, id, name, disabled, type }) {

    return (
        <input
            type="number"
            style={{ height: '40px' }}
            value={value}
            onChange={(e) => onChange(id, name, e.target.value, type)}
            disabled={disabled}
        />
    );
}

function DateInput({ value, onChange, id, name, disabled, type }) {

    // Convert the provided value to a Date object
    const dateObj = new Date(value);

    // Extract the year, month, and day in YYYY-MM-DD format
    const formattedValue = `${dateObj.getUTCFullYear()}-${String(dateObj.getUTCMonth() + 1).padStart(2, '0')}-${String(dateObj.getUTCDate()).padStart(2, '0')}`;


    return (
        <input
            style={{ height: '40px' }}
            type="date"
            value={formattedValue}
            onChange={(e) => onChange(id, name, e.target.value, type)}
            disabled={disabled}
        />
    );
}




function SelectInput({ value, isMulti, options, onChange, id, name, disabled, style, type }) {
    return (
        <Select
            style={style || { width: "100%", color: "black" }}
            isMulti={isMulti}
            value={value}
            name={name}
            options={options}
            className="selectTableEdit"
            classNamePrefix="select"
            onChange={(selectedOption) => onChange(id, name, selectedOption, type)}
            isDisabled={disabled}
        />
    );
}

function LongTextInput({ value, onChange, id, name, disabled, type }) {
    return (
        <textarea
            className='InputTextarea'
            value={value}
            onChange={(e) => onChange(id, name, e.target.value, type)}
            disabled={disabled}
        ></textarea>
    );
}

export { RenderDataCell }