



import React from 'react';
import EditData from '../components/editData/editData';

function OnbAutomations() {
    const possibleCategorys = [{
        "name": "autoOnboardings",
        "displayName": "🤖 Onboarding Automations"
    }]

    return (

        <EditData categorys={possibleCategorys} />

    );
}

export default OnbAutomations;