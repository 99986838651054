import React, { useState, useEffect } from "react";
import "./../style/login.css"
import { useGetData } from '../functions/api';
import { useNotification } from '../contextProviders/NotificationContext.js';
import {
    useNavigate
} from "react-router-dom";

function LogIn() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { getData } = useGetData()
    const { showNotification } = useNotification();
    const navigate = useNavigate();

    const hasAccessTo = (module, orgaData) => {
        switch (module) {
            case "agentur_cockpit_modul":
                return orgaData?.agentur_cockpit_modul;
            case "chatbot_modul":
                return orgaData?.chatbot_modul;
            default:
                return false;
        }
    }

    const handleLogout = () => {
        // JWT-Token aus dem localStorage entfernen
        localStorage.removeItem('jwtToken');

        navigate('/login');
    }

    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        const orgaData = JSON.parse(localStorage.getItem('cookieOrgaData'));

        if (token) {
            if (hasAccessTo("agentur_cockpit_modul", orgaData)) {
                navigate('/overview');
            } else if (hasAccessTo("chatbot_modul", orgaData)) {
                navigate('/chatbot');
            } else {
                handleLogout();
            }

        }
    }, [navigate]);



    const handleLogin = async () => {
        if (username && password) {

            let res = await getData("/login", { "email": username, "password": password })

            if (res) {
                console.log("Res Ans: ", res)

                let tokenTest = res['token']
                localStorage.setItem('jwtToken', tokenTest);
                const orgaData = {
                    agentur_cockpit_modul: res['orgaData']['data']['agentur_cockpit_modul'],
                    chatbot_modul: res['orgaData']['data']['chatbot_modul'],
                    cuApiToken: res['orgaData']['data']['cuApiToken'],
                    name: res['orgaData']['data']['name'],
                    mode: res['orgaData']['data']['mode']
                }

                console.log("Speichere Daten: ", orgaData)
                localStorage.setItem('cookieOrgaData', JSON.stringify(orgaData))
                const userData = {
                    firstname: res['userData']['firstname'],
                    lastname: res['userData']['lastname'],
                    role3qc: res['userData']['role3qc']
                };
                localStorage.setItem('cookieUserData', JSON.stringify(userData));


                if (hasAccessTo("agentur_cockpit_modul", orgaData)) {
                    navigate('/overview');
                } else if (hasAccessTo("chatbot_modul", orgaData)) {
                    navigate('/chatbot');
                } else {
                    handleLogout();
                }
            }
            else {
                showNotification("Falsche Daten", "error")
            }

        } else {
            alert('Bitte füllen Sie beide Felder aus!');
        }
    };

    return (
        <div className="login-container">
            <h1 className="login-header">3QC</h1>
            <div className="login-form-container">
                <h2 className="login-title">Login</h2>
                <input
                    type="text"
                    placeholder="Username"
                    className="login-input"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    className="login-input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin} className="login-button">Login</button>
            </div>
        </div>
    );
}

export default LogIn;