import { findPossibleConnections } from "../components/flowchart/flowchartHandlers";

export function deleteMsgHandler(clickedID, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData) {
    let tmpMasterData = JSON.parse(JSON.stringify(masterData));

    // Filtere Gruppen, um die Gruppe mit der spezifischen ID zu entfernen
    tmpMasterData.nachrichten = tmpMasterData.nachrichten.filter(msg => msg.id !== clickedID);

    // Filtere Verbindungen, um diejenigen zu entfernen, die entweder 'from' oder 'to' mit der ID haben
    tmpMasterData.connections = tmpMasterData.connections.filter(conn => conn.from !== clickedID && conn.to !== clickedID);

    setMasterData(tmpMasterData);
    setSelectedItem(null)
    return true
}

export function resetExtraClass(tmpMasterData) {
    tmpMasterData.nachrichten.forEach(nachricht => {
        nachricht['extraClass'] = '';
    });
}


export async function connectMsgHandler(clickedID, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData) {
    let tmpMasterData = JSON.parse(JSON.stringify(masterData));
    const index = masterData.nachrichten.findIndex(msg => msg.id == clickedID);

    if (!selectedItem) {
        let possibleConnections = findPossibleConnections(tmpMasterData, index, "msg")

        console.log("possibleConnections: ", possibleConnections)

        tmpMasterData.nachrichten.forEach(msg => {
            if (possibleConnections.includes(msg.id)) {
                msg['extraClass'] = 'targetNachricht';
            }

            if (msg.id == clickedID) {
                msg['extraClass'] = 'sourceNachricht';
            }
        });

        setSelectedItem({ "id": clickedID, "type": btnType, "possibleIDs": possibleConnections })
    }
    else if (selectedItem) {
        console.log("AddConeection")
        tmpMasterData.connections.push({ 'from': selectedItem.id, 'to': clickedID, filter: [] })
        resetExtraClass(tmpMasterData)
        setSelectedItem(null)
    }

    setMasterData(tmpMasterData)
    return true
}


export async function disconnectMsgHandler(clickedID, btnType, klasse, setSelectedItem, selectedItem, masterData, setMasterData) {
    let tmpMasterData = JSON.parse(JSON.stringify(masterData));
    const index = masterData.nachrichten.findIndex(msg => msg.id == clickedID);

    if (!selectedItem) {

        tmpMasterData.nachrichten.forEach(msg => {
            msg.afters = [];
            msg.befores = [];
        });

        tmpMasterData.connections.forEach(connection => {
            tmpMasterData.nachrichten.forEach(msg => {

                if (msg.id === connection.from) {

                    msg.afters.push(connection.to);
                }

                if (msg.id === connection.to) {

                    msg.befores.push(connection.from);
                }
            });
        });


        console.log(tmpMasterData, index)

        let afters = tmpMasterData.nachrichten[index].afters;

        afters.forEach(after => {
            const activity = tmpMasterData.nachrichten.find(msg => msg.id === after);
            if (activity) activity['extraClass'] = 'targetUnplugMessage';
        });

        tmpMasterData.nachrichten[index]['extraClass'] = 'sourceUnplugMessage';

        setSelectedItem({ "id": clickedID, "type": btnType, "possibleIDs": afters })
    }
    else {

        tmpMasterData.connections?.filterInPlace(conn => !(conn.from === selectedItem.id && conn.to === clickedID));
        tmpMasterData.nachrichten.forEach(msg => {
            msg.afters = [];
            msg.befores = [];
        });

        tmpMasterData.connections.forEach(connection => {
            tmpMasterData.nachrichten.forEach(msg => {

                if (msg.id === connection.from) {

                    msg.afters.push(connection.to);
                }

                if (msg.id === connection.to) {

                    msg.befores.push(connection.from);
                }
            });
        });
        resetExtraClass(tmpMasterData)
        setSelectedItem(null)
    }

    setMasterData(tmpMasterData)
    return true
}


export async function calcPathPlaceMessages(tmpMasterData) {

    // Schritt 1: Bestimme "afters" und "befores" zwischen den Gruppen
    tmpMasterData.connections.forEach(connection => {
        tmpMasterData.nachrichten.forEach(msg => {
            if (!msg.afters) {
                msg.afters = [];
            }
            if (msg.id === connection.from) {
                msg.afters.push(connection.to);
            }

            if (!msg.befores) {
                msg.befores = [];
            }
            if (msg.id === connection.to) {
                msg.befores.push(connection.from);
            }

        });
    });



    //Calc Pathplaces for each Object
    const firstNachrichten = tmpMasterData.nachrichten.filter(msg => !msg.befores.length);
    await firstNachrichten.forEach(msg => {
        msg.data.path_place = 1;
        addPathPlacesToAfterElements(msg, tmpMasterData.nachrichten);
    });


}

function addPathPlacesToAfterElements(element, array) {
    element.afters.forEach(afterElementId => {
        const foundElement = array.find(e => e.id === afterElementId);
        if (foundElement && foundElement.data.path_place <= element.data.path_place) {
            foundElement.data.path_place = element.data.path_place + 1;
        }
        addPathPlacesToAfterElements(foundElement, array);
    });
}

function getMaxPathPlace(data) {
    return Math.max(...data.map(node => node.data.path_place));
}

function getNodesByPathPlace(data, pathPlace) {
    return data.filter(node => node.data.path_place === pathPlace);
}


export function calcMessagePositions(data, config, start_point) {
    console.log("Before calculating positions:", JSON.parse(JSON.stringify(data)));

    for (let pathPlace = 1; pathPlace <= getMaxPathPlace(data); pathPlace++) {
        let nodes = getNodesByPathPlace(data, pathPlace);

        if (pathPlace > 1) {
            nodes = nodes.sort((a, b) => {
                const aMinX = Math.min(...a.befores.map(beforeId => {
                    const pred = data.find(node => node.id === beforeId);
                    return pred.position.x;
                }));
                const bMinX = Math.min(...b.befores.map(beforeId => {
                    const pred = data.find(node => node.id === beforeId);
                    return pred.position.x;
                }));
                return aMinX - bMinX;
            });
        }

        let startX = start_point.x;

        if (pathPlace > 1) {
            const predecessors = nodes.flatMap(node => node.befores.map(beforeId => data.find(n => n.id === beforeId)));
            const minX = Math.min(...predecessors.map(pred => pred.position.x));
            const maxX = Math.max(...predecessors.map(pred => pred.position.x));
            startX = (minX + maxX) / 2 - ((nodes.length - 1) * (config.width + config.marginX)) / 2;
        }

        nodes.forEach((node, idx) => {
            node.position = {
                y: start_point.y + (pathPlace - 1) * (config.height + config.marginY), // Y-Position basierend auf der Schicht
                x: startX + idx * (config.width + config.marginX) // X-Position basierend auf der Indexposition in der sortierten Knotenliste
            };
        });
    }

    console.log("After calculating positions:", data);

    let minX = Infinity;
    data.forEach(node => {
        minX = Math.min(minX, node.position.x);
    });

    if (minX < start_point.x) {
        let diffX = start_point.x - minX;
        data.forEach(node => {
            node.position.x = node.position.x + diffX;
        });
    }
}
