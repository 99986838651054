export function calcPathPlaces(tmpMasterData) {
    tmpMasterData.groups.forEach(group => {
        group.activities.forEach(activity => {
            activity.path_place = 1;
            activity.afters = [];
            activity.befores = [];
        });
        group.path_place = 1;
        group.afters = [];
        group.befores = [];
    });


    // Schritt 1: Bestimme "afters" und "befores" zwischen den Gruppen
    tmpMasterData.connections.forEach(connection => {
        tmpMasterData.groups.forEach(group => {
            if (group.id === connection.from) {
                group.afters.push(connection.to);
            }
            if (group.id === connection.to) {
                group.befores.push(connection.from);
            }
        });
    });

    // Schritt 2: Bestimme "afters" und "befores" zwischen den Aktivitäten in jeder Gruppe
    tmpMasterData.groups.forEach(group => {
        group.connections.forEach(connection => {

            group.activities.forEach(activity => {
                if (activity.id === connection.from) {
                    activity.afters.push(connection.to);
                }
                if (activity.id === connection.to) {
                    activity.befores.push(connection.from);
                }
            });

        });
    });




    //Calc Pathplaces for each Object
    const firstGroups = tmpMasterData.groups.filter(group => !group.befores.length);
    firstGroups.forEach(group => {
        group.path_place = 1;
        addPathPlacesToAfterElements(group, tmpMasterData.groups);
    });

    let firstActivities = []
    tmpMasterData.groups.forEach(group => {
        firstActivities = group.activities.filter(activity => !activity.befores.length);

        firstActivities.forEach(activity => {
            activity.path_place = 1;
            addPathPlacesToAfterElements(activity, group.activities);
        });
    });

    function addPathPlacesToAfterElements(element, array) {
        element.afters.forEach(afterElementId => {
            const foundElement = array.find(e => e.id === afterElementId);
            if (foundElement && foundElement.path_place <= element.path_place) {
                foundElement.path_place = element.path_place + 1;
            }
            addPathPlacesToAfterElements(foundElement, array);
        });
    }
}


// Definiere die Funktion calculateNodePositions, die die Positionen der Knoten (nodes) in einem Diagramm berechnet
export function calculateNodePositions(data, config, start_point) {
    // Logge die Eingangsdaten für Debugging-Zwecke
    //console.log("Before calculating positions:", JSON.parse(JSON.stringify(data)));

    // Iteriere über jede "Schicht" oder "Pfad-Position" im Diagramm
    for (let pathPlace = 1; pathPlace <= getMaxPathPlace(data); pathPlace++) {
        // Hole alle Knoten in der aktuellen pathPlace-Position
        let nodes = getNodesByPathPlace(data, pathPlace);

        // Wenn wir uns nicht in der ersten Schicht befinden, sortiere die Knoten basierend auf der Y-Position ihrer Vorgänger
        if (pathPlace > 1) {
            nodes = nodes.sort((a, b) => {
                // Berechne den minimalen Y-Wert der Vorgänger von a
                const aMinY = Math.min(...a.befores.map(beforeId => {
                    const pred = data.find(node => node.id === beforeId);
                    return pred.position.y;
                }));
                // Berechne den minimalen Y-Wert der Vorgänger von b
                const bMinY = Math.min(...b.befores.map(beforeId => {
                    const pred = data.find(node => node.id === beforeId);
                    return pred.position.y;
                }));
                // Vergleiche die minimalen Y-Werte von a und b
                return aMinY - bMinY;
            });
        }

        // Setze den Startwert von Y auf den übergebenen Startpunkt
        let startY = start_point.y;

        // Wenn wir uns nicht in der ersten Schicht befinden, justiere startY basierend auf den Vorgängern
        if (pathPlace > 1) {
            // Finde alle Vorgänger der aktuellen Knoten
            const predecessors = nodes.flatMap(node => node.befores.map(beforeId => data.find(n => n.id === beforeId)));
            // Berechne den minimalen und maximalen Y-Wert der Vorgänger
            const minY = Math.min(...predecessors.map(pred => pred.position.y));
            const maxY = Math.max(...predecessors.map(pred => pred.position.y));
            // Berechne startY so, dass die Knoten um den mittleren Y-Wert ihrer Vorgänger zentriert sind
            startY = (minY + maxY) / 2 - ((nodes.length - 1) * (config.height + config.marginY)) / 2;
        }

        // Berechne die Position jedes Knoten in der aktuellen Schicht
        nodes.forEach((node, idx) => {
            node.position = {
                x: start_point.x + (pathPlace - 1) * (config.width + config.marginX), // X-Position basierend auf der Schicht
                y: startY + idx * (config.height + config.marginY) // Y-Position basierend auf der Indexposition in der sortierten Knotenliste
            };
        });
    }

    // Logge die Daten nach der Berechnung der Positionen für Debugging-Zwecke
    //console.log("After calculating positions:", data);

    // Finde den minimalen Y-Wert aller Knoten
    let minY = Infinity;
    data.forEach(node => {
        minY = Math.min(minY, node.position.y);
    });

    // Wenn der minimale Y-Wert kleiner als der Startpunkt ist, justiere alle Y-Positionen nach oben
    if (minY < start_point.y) {
        let diffY = start_point.y - minY;
        data.forEach(node => {
            node.position.y = node.position.y + diffY;
        });
    }
}
function getMaxPathPlace(data) {
    return Math.max(...data.map(node => node.path_place));
}

function getNodesByPathPlace(data, pathPlace) {
    return data.filter(node => node.path_place === pathPlace);
}



//übersetzte den Dict in FlowChart Data
export function createNotes(activityArray, tmpActivities) {
    let nodesInAGroup = []

    activityArray.forEach(activity => {
        let TmpObj = tmpActivities.filter(tmpActivity => tmpActivity._id == activity.tmp_id);
        //console.log("TmpObj: ", TmpObj)

        if (TmpObj.length == 1) {
            TmpObj = TmpObj[0]
            nodesInAGroup.push({
                position: activity.position,
                data: {
                    "label": TmpObj.name,
                    'id': activity.id,
                    "rollen": TmpObj.roles,
                    "desc": TmpObj.description,
                    "time": TmpObj.shouldDuration,
                    "days": TmpObj.shouldDays,
                    'extraClass': activity.extraClass
                },
                id: activity.id,
                type: 'activity',
            })
        } else {
            nodesInAGroup.push({
                position: activity.position,
                data: {
                    "label": "Invalid Template",
                    'id': activity.id,
                    "rollen": [],
                    "desc": "Invalid Template",
                    "time": 0,
                    "days": 0,
                    'extraClass': activity.extraClass
                },
                id: activity.id,
                type: 'activity',
            })
        }
    });
    return nodesInAGroup;
}

export function generateRandomToken(length = 12) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }
    return result;
}

export function createEdges(connectionArray) {
    let tmpEdges = []

    connectionArray.forEach(connection => {
        tmpEdges.push({
            id: "con_" + generateRandomToken(),
            target: connection.to,
            source: connection.from
        })
    });

    return tmpEdges
}


/*

EXPERIMENT

*/
export function calculateGroupPositions(groups, start_point) {
    const GROUP_MARGIN_X = 150;
    const GROUP_MARGIN_Y = 50;

    // Iteriere über jede "Schicht" oder "Pfad-Position" im Diagramm
    for (let pathPlace = 1; pathPlace <= getMaxPathPlace(groups); pathPlace++) {
        // Hole alle Knoten in der aktuellen pathPlace-Position
        let nodes = getNodesByPathPlace(groups, pathPlace);

        // Wenn wir uns nicht in der ersten Schicht befinden, sortiere die Knoten basierend auf der Y-Position ihrer Vorgänger
        if (pathPlace > 1) {
            nodes = nodes.sort((a, b) => {
                // Berechne den minimalen Y-Wert der Vorgänger von a
                const aMinY = Math.min(...a.befores.map(beforeId => {
                    const pred = groups.find(node => node.id === beforeId);
                    return pred.position.y;
                }));
                // Berechne den minimalen Y-Wert der Vorgänger von b
                const bMinY = Math.min(...b.befores.map(beforeId => {
                    const pred = groups.find(node => node.id === beforeId);
                    return pred.position.y;
                }));
                // Vergleiche die minimalen Y-Werte von a und b
                return aMinY - bMinY;
            });
        }

        // Setze den Startwert von Y auf den übergebenen Startpunkt
        let startY = null
        let breiteVorherigerPath = 0;
        if (pathPlace > 1) {
            breiteVorherigerPath = findBreiteVorherigerPath(pathPlace - 1, groups)
        }
        // Wenn wir uns nicht in der ersten Schicht befinden, justiere startY basierend auf den Vorgängern
        if (pathPlace > 1) {
            // Finde alle Vorgänger der aktuellen Knoten
            const predecessors = nodes.flatMap(node => node.befores.map(beforeId => groups.find(n => n.id === beforeId)));
            const predecessorsPath = getNodesByPathPlace(groups, pathPlace - 1);
            // Berechne den minimalen und maximalen Y-Wert der Vorgänger
            const minY = Math.min(...predecessors.map(pred => pred.position.y));
            const maxY = Math.max(...predecessors.map(pred => pred.position.y));

            let bisherigeHoheTmp = 0
            let bisherigeHohePressecores = 0
            let bisherigeHohePressecoresNor = 0
            nodes.forEach(node => {
                bisherigeHoheTmp += node.height
            });
            predecessorsPath.forEach(node => {
                bisherigeHohePressecores += node.height
            });
            predecessors.forEach(node => {
                bisherigeHohePressecoresNor += node.height
            });
            // Berechne startY so, dass die Knoten um den mittleren Y-Wert ihrer Vorgänger zentriert sind


            startY = (minY);

            if (predecessors.length == 1) {
                startY = minY + GROUP_MARGIN_Y
            } else if (predecessorsPath.length == 1) {
                startY = minY - bisherigeHoheTmp / 2 + bisherigeHohePressecores / 2
            } else if (minY == maxY) {
                startY = (minY + (bisherigeHohePressecoresNor / predecessors.length / 2)) - (bisherigeHoheTmp / 2)
            }

        }

        // Berechne die Position jedes Knoten in der aktuellen Schicht

        let bisherigeHohe = 0

        nodes.forEach((node, idx) => {
            node.position = {
                x: breiteVorherigerPath + GROUP_MARGIN_X, // X-Position basierend auf der Schicht
                y: startY + (idx - 1) * (GROUP_MARGIN_Y) + bisherigeHohe// Y-Position basierend auf der Indexposition in der sortierten Knotenliste
            };
            bisherigeHohe += node.height
        });
    }

    function findBreiteVorherigerPath(pathPlace, groups) // Fügen Sie 'groups' als Parameter hinzu, falls erforderlich
    {
        let nodes = getNodesByPathPlace(groups, pathPlace);
        return Math.max(...nodes.map(node => node.position.x + node.width)); // Korrigierte Verwendung von Math.max
    }


    if (getMaxPathPlace(groups) > 1) {
        let nodes = getNodesByPathPlace(groups, 1);
        nodes = nodes.sort((a, b) => {
            // Berechne den minimalen Y-Wert der Vorgänger von a
            const aMinY = Math.min(...a.afters.map(beforeId => {
                const pred = groups.find(node => node.id === beforeId);
                return pred.position.y;
            }));
            // Berechne den minimalen Y-Wert der Vorgänger von b
            const bMinY = Math.min(...b.afters.map(beforeId => {
                const pred = groups.find(node => node.id === beforeId);
                return pred.position.y;
            }));
            // Vergleiche die minimalen Y-Werte von a und b
            return aMinY - bMinY;
        });


        const sucessors = nodes.flatMap(node => node.afters.map(afterId => groups.find(n => n.id === afterId)));

        const minY = Math.min(...sucessors.map(pred => pred.position.y));
        const maxY = Math.max(...sucessors.map(pred => pred.position.y));

        const startY = (maxY - minY) / 2
        let bisherigeHohe = 0

        nodes.forEach((node, idx) => {
            node.position.y = startY + (idx - 1) * (GROUP_MARGIN_Y) + bisherigeHohe

            bisherigeHohe += node.height
        });

        for (let pathPlace = 2; pathPlace <= getMaxPathPlace(groups); pathPlace++) {
            // Hole alle Knoten in der aktuellen pathPlace-Position
            let nodes = getNodesByPathPlace(groups, pathPlace);

            // Wenn wir uns nicht in der ersten Schicht befinden, sortiere die Knoten basierend auf der Y-Position ihrer Vorgänger
            if (pathPlace > 1) {
                nodes = nodes.sort((a, b) => {
                    // Berechne den minimalen Y-Wert der Vorgänger von a
                    const aMinY = Math.min(...a.befores.map(beforeId => {
                        const pred = groups.find(node => node.id === beforeId);
                        return pred.position.y;
                    }));
                    // Berechne den minimalen Y-Wert der Vorgänger von b
                    const bMinY = Math.min(...b.befores.map(beforeId => {
                        const pred = groups.find(node => node.id === beforeId);
                        return pred.position.y;
                    }));
                    // Vergleiche die minimalen Y-Werte von a und b
                    return aMinY - bMinY;
                });
            }

            // Setze den Startwert von Y auf den übergebenen Startpunkt
            let startY = null
            let breiteVorherigerPath = 0;
            if (pathPlace > 1) {
                breiteVorherigerPath = findBreiteVorherigerPath(pathPlace - 1, groups)
            }
            // Wenn wir uns nicht in der ersten Schicht befinden, justiere startY basierend auf den Vorgängern
            if (pathPlace > 1) {
                // Finde alle Vorgänger der aktuellen Knoten
                const predecessors = nodes.flatMap(node => node.befores.map(beforeId => groups.find(n => n.id === beforeId)));
                const predecessorsPath = getNodesByPathPlace(groups, pathPlace - 1);
                // Berechne den minimalen und maximalen Y-Wert der Vorgänger
                const minY = Math.min(...predecessors.map(pred => pred.position.y));
                const maxY = Math.max(...predecessors.map(pred => pred.position.y));

                let bisherigeHoheTmp = 0
                let bisherigeHohePressecores = 0
                let bisherigeHohePressecoresNor = 0
                nodes.forEach(node => {
                    bisherigeHoheTmp += node.height
                });
                predecessorsPath.forEach(node => {
                    bisherigeHohePressecores += node.height
                });
                predecessors.forEach(node => {
                    bisherigeHohePressecoresNor += node.height
                });
                // Berechne startY so, dass die Knoten um den mittleren Y-Wert ihrer Vorgänger zentriert sind



                startY = (maxY - minY) / 2;

                if (predecessors.length == 1) {
                    startY = minY + GROUP_MARGIN_Y
                } else if (predecessorsPath.length == 1) {
                    startY = minY - bisherigeHoheTmp / 2 + bisherigeHohePressecores / 2
                } else if (minY == maxY) {
                    startY = (minY + (bisherigeHohePressecoresNor / predecessors.length / 2)) - (bisherigeHoheTmp / 2);
                } else if (nodes.length == 1) {
                    startY = (minY + maxY) + bisherigeHoheTmp;
                }

            }

            // Berechne die Position jedes Knoten in der aktuellen Schicht

            let bisherigeHohe = 0

            nodes.forEach((node, idx) => {
                node.position = {
                    x: breiteVorherigerPath + GROUP_MARGIN_X, // X-Position basierend auf der Schicht
                    y: startY + (idx - 1) * (GROUP_MARGIN_Y) + bisherigeHohe// Y-Position basierend auf der Indexposition in der sortierten Knotenliste
                };
                bisherigeHohe += node.height
            });
        }
    }
    // Logge die Daten nach der Berechnung der Positionen für Debugging-Zwecke
    //console.log("After calculating positions:", groups);
}