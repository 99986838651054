// components/afterSaleForm/afterSaleForm.js
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNotification } from "../../contextProviders/NotificationContext";
import { useGetData, useGetOrgaData } from "../../functions/api";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import CustomModal from "../creationModals/creationModal";

import './afterSaleForm.css'

function AfterSaleForm() {

    //Functions
    const { showNotification } = useNotification();
    const { loadOrganizationData } = useGetOrgaData();
    const { getData } = useGetData();

    //Config
    const [isLoading, setIsLoading] = useState(true)
    const { organizationID } = useParams();
    const [customerDataGeladen, setCustomerDataGeladen] = useState(false)
    const [modalConfig, setModalConfig] = useState({ 'open': false, 'category': '', 'setObjects': null, 'parentID': '', 'setObject': null })
    const [saleType, setSaleType] = useState(''); // 'upsell' oder 'frontsell'
    const [isSubmitting, setIsSubmitting] = useState(false);


    //Daten
    const [organization, setOrganization] = useState({})

    const [formVorlagen, setFormVorlagen] = useState([])
    const [choosableFormVorlage, setChoosabkeFormVorlage] = useState({})
    const [choosedFormVorlage, setChoosedFormVorlage] = useState({})

    const [customers, setCustomers] = useState([])
    const [choosedCustomer, setChoosedCustomer] = useState({})
    const [choosableCustomers, setChoosableCustomers] = useState([])

    const [personen, setPersonen] = useState([])
    const [choosedContactPerson, setChoosedContactPerson] = useState({})


    //Input Management
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setContractDetails(prevData => ({ ...prevData, [name]: newValue }));
    };
    const [contractDetails, setContractDetails] = useState({
    });



    //Init
    useEffect(() => {
        const fetchData = async () => {
            let results = await Promise.all([
                getData("/customers", { "organizationID": organizationID }),
                getData("/formVorlagen", { "organizationID": organizationID }),
                getData("/organizations", { "rowID": organizationID })
            ]);
            console.log("results", results)

            let [customers, formVorlagen, organization] = results;

            if (organization) {
                setOrganization(organization)
                const choosableCustomers = customers.map(customer => ({
                    value: customer._id,
                    label: customer.f_name
                }));

                setChoosableCustomers(choosableCustomers)
                setCustomers(customers);
                setFormVorlagen(formVorlagen);

                const choosabkeFormVorlage = formVorlagen.map(form => ({
                    value: form._id,
                    label: form.name
                }));

                setChoosabkeFormVorlage(choosabkeFormVorlage);
                setIsLoading(false)
            }

        }
        fetchData()
    }, [organizationID]);



    useEffect(() => {
        async function loadCustomerData() {
            setCustomerDataGeladen(false)
            const fetchCustomerData = async (choosenCustomer) => {
                let results = await Promise.all([
                    getData('/contactPersons', { 'customerID': choosenCustomer.value })
                ]);
                let [personen] = results

                const choosablePersonen = personen.map(person => ({
                    value: person._id,
                    label: person.f_name
                }));

                setPersonen(choosablePersonen);

                setCustomerDataGeladen(true)
            }
            await fetchCustomerData(choosedCustomer);
        }

        if (choosedCustomer && choosedCustomer.value) {
            loadCustomerData()
        } else {
            setCustomerDataGeladen(false)
            setChoosedContactPerson({})
        }

    }, [choosedCustomer]);



    const [dynamicFields, setDynamicFields] = useState([]);
    const [zusatzData, setZusatzData] = useState({});

    const handleZusatzDataChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setZusatzData(prevData => ({ ...prevData, [name]: newValue }));
    };

    useEffect(() => {
        console.log("Zusatzdata (from useEffect):", zusatzData);
    }, [zusatzData]);

    const generateDynamicFields = (parsedFields) => {
        return parsedFields.formFields.map(field => {
            switch (field.type) {
                case 'text':
                    return (
                        <div key={field.id} className="formQestion">
                            <label htmlFor={field.id}>🤖 {field.label}</label>
                            <p>{field.desc}</p>
                            <input type="text" id={field.id} name={field.id} onChange={handleZusatzDataChange} required={field.required} value={zusatzData[field.id] || ''} />
                        </div>
                    );

                case 'LongText':
                    return (
                        <div key={field.id} className="formQestion">
                            <label htmlFor={field.id}>🤖 {field.label}</label><br />
                            <p>{field.desc}</p>
                            <textarea id={field.id} name={field.id} required={field.required} onChange={handleZusatzDataChange} value={zusatzData[field.id] || ''}></textarea>
                        </div>
                    );

                case 'singleSelect':
                    return (
                        <div key={field.id} className="formQestion">
                            <label htmlFor={field.id}>🤖 {field.label}</label>
                            <p>{field.desc}</p>
                            <select id={field.id} name={field.id} value={zusatzData[field.id] || ''} onChange={handleZusatzDataChange}>
                                {field.possibleChoices.map((choice, index) => (
                                    <option key={index} value={choice}>{choice}</option>
                                ))}
                            </select>
                        </div>
                    );

                case 'Number':
                    return (
                        <div key={field.id} className="formQestion">
                            <label htmlFor={field.id}>🤖 {field.label}</label>
                            <p>{field.desc}</p>
                            <input type="number" id={field.id} name={field.id} required={field.required} value={zusatzData[field.id] || ''} onChange={handleZusatzDataChange} />
                        </div>
                    );

                case 'Checkbox':
                    return (
                        <div key={field.id} className="formQestion">
                            <label htmlFor={field.id}>🤖 {field.label}</label>
                            <p>{field.desc}</p>
                            <input type="checkbox" id={field.id} name={field.id} checked={zusatzData[field.id] || false} onChange={handleZusatzDataChange} />
                        </div>
                    );

                default:
                    return null;
            }
        });
    };

    useEffect(() => {
        if (choosedFormVorlage) {
            const foundForm = formVorlagen.find(form => choosedFormVorlage.value === form._id);
            if (foundForm && foundForm.json_formDataFields) {
                const parsedFields = JSON.parse(foundForm.json_formDataFields);
                const generatedFields = generateDynamicFields(parsedFields);
                setDynamicFields(generatedFields);

                const initialZusatzData = parsedFields.formFields.reduce((acc, field) => {
                    acc[field.id] = field.type === 'Checkbox' ? false : '';
                    return acc;
                }, {});

                setZusatzData(initialZusatzData);
            }
        } else {
            setDynamicFields(null);
        }
    }, [choosedFormVorlage]);

    // Neuer useEffect, der dynamicFields neu generiert, wenn sich zusatzData ändert
    useEffect(() => {
        if (choosedFormVorlage) {
            const foundForm = formVorlagen.find(form => choosedFormVorlage.value === form._id);
            if (foundForm && foundForm.json_formDataFields) {
                const parsedFields = JSON.parse(foundForm.json_formDataFields);
                const generatedFields = generateDynamicFields(parsedFields);
                setDynamicFields(generatedFields);
            }
        }
    }, [zusatzData]);










    const handleSubmit = () => {
        if (!isSubmitting) {

            const validationResult = validateForm();

            if (validationResult.status) {
                setIsSubmitting(true)
                // Daten, die Sie an den Server senden möchten
                const postData = {
                    contractDetails: contractDetails,
                    zusatzData: zusatzData,
                    saleType: saleType,
                    orgaID: organizationID,
                    choosedContactPerson: choosedContactPerson,
                    choosedFormVorlage: choosedFormVorlage,
                    choosedCustomer: choosedCustomer
                };

                const postDataJSON = JSON.stringify(postData)

                const payload = {
                    orgaID: organizationID,
                    data: postDataJSON
                }

                console.error("postData: ", postData)
                // POST Request
                fetch('https://hook.eu2.make.com/mfo7mbx1hrtuqm9ytfitloj5vv57lrde', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                })
                    .then(response => response.json()) // Umwandeln der Antwort in JSON
                    .then(data => {
                        if (data && data.url) {
                            showNotification("Vertrag wurde erstellt", "good");
                            // Öffnen der URL in einem neuen Tab
                            setTimeout(() => {
                                window.open(data.url, '_blank');
                                window.location.reload();
                            }, 1000)

                        } else if (data && data.err_msg) {
                            console.error(data.err_msg, "error");
                        }
                        else {
                            console.error('Server Error', "error");
                        }
                    })
                    .catch(error => {
                        showNotification("Server Error", "error");
                        console.error('Fehler beim Senden des POST-Requests:', error);
                    });
            } else {
                showNotification(validationResult.msg, "error");
            }
        }
    };

    const validateForm = () => {
        // Überprüfen der Felder des Vertragsdetails

        console.log("Warn: Validete:")

        //Checke VertragsArt
        if (!contractDetails) {
            return { status: false, msg: 'Vertragsdetails sind nicht definiert.' };
        }


        //Kontakt Details
        if (saleType === 'upsell') {
            if (!choosedCustomer.value || !choosedContactPerson.value) {
                return { status: false, msg: 'Bitte wählen Sie einen Kunden und einen Ansprechpartner aus.' };
            }
        } else if (saleType === 'frontsell') {
            const requiredFieldsFrontsell = ["firmenName", "Street", "Stadt", "PLZ", "UST_ID", "land", "apVorname", "apNachname", "Geschlecht", "Ansprache", "apPhoneNumber", "apEmail"];
            for (const key of requiredFieldsFrontsell) {
                if (!contractDetails[key]) {
                    return { status: false, msg: `Das Feld ${key} im Frontsell ist leer oder nicht definiert.` };
                }
            }
        } else {
            return { status: false, msg: `Vertragsart wählen` };
        }



        // Extrahieren Sie das Abrechnungsmodell für bessere Lesbarkeit
        const billingModel = contractDetails['Abrechnungsmodell'];

        if (!billingModel) {
            return { status: false, msg: 'Abrechnungsmodell wählen' };
        }

        // Validierung für Setup-Daten
        if (billingModel === "setup" || billingModel === "setup + ongoing") {
            const requiredSetupFields = ["setupModel", "setupFee", "setupStart"];
            for (const key of requiredSetupFields) {
                if (!contractDetails[key]) {
                    return { status: false, msg: `Das Feld ${key} im Setup ist leer oder nicht definiert.` };
                }
            }
        }

        // Validierung für Ongoing-Daten
        if (billingModel === "ongoing" || billingModel === "setup + ongoing") {
            const requiredOngoingFields = ["monthlyFee", "duration", "cancellationPeriod", "ongoingAutoRenewal", "ongoingStart"];
            for (const key of requiredOngoingFields) {
                if (!contractDetails[key]) {
                    return { status: false, msg: `Das Feld ${key} im Ongoing ist leer oder nicht definiert.` };
                }
            }

            // Überprüfen Sie die automatische Verlängerung
            if (contractDetails['ongoingAutoRenewal'] === "true" && !contractDetails['ongoingRenewalDuration']) {
                return { status: false, msg: 'Die Dauer der automatischen Verlängerung ist nicht definiert.' };
            }
        }



        // Überprüfen der Zusatzdaten
        if (!choosedFormVorlage || choosedFormVorlage == {}) {
            return { status: false, msg: `Wähle deine Vorlage aus` };
        }
        for (const key in zusatzData) {
            if (zusatzData[key] === '' || zusatzData[key] === null || zusatzData[key] === undefined) {
                return { status: false, msg: `Das Zusatzdaten-Feld ${key} ist leer oder nicht definiert.` };
            }
        }

        return { status: true, msg: 'Formular ist valide.' };
    };



    if (isLoading) {
        return (<p>Laden...</p>)
    }

    return (
        <div className="afterSaleForm-container">
            <div className="afterSaleForm-containerHeader">
                {organization.name} - After Sale
            </div>


            <section className="afterSaleForm-section">
                <h2>Vertragsart</h2>
                <select value={saleType} onChange={e => setSaleType(e.target.value)}>
                    <option value="">-- Wählen Sie einen Sale-Typ --</option>
                    <option value="upsell">Upsell</option>
                    <option value="frontsell">Frontsell</option>
                </select>
            </section>

            <section className="afterSaleForm-section">
                <h2>Zielkunde</h2>
                {saleType === 'upsell' && (
                    <>
                        <div className="formQestion">
                            <label>🏢 Kunde</label>
                            <Select
                                isClearable={true}
                                value={choosedCustomer}
                                options={choosableCustomers}
                                placeholder='Kunde auswählen'
                                onChange={(e) => setChoosedCustomer(e ? e : {})}
                            />
                        </div>
                        {customerDataGeladen && (

                            <div className="formQestion">
                                <label>🧑 Person</label>
                                <CreatableSelect
                                    isClearable={true}
                                    value={choosedContactPerson}
                                    options={personen}
                                    className='rollenSelect'
                                    placeholder='Ansprechpartner'
                                    isDisabled={!customerDataGeladen}
                                    onChange={(e) => {
                                        if (e) {
                                            if (e.__isNew__) {
                                                setModalConfig({
                                                    'open': true,
                                                    'category': 'contactpersons',
                                                    'objects': personen,
                                                    'setObjects': setChoosedContactPerson,
                                                    'parentID': choosedCustomer.value,
                                                    'setObject': setChoosedContactPerson
                                                });
                                            } else {
                                                setChoosedContactPerson({ 'value': e.value, 'label': e.label })
                                            }
                                        } else {
                                            setChoosedContactPerson({})
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}

                {saleType === 'frontsell' && (
                    <>
                        <h3>🏢 Kunde</h3>
                        <div className="formQestion">
                            <label>🏢 Firmenname</label>
                            <input type="text" name="firmenName" value={contractDetails.firmenName || ''} onChange={handleInputChange} required />
                        </div>
                        <div className="formQestion">
                            <label>🏢 Straße</label>
                            <input type="text" name="Street" value={contractDetails.Street || ''} onChange={handleInputChange} required />
                        </div>
                        <div className="formQestion">
                            <label>🏢 PLZ</label>
                            <input type="text" name="PLZ" value={contractDetails.PLZ || ''} onChange={handleInputChange} required />
                        </div>
                        <div className="formQestion">
                            <label>🏢 Stadt</label>
                            <input type="text" name="Stadt" value={contractDetails.Stadt || ''} onChange={handleInputChange} required />
                        </div>
                        <div className="formQestion">
                            <label htmlFor="land">🏢 Land</label>
                            <select id="land" name="land" value={contractDetails.land || ''} onChange={handleInputChange}>
                                <option value="">Auswählen</option>
                                <option value="DE">Deutschland</option>
                                <option value="AT">Österreich</option>
                                <option value="CH">Schweiz</option>
                            </select>
                        </div>
                        <div className="formQestion">
                            <label>🏢 UST-ID</label>
                            <input type="text" name="UST_ID" value={contractDetails.UST_ID || ''} onChange={handleInputChange} />
                        </div>


                        <h3>🧑 Person</h3>
                        <div className="formQestion">
                            <label>🧑 Vorname</label>
                            <input type="text" name="apVorname" value={contractDetails.apVorname || ''} onChange={handleInputChange} required />
                        </div>
                        <div className="formQestion">
                            <label>🧑 Nachname</label>
                            <input type="text" name="apNachname" value={contractDetails.apNachname || ''} onChange={handleInputChange} required />
                        </div>
                        <div className="formQestion">
                            <label>🧑 Telefonnummer</label>
                            <input type="text" name="apPhoneNumber" value={contractDetails.apPhoneNumber || ''} onChange={handleInputChange} required />
                        </div>
                        <div className="formQestion">
                            <label>🧑 Email</label>
                            <input type="text" name="apEmail" value={contractDetails.apEmail || ''} onChange={handleInputChange} required />
                        </div>
                        <div className="formQestion">
                            <label>🧑 Geschlecht</label>
                            <select name="Geschlecht" value={contractDetails.Geschlecht || ''} onChange={handleInputChange}>
                                <option value="" disabled selected> Auswählen </option>
                                <option value="Mann">Männlich</option>
                                <option value="Weiblich">Weiblich</option>
                            </select>
                        </div>
                        <div className="formQestion">
                            <label>🧑 Ansprache</label>
                            <select name="Ansprache" value={contractDetails.Ansprache || ''} onChange={handleInputChange}>
                                <option value="" disabled selected> Ansprache </option>
                                <option value="du">Du</option>
                                <option value="sie">Sie</option>
                            </select>
                        </div>

                    </>
                )}

                {saleType === '' && (
                    <>
                        <p>Auswählen...</p>
                    </>
                )}
            </section>
            <section className="afterSaleForm-section">
                <h2>📝 Vertragsdetails</h2>
                <div className="formQestion">
                    <label>📝 Abrechnungsmodell</label>
                    <select name="Abrechnungsmodell" value={contractDetails.Abrechnungsmodell || ''} onChange={handleInputChange}>
                        <option value="" disabled selected> Abrechnungsmodell </option>
                        <option value="setup">Setup</option>
                        <option value="setup + ongoing">Setup + Ongoing</option>
                        <option value="ongoing">Ongoing</option>
                    </select>
                </div>

                {(contractDetails['Abrechnungsmodell'] === "setup" || contractDetails['Abrechnungsmodell'] === "setup + ongoing") &&
                    <>
                        <h3>📝 Setup Daten</h3>
                        <div className="formQestion">
                            <label>📝 Setup-Gebühr Abrechnungsart</label>
                            <select name="setupModel" value={contractDetails.setupModel || ''} onChange={handleInputChange}>
                                <option value="" disabled selected> setupModel </option>
                                <option value="100">Einmalzahlung</option>
                                <option value="50-50">50 / 50</option>
                            </select>
                        </div>
                        <div className="formQestion">
                            <label>📝 Setup-Gebühr (€):</label>
                            <input type="number" name="setupFee" value={contractDetails.setupFee || 0} onChange={handleInputChange} placeholder="Setup-Gebühr" />
                        </div>
                        <div className="formQestion">
                            <label>📝 Start des Vertrages</label>
                            <input type="date" name="setupStart" value={contractDetails.setupStart || ''} onChange={handleInputChange} />
                        </div>
                    </>
                }

                {(contractDetails['Abrechnungsmodell'] === "ongoing" || contractDetails['Abrechnungsmodell'] === "setup + ongoing") &&
                    <>
                        <h3>📝 Ongoing Daten</h3>
                        <div className="formQestion">
                            <label>📝 Monatliche Gebühr (€):</label>
                            <input type="number" name="monthlyFee" value={contractDetails.monthlyFee || 0} onChange={handleInputChange} placeholder="Monatliche Gebühr" />
                        </div>

                        <div className="formQestion">
                            <label>📝 Mindest Laufzeit [Wochen]:</label>
                            <input type="number" name="duration" value={contractDetails.duration || ''} onChange={handleInputChange} placeholder="Laufzeit" />
                        </div>

                        <div className="formQestion">
                            <label>📝 Kündigungsfrist [Wochen]:</label>
                            <input type="number" name="cancellationPeriod" value={contractDetails.cancellationPeriod || ''} onChange={handleInputChange} placeholder="Kündigungsfrist" />
                        </div>

                        <div className="formQestion">
                            <label>📝 Automatische Verlängerung:</label>
                            <select name="ongoingAutoRenewal" value={contractDetails.ongoingAutoRenewal || ''} onChange={handleInputChange}>
                                <option value="" disabled selected> Aut. Verlängerung </option>
                                <option value="true" >Ja - Verlängere Aut.</option>
                                <option value="false" >Nein - Keine Aut. Verlängerung</option>
                            </select>
                        </div>

                        {contractDetails['ongoingAutoRenewal'] == "true" &&
                            <div className="formQestion">
                                <label>📝 Wird aut. verlänger um: [Wochen]:</label>
                                <input type="number" name="ongoingRenewalDuration" value={contractDetails.ongoingRenewalDuration || ''} onChange={handleInputChange} placeholder="4" />
                            </div>
                        }

                        <div className="formQestion">
                            <label>📝 Ongoing Start</label>
                            <input type="date" name="ongoingStart" value={contractDetails.ongoingStart || ''} onChange={handleInputChange} />
                        </div>
                    </>
                }
            </section>

            <section className="afterSaleForm-section">
                <h2>🤖 Zusatzdaten</h2>
                <label>🤖 Vorlage:</label>
                <Select
                    isClearable={true}
                    value={choosedFormVorlage}
                    options={choosableFormVorlage}
                    placeholder='Formular Vorlage auswählen'
                    onChange={(e) => setChoosedFormVorlage(e ? e : null)}
                />

                {dynamicFields}
            </section>

            <section className="afterSaleForm-section">
                <div className={`addBtn ${isSubmitting ? 'disabledBtn' : ''}`} onClick={isSubmitting ? null : handleSubmit}>Vertrag generieren</div>
            </section>
            <CustomModal modalConfig={modalConfig} setModalConfig={setModalConfig} />
        </div >
    );

}

export default AfterSaleForm;
