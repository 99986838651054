
import React, { useState, useEffect } from 'react';
import { getDecodedJwtToken, useGetData, useGetOrgaData } from '../functions/api';
import "./overview.css"
import ShareItems from '../components/shareItems/shareItems';
import Modal from 'react-modal'; // Ein Beispiel mit 'react-modal'. Du kannst eine beliebige Modal-Library verwenden.

function Overview() {
    const { loadOrganizationData } = useGetOrgaData();
    const [workflowData, setFlowChartData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isAscending, setIsAscending] = useState(true);
    const [filterValue, setFilterValue] = useState('');
    const [modalConfig, setModalConfig] = useState(false);

    const openModal = (kategorie, customerID, type) => {
        setModalConfig({ 'open': true, 'category': kategorie, 'customerID': customerID, 'type': type });
    }

    const closeModal = () => {
        setModalConfig({ 'open': false, 'category': '' });
    }

    const [customersVorlagen, setCustomersVorlagen] = useState([])

    const [orgaID, setOrgaID] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {

            let results = await Promise.all([
                loadOrganizationData("/customers", {})
            ]);
            console.log("results", results)

            let [customers] = results;

            customers.forEach(cstm => {
                if (cstm.lastTouchpoint) {
                    cstm.lastTouchpoint = formatDate(cstm.lastTouchpoint[0])
                }
                if (cstm.lastHappinessCall) {
                    cstm.lastHappinessCall = formatDate(cstm.lastHappinessCall[0])
                }
            });

            let decodedToken = await getDecodedJwtToken()
            setOrgaID(decodedToken.decodedToken.orgaId)

            setCustomersVorlagen(customers)

            setIsLoading(false)
        }
        fetchData()
    }, []);




    const formatDate = (dateString) => {
        console.log("DATE: ", dateString)
        const date = new Date(dateString);
        return date;
    };


    const handleSort = (field) => {
        setIsAscending(prevState => !prevState);
        setCustomersVorlagen(prevCustomers => {
            return [...prevCustomers].sort((a, b) => {
                if (a[field] < b[field]) return isAscending ? 1 : -1;
                if (a[field] > b[field]) return isAscending ? -1 : 1;
                return 0;
            });
        });
    };

    const handleFilter = (value) => {
        setFilterValue(value);
    }

    if (isLoading) {
        return (<div className='mainBox'>Loading...</div>);
    }

    const filteredCustomers = customersVorlagen.filter(customer =>
        customer.f_name.toLowerCase().includes(filterValue.toLowerCase())
    );
    const displayedCustomers = isAscending ? filteredCustomers : [...filteredCustomers].reverse();

    return (
        <div className='mainBox'>
            <div className='customerOverView'>
                <div className='customerOverViewHeader'>
                    <div className="breite-0"></div>
                    <div className="header-info OverviewBreite-3">
                        <label htmlFor="customerName">Name:</label>
                        <input
                            id="customerName"
                            onInput={e => handleFilter(e.target.value)}
                            className="filter-header-search-input"
                            placeholder="&#128269;Suche.."
                        />
                    </div>
                    <div className="header-info OverviewBreite-3">
                        Letzter Touchpoint
                        <span onClick={() => handleSort('lastTouchpoint')} style={{ cursor: 'pointer' }}>{isAscending ? '↑' : '↓'}</span>
                    </div>
                    <div className="header-info OverviewBreite-3">
                        Letzter Happinesscall
                        <span onClick={() => handleSort('lastHappinessCall')} style={{ cursor: 'pointer' }}>{isAscending ? '↑' : '↓'}</span>
                    </div>

                    <div className="header-info OverviewBreite-3">
                        Aktuelle Zufriedenheit
                        <span onClick={() => handleSort('lastHappinessValue')} style={{ cursor: 'pointer' }}>{isAscending ? '↑' : '↓'}</span>
                    </div>
                    <div className="header-info OverviewBreite-3">
                        Status
                    </div>
                    <div className="header-info OverviewBreite-3">

                    </div>
                </div>
                <div className='customerOverViewBody'>
                    {displayedCustomers.map(customer =>
                        <CustomerRow customer={customer} orgaID={orgaID} openModal={openModal} />
                    )}
                </div>
            </div>
            {modalConfig.open && (
                <>
                    <div className='shadow' />
                    <Modal isOpen={modalConfig.open} >
                        <div className='modalStyle'>
                            <div className='modalHeader'>
                                <div className='modalName'>
                                    {modalConfig.category}
                                </div>
                                <div className='closeModal' onClick={closeModal}>
                                    X
                                </div>
                            </div>
                            <ShareItems type={modalConfig.type} orgaID={orgaID} customerID={modalConfig.customerID} />
                        </div>
                    </Modal>
                </>
            )}
        </div>
    );
}

export default Overview;

function CustomerRow({ customer, orgaID, openModal }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [lastCommunication, setLastCommunication] = useState(null);
    const { getData } = useGetData()


    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    };


    const getDaysSince = (date) => {
        const today = new Date();
        const diffTime = Math.abs(today - date);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };


    const getHappinessLevel = (value) => {

        if (Array.isArray(value)) {
            value = value[0]
        }
        const happinessLevels = [
            {
                "value": 1,
                "label": "😡 Kritisch",
                "color": "#FF0000"
            },
            {
                "value": 2,
                "label": "😥 Unruhig",
                "color": "#FFA500"
            },
            {
                "value": 3,
                "label": "😐 Neutral",
                "color": "#FFFF00"
            },
            {
                "value": 4,
                "label": "😊 Zufrieden",
                "color": "#008000"
            },
            {
                "value": 5,
                "label": "😁 Begeistert",
                "color": "#5abe00"
            }
        ];

        const level = happinessLevels.find(level => level.value === value);

        console.log("level:", level)
        return level ? level : { label: "---", color: "transparent" };
    }

    const getColorForDaysSince = (days) => {
        if (days <= 7) {
            return "#5abe00"; // Dunkelgrün
        } else if (days <= 14) {
            return "#006400"; // Mittelgrün
        } else if (days <= 21) {
            return "#DAA520"; // Gold
        } else if (days <= 31) {
            return "#FFA500"; // Orange
        } else {
            return "#FF0000"; // Rot
        }
    }



    useEffect(() => {

        const fetchData = async () => {
            if (customer.lastTouchpointRowID) {
                let resObjs = await getData("/touchpoints", { 'rowID': customer.lastTouchpointRowID });
                console.log("results:", resObjs);

                if (resObjs) {
                    setLastCommunication(resObjs);
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };
        if (isOpen) {
            setIsLoading(true);
            fetchData();
        }
    }, [customer, isOpen]);

    if (isLoading) {
        return (<p>Laden...</p>)
    }
    console.log("Customer: ", customer)
    return (
        <div key={customer._id} className={`customerRow ${isOpen ? 'borderLeftClass' : ''}`} style={isOpen ? { borderLeft: '3px solid var(--main-color)' } : {}}>
            <div key={customer._id} className='zeileWhiteBoardEdit-Header'>
                <div className="breite-0" onClick={handleToggleOpen}>
                    {!isOpen && <i className="fa-solid fa-eye"></i>}
                    {isOpen && <i className="fa-solid fa-x" style={{ color: "red" }}></i>}
                </div>
                <div className={"OverviewZelle OverviewBreite-3"}>
                    {customer.f_name}
                </div>
                {!isOpen &&
                    <>
                        <div className="OverviewZelle OverviewBreite-3" style={{ color: customer.lastTouchpoint ? getColorForDaysSince(getDaysSince(customer.lastTouchpoint)) : "transparent" }}>
                            <strong>{customer.lastTouchpoint ? getDaysSince(customer.lastTouchpoint) + ' Tage her' : "---"}</strong>
                        </div>
                        <div className="OverviewZelle OverviewBreite-3" style={{ color: customer.lastHappinessCall ? getColorForDaysSince(getDaysSince(customer.lastHappinessCall)) : "transparent" }}>
                            <strong>{customer.lastHappinessCall ? getDaysSince(customer.lastHappinessCall) + ' Tage her' : "---"}</strong>
                        </div>


                        <div className="OverviewZelle OverviewBreite-3">
                            <div className='tag' style={{ backgroundColor: getHappinessLevel(customer.lastHappinessValue).color, justifyContent: 'flex-start', paddingLeft: '10px' }}>
                                <div>{getHappinessLevel(customer.lastHappinessValue).label}</div>
                            </div>
                        </div>
                        <div className="header-info OverviewBreite-3">
                            <div className='tag' style={{ backgroundColor: 'green', justifyContent: 'flex-start', paddingLeft: '10px' }}>
                                <div>Aktive Zusammenarbeit</div>
                            </div>
                        </div>
                        <div className="header-info OverviewBreite-3">
                            <div className='addBtn' style={{ width: '90%' }} onClick={() => { openModal("Kommunikation", customer._id, "touchpoints") }}>
                                kommunikation
                            </div>
                        </div>

                    </>
                }
            </div>
            {/* BODY */}
            {
                isOpen && <>
                    < div className='customerDetailsBox'>



                        <div className='customerDetailsBox-Header'>
                            <div className='customerDetailsBox-Header-Buttons'>
                                <div className='customerDetailsBox-Header-Buttons-Btn' onClick={() => { openModal("Kommunikation", customer._id, "touchpoints") }}>Kommunikation</div>
                                <div className='customerDetailsBox-Header-Buttons-Btn' onClick={() => { openModal("LinkTree", customer._id, "linktree") }}>LinkTree</div>
                                <div className='customerDetailsBox-Header-Buttons-Btn' onClick={() => { openModal("Personen", customer._id, "contactpersons") }}>Personen</div>
                                <div className='customerDetailsBox-Header-Buttons-Btn' onClick={() => { openModal("Passwörter", customer._id, "passwords") }}>Passwörter</div>
                            </div>
                            <div className='customerDetailsBox-Header-Kommunikation'>
                                {lastCommunication &&
                                    <>
                                        <div className='tag' style={{ backgroundColor: getHappinessLevel(customer.lastHappinessValue).color, width: 'fit-content', padding: '5px 10px' }}>
                                            <strong>Letzter Touchpoint: {getHappinessLevel(customer.lastHappinessValue).label}</strong>
                                        </div>
                                        <textarea disabled className='textarea-modern'>{lastCommunication.notes}</textarea>
                                    </>
                                }
                                {!lastCommunication &&
                                    <p>Keine bisher getrackte Kommunikation</p>
                                }
                            </div>
                        </div>
                        <ProjectTable customerID={customer._id} />
                    </div>
                </>


            }
        </div >
    )
}


function ProjectTable({ customerID }) {
    const { getData } = useGetData();

    const [isLoading, setIsLoading] = useState(true);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            let resObjs = await getData("/projects", { 'customerID': customerID });
            console.log("results:", resObjs);

            if (resObjs) {
                setProjects(resObjs);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [customerID]);

    if (isLoading) {
        return <a>Laden...</a>;
    }

    return (

        <div className='projectTable'>
            <span>Projekte</span>
            {
                projects.map(proj => {
                    return <ProjectRow proj={proj} />; // Fügen Sie auch einen Schlüssel hinzu, um React-Warnungen zu vermeiden.
                })
            }
        </div>


    );
}


function ProjectRow({ proj }) {

    const [isOpen, setIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleEditandSave = () => {
        setIsEditing(!isEditing);
    };

    return (
        <div key={proj._id || proj.f_name} className='projectRow' style={isOpen ? { borderLeft: '3px solid var(--main-color)' } : {}}>
            <div className='projectHead'>
                <div className="breite-0" onClick={handleToggleOpen}>
                    {!isOpen && <i className="fa-solid fa-eye"></i>}
                    {isOpen && <i className="fa-solid fa-x" style={{ color: "red" }}></i>}
                </div>
                <div className='splitRow'>
                    <div className="header-info OverviewBreite-3">
                        {proj.f_name}
                    </div>
                    <div className="header-info OverviewBreite-3">
                        <div className='tag' style={{ backgroundColor: proj.status === 'aktiv' ? '#2ecc71' : '#e74c3c', width: '100%' }}>
                            {proj.status}
                        </div>
                    </div>
                </div>

            </div>
            {isOpen &&
                <ProjectBody proj={proj} />
            }
        </div >
    )
}

function ProjectBody({ proj }) {
    const { getData } = useGetData();

    const [isLoading, setIsLoading] = useState(true);
    const [projItems, setProjItems] = useState([]);
    const [selectedTab, setSelectedTab] = useState("Overview");

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            let resObjs = await getData("/projectItems", { 'projectRowID': proj._id });
            console.log("results:", resObjs);

            if (resObjs) {
                setProjItems(resObjs);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [proj]);

    if (isLoading) {
        return <a>Laden...</a>;
    }

    return (
        <>
            <div className="tab-navigation">
                {["Overview", "Workflows", "Elemente", "Verträge", "Rechnungen"].map(tab => (
                    <button
                        key={tab}
                        className={`tab-button ${selectedTab === tab ? ' tab-button-active' : ''}`}
                        onClick={() => setSelectedTab(tab)}
                    >
                        {tab}
                    </button>
                ))}
            </div>

            {selectedTab === "Overview" && <ProjectDashboard proj={proj} />}
            {selectedTab === "Workflows" && <div>Workflows-Inhalt</div>}
            {selectedTab === "Elemente" && projItems.map(item => <ItemRow key={item._id} item={item} />)}
            {selectedTab === "Verträge" && <div>Verträge-Inhalt</div>}
            {selectedTab === "Rechnungen" && <div>Rechnungen-Inhalt</div>}
        </>
    );
}


function ItemRow({ item }) {

    console.log("Item: ", item)

    const [isEditing, setIsEditing] = useState(false);
    const handleEditandSave = () => {
        setIsEditing(!isEditing);
    };


    return (
        <div key={item._id || item.name} className='projectRow'>
            <div className='projectHead'>
                <div className="breite-0" onClick={handleEditandSave}>
                    {isEditing && <i className="fa-solid fa-save"></i>}
                    {!isEditing && <i className="fa-solid fa-pencil"></i>}
                </div>
                <div className="header-info OverviewBreite-2">
                    {item.name}
                </div>
                <div className="header-info OverviewBreite-3">
                    <div className='tag' style={{ backgroundColor: item.typ === 'wasserfall' ? '#3498db' : '#95a5a6' }}>
                        {item.status}
                    </div>
                </div>
            </div>
        </div >
    )

}

function ProjectDashboard({ proj }) {
    const secondsToHMM = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        return `${h} h:${m < 10 ? '0' + m : m} min`;
    }

    const formatCost = (cost) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(cost);
    }

    const shouldTime = secondsToHMM(proj.shouldTime);
    const isTime = secondsToHMM(proj.isTime);
    const isCost = formatCost(proj.isCost);

    const isTimeExceeded = proj.isTime > proj.shouldTime;

    const isTimePercentage = (proj.isTime / proj.shouldTime) * 100;

    return (
        <div className="project-dashboard">
            <div className="time-container">
                <div className={`time-display ${isTimeExceeded ? 'exceeded-time' : ''}`}>
                    Zeit: {isTime} / {shouldTime}
                </div>
                <div className="time-bar">
                    <div className="filled-time-bar" style={{ width: `${isTimePercentage}%` }}></div>
                </div>
            </div>
            <div className="cost-container">
                <div className="cost-display">
                    <span className="cost-icon">Kosten: </span> {isCost}
                </div>
            </div>
        </div>
    );
}




//offers Verträge


//rechnungen