import React, { createContext, useState, useContext, useEffect } from "react";
import '../style/notifications.css'

const NotificationContext = createContext();

export function useNotification() {
    const setNotification = useContext(NotificationContext);

    function showNotification(message, type) {
        console.log("Notification")
        setNotification({ active: true, message, type });
    }

    return { showNotification };
}

export function NotificationProvider({ children }) {
    const [notification, setNotification] = useState({
        active: false,
        message: "",
        type: ""
    });

    useEffect(() => {
        if (notification.active) {
            const timerId = setTimeout(() => {
                setNotification({ active: false, message: "", type: "" });
            }, 2000);

            // Aufräumarbeiten: Den Timer löschen, falls die Komponente unerwartet aushängt.
            return () => {
                clearTimeout(timerId);
            };
        }
    }, [notification, setNotification]);

    return (
        <NotificationContext.Provider value={setNotification}>
            {children}
            {notification.active && (
                <div className={`notification ${notification.type}`} >
                    {notification.message}
                </div>

            )}
        </NotificationContext.Provider>
    );
}
